import React, { useState } from 'react'
import { IForm } from '../../../domain/interfaces/IForm'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box, Checkbox, Grid } from '@mui/material'
import { AppBorders, AppColors } from '../../Theme'
import { ETypography } from '../../components/fonts/ETypography'
import { EdifyButton } from '../../components/buttons'
import SubmissionsModal from './SubmissionsModal'
import { IFormSubmitter } from '../../../core/hooks'
import LinkIcon from '@mui/icons-material/Link'
import BasicSubmissionModal from './BasicSubmissionModal'
import { getCurrentOrgId } from '../../../domain/domain'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { addOrgIdAndTokenToFormComponents } from './submissionHelpers'
interface AdditionalFormsProps {
  form: IForm
  formSubmitter: IFormSubmitter
  viewOnly?: boolean
  value?: number
}

const AdditionalForms: React.FC<AdditionalFormsProps> = ({
  form,
  formSubmitter,
  viewOnly = false,
  value,
}) => {
  const {
    additionalFormSubmissions,
    linkFormsState,
    toggleAdditionalForm,
    toggleLinkedForms,
  } = formSubmitter
  const { additionalForms, linkForms } = form

  const getSelectedSubmissionIds = (form: any) => {
    const linkedForm = linkFormsState.find((f: any) => f.id == form.id)
    if (!linkedForm) return []
    return linkedForm.submissionIds
  }
  return (
    <>
      {additionalForms && additionalForms.length > 0 && (
        <InnerPageContainer
          headerTitle='Add Additional Forms'
          // collapsed
          collapsible
          sx={{
            justifyContent: 'space-between',
            flexDirection: 'column',
            marginTop: '24px',
            display: 'flex',
          }}
        >
          <ETypography font='MS' color='gray700' sx={{ margin: '12px 0' }}>
            SELECT FORMS
          </ETypography>

          <Grid
            container
            spacing={'12px'}
            sx={{
              justifyContent: 'space-between',
            }}
          >
            {additionalForms.map((f) => {
              const isChecked = additionalFormSubmissions.some(
                (sub: any) => sub.id == f.id,
              )
              return (
                <Grid
                  key={f.id}
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  onClick={() => toggleAdditionalForm(f.id, isChecked)}
                >
                  <ButtonWithCheckbox
                    title={f.title}
                    checked={isChecked}
                  ></ButtonWithCheckbox>
                </Grid>
              )
            })}
          </Grid>
        </InnerPageContainer>
      )}
      {linkForms &&
        linkForms.map((f) => (
          <FormLink
            key={f.id}
            title={f.title}
            formId={f.id}
            toggleLinkedForms={toggleLinkedForms}
            selectedSubmissionIds={getSelectedSubmissionIds(f)}
            viewOnly={viewOnly}
          />
        ))}
    </>
  )
}

export default AdditionalForms



// BUTTON - MOVE TO COMPONENTS
interface ButtonWithCheckboxProps {
  checked: boolean
  title: string
}

const ButtonWithCheckbox: React.FC<ButtonWithCheckboxProps> = ({
  checked: initChecked,
  title,
}) => {
  const [checked, setChecked] = useState<boolean>(initChecked)

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  return (
    <Box
      onClick={() => setChecked(!checked)}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 0px 0px 12px',
        ...AppBorders.primary,
        '&: hover': {
          cursor: 'pointer',
        },
      }}
    >
      <ETypography font='MS' color='gray700'>
        {title}
      </ETypography>
      <Checkbox
        checked={checked}
        onChange={handleCheckboxChange}
        color='primary'
      />
    </Box>
  )
}

interface FormLinkProps {
  title: string
  formId: string
  toggleLinkedForms: any
  selectedSubmissionIds: string[]
  viewOnly?: boolean
}

const FormLink: React.FC<FormLinkProps> = ({
  title,
  formId,
  toggleLinkedForms,
  selectedSubmissionIds,
  viewOnly = false,
}) => {
  const { forms, getHierarchyName } = useOrganizationProvider()
  const orgId = getCurrentOrgId() ?? ''
  const [open, setOpen] = useState(false)
  const [submissionOpen, setSubmissionOpen] = useState(false)
  const [selectedSubId, setSelectedSubId] = useState<string | null>(null)
  const [selectedFormJson, setSelectedFormJson] = useState<any>(null)
  
  const form = forms.find((f) => f.id == formId) as IForm
  const readOnlyFormJson = addOrgIdAndTokenToFormComponents(
    form,
    getHierarchyName(1, true),
    getHierarchyName(0, true),
    orgId,
    true,
  )

  const generateReadOnlyFormJson = () => {
    return addOrgIdAndTokenToFormComponents(
      form,
      getHierarchyName(1, true),
      getHierarchyName(0, true),
      orgId,
      true,
    )
  }

  const handleSubmissionClick = (subId: string) => {
    const formJson = generateReadOnlyFormJson()
    setSelectedSubId(subId)
    setSelectedFormJson(formJson)
    setSubmissionOpen(true)
  }

  const handleModalClose = () => {
    setSubmissionOpen(false)
    setSelectedSubId(null)
    setSelectedFormJson(null)
  }

  if (!open) {
    return (
      <InnerPageContainer
        headerTitle={`${title}`}
        renderRightItems={() =>
          viewOnly ? (
            <></>
          ) : (
            <EdifyButton
              onClick={() => setOpen(true)}
              buttonStyle={{ padding: 0, margin: 0 }}
              secondary
              title='+'
            />
          )
        }
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          marginTop: '24px',
        }}
      >
        {selectedSubmissionIds.map((id, count) => {
          const removeSubmission = selectedSubmissionIds.filter((s) => s !== id)
          return (
            <Box
              sx={{
                display: 'flex',
                width: '70px',
                justifyContent: 'space-between',
                '&: hover': {
                  cursor: 'pointer',
                },
              }}
              key={id}
            >
              <ETypography
                font='MS'
                color='primary600'
                onClick={() => handleSubmissionClick(id)}
              >
                Form
              </ETypography>

              {!viewOnly && (
                <LinkIcon
                  sx={{ color: AppColors.primary600 }}
                  onClick={() =>
                    toggleLinkedForms(formId, title, removeSubmission)
                  }
                />
              )}
            </Box>
          )
        })}

        {selectedSubId && (
          <BasicSubmissionModal
            open={submissionOpen}
            handleClose={handleModalClose}
            orgId={orgId}
            formId={formId}
            subId={selectedSubId}
            form={selectedFormJson}
            formReadOnly={selectedFormJson}
          />
        )}
      </InnerPageContainer>
    )
  }

  return (
    <>
      <SubmissionsModal
        basicModal={true}
        formId={formId}
        open={open}
        handleClose={() => setOpen(false)}
        handleAddSelection={toggleLinkedForms}
        selectedSubmissionIds={selectedSubmissionIds}
        readOnlyFormJson={readOnlyFormJson}
      />

      <InnerPageContainer
        headerTitle={`${title}`}
        renderRightItems={() =>
          viewOnly ? (
            <></>
          ) : (
            <EdifyButton
              onClick={() => setOpen(true)}
              buttonStyle={{ padding: 0, margin: 0 }}
              secondary
              title='+'
            />
          )
        }
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          marginTop: '24px',
        }}
      ></InnerPageContainer>
    </>
  )
}
