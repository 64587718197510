// ED-1386 https://edifyai.atlassian.net/browse/ED-1386
import React, { useContext, useEffect, useState } from 'react'
import useQuery from '../../../core/hooks/useQuery'
import { IGoal, IParticipant } from '../../../domain/interfaces/IGoal'
import { showConfirmationDeleteMessage } from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import {
  // adminPauseGoal,
  getParticipants,
  adminDeleteParticipants,
  getParticipantById,
  getGoalById,
  adminDeleteGoalById,
  getCurrentUser,
} from '../../../domain/domain'
import { useNavigate, useParams } from 'react-router-dom'
import { ISearchResult } from '../../components/form/EdifySearch/EdifySearchDropdown'
import { DEFAULT_LIMIT } from '../../components/datagrid/EdifyDataGrid'
import { normalizeParticipantsForGrid } from '../../components/datagrid/gridData/participants'
import { ROUTE_GOALS } from '../goals/GoalsPage'

export interface GoalPageViewModelProps {
  normalizeParticipantsForDataGrid: () => any
  queryHelper: Record<string, any>
  goal: IGoal | undefined
  goalLoading: boolean
  goalError: string | undefined
  // participant?: IParticipant
  participantLoading?: boolean
  participantError?: string | undefined
  participants: IParticipant[]
  participantsLoading: boolean
  participantsError: string | undefined
  fetchParticipants: (fetchSkip: number, query: Record<string, any>) => void
  totalCount: number
  getGoal: () => void
  showDeleteConfirmation: (ids: string[]) => void
  showDeleteGoalConfirmation: (id: string) => void
  loadMore: (paginationModel: GridPaginationModel) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  onParticipantSearch: (result: ISearchResult) => void
  deleteSelectedParticipants: (ids: string[]) => void
  loadParticipants: () => void
  goalNotFound: boolean
  errorObj: any | undefined
}

const GoalPageContext = React.createContext<GoalPageViewModelProps | null>(null)

export function useGoalPageViewModel(): GoalPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(GoalPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const GoalPageViewModel: React.FC<Props> = ({ children }) => {
  const { id } = useParams()
  const user = getCurrentUser()

  const queryHelper = useQuery({
    ...(id ? { goalId: id } : {}),
    skip: 0,
    limit: DEFAULT_LIMIT,
  })
  const { query, getQueryStringFromQuery, setNew } = queryHelper
  // goal state
  const [goal, setGoal] = useState<IGoal>()
  const [goalLoading, setGoalLoading] = useState<boolean>(false)
  const [goalNotFound, setGoalNotFound] = useState<boolean>(false)
  const [goalError, setGoalError] = useState<string | undefined>(undefined)
  const [errorObj, setErrorObj] = useState<any | undefined>(undefined)

  //participant state
  // const [participant, setParticipant] = useState<IParticipant>()
  // const [participantError, setParticipantError] = useState<string | undefined>()
  // const [participantLoading, setParticipantLoading] = useState<boolean>(false)
  //participants state
  const [participants, setParticipants] = useState<IParticipant[]>([])
  const [participantsError, setParticipantsError] = useState<
    string | undefined
  >()
  const [participantsLoading, setParticipantsLoading] = useState<boolean>(false)

  const [totalCount, setTotalCount] = useState<number>(0)
  const navigate = useNavigate()

  const loadParticipants = async () => {
    setParticipantsError(undefined)
    setParticipantsLoading(true)

    fetchParticipants(0, query)
  }

  const fetchParticipants = async (
    fetchSkip: number,
    query: Record<string, any>,
  ) => {
    // will we ever not have an id?
    if (!id) return
    setParticipantsError(undefined)
    setParticipantsLoading(true)

    const newQuery = { ...query, skip: fetchSkip }

    // not sure how this will work with roles
    const res = await getParticipants(getQueryStringFromQuery(newQuery))
    setParticipantsLoading(false)
    if (res.isLeft()) {
      setGoalError(res.left?.message ?? 'Error getting goal')
      return
    }
    setTotalCount(res.right?.total_count || 0)
    setParticipants(res.right?.data as IParticipant[])
    setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchParticipants(fetchSkip, query)
  }

  const deleteSelectedParticipants = async (ids: string[]) => {
    const res = await adminDeleteParticipants(ids)
    const participantsString = ids.length == 1 ? 'Participant' : 'Participants'
    const participantsStringLower =
      ids.length == 1 ? 'Participants' : 'participants'
    if (res.isRight()) {
      SuccessToast({ title: `${participantsString} removed ` })
      fetchParticipants(query.skip, query)
    } else {
      ErrorToast({ title: `Error removing ${participantsStringLower}.` })
    }
  }

  const deleteSelectedGoal = async (id: string) => {
    const res = await adminDeleteGoalById(id)

    if (res.isRight()) {
      SuccessToast({ title: 'Goal removed' })
      navigate(ROUTE_GOALS)
    } else {
      ErrorToast({ title: 'Error removing Goal.' })
    }
  }

  const showDeleteConfirmation = async (ids: string[]) => {
    const confirmMessage =
      ids.length > 1 ? `${ids.length} participants` : '1 participant'

    showConfirmationDeleteMessage(confirmMessage, () =>
      deleteSelectedParticipants(ids),
    )
  }

  const showDeleteGoalConfirmation = async (id: string) => {
    showConfirmationDeleteMessage(
      'Are you sure you want to delete this Goal?',
      () => deleteSelectedGoal(id),
    )
  }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    const newQuery = {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    }
    // TODO: BUG THIS IS GETTING CALLED ON MOUNT
    // DOES THIS GET CALLED ON MOUNT?
    fetchParticipants(0, newQuery)
  }

  const onParticipantSearch = async (
    participantSearchResult: ISearchResult,
  ) => {
    // check to see if it is already in the page
    const matchedParticipant = participants.find(
      (p) => p.id == participantSearchResult.id,
    )
    if (matchedParticipant) {
      setParticipants([matchedParticipant])
      setTotalCount(1)
      return
    }
    // if not grab from API
    if (!matchedParticipant) {
      setParticipantsLoading(true)
      setParticipantsError(undefined)
      const res = await getParticipantById(participantSearchResult.id)
      setParticipantsLoading(false)
      if (res.right) {
        setParticipants([res.right])
        setTotalCount(1)
      } else {
        ErrorToast({ title: 'Could not find goal' })
      }
    }
  }
  const getGoal = async () => {
    setGoalLoading(true)
    const res = await getGoalById(id!)
    // TODO: NEED API END POINT FOR PARTICIAPANTS
    setGoalLoading(false)
    if (res.isLeft()) {
      setErrorObj(res.left)
      setGoalError(res.left?.message ?? 'Error getting goal')
      if (res.left?.statusCode === 403) {
        setGoalNotFound(true)
      }

      return
    }
    setGoal(res.right)
  }

  useEffect(() => {
    getGoal()
    loadParticipants()
  }, [])

  const normalizeParticipantsForDataGrid = () => {
    return participants
      ? normalizeParticipantsForGrid(
        participants,
        goal?.goalCount,
        user?.id ?? '',
      )
      : []
  }

  return (
    <GoalPageContext.Provider
      value={{
        normalizeParticipantsForDataGrid,
        deleteSelectedParticipants,
        goal,
        queryHelper,
        goalLoading,
        goalError,
        // participant,
        // participantLoading,
        // participantError,
        participants,
        participantsLoading,
        participantsError,
        fetchParticipants,
        totalCount,
        getGoal,
        showDeleteConfirmation,
        showDeleteGoalConfirmation,
        sortClickedHandler,
        onParticipantSearch,
        loadMore,
        loadParticipants,
        goalNotFound,
        errorObj,
      }}
    >
      {children}
    </GoalPageContext.Provider>
  )
}
export default GoalPageContext
