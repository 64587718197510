import React from 'react'
import { EdifyButton } from '../edifyButton/EdifyButton'
import { ButtonProps, CircularProgress } from '@mui/material'
import { AppColors } from '../../../Theme'
import { DownloadDisabledIcon } from '../../../../core/components/icons/DownloadDisabledIcon'
import { DownloadIcon } from '../../../../core/components/icons/DownloadIcon'
import { EyeIcon } from '../../../../core/components/icons/EyeIcon'

interface Props extends ButtonProps {
  showHidden: boolean
  onClick: () => void
  loading: boolean
}

const ShowHiddenFormsButton: React.FC<Props> = ({
  showHidden = false,
  onClick,
  loading,
}) => {
  return (
    <>
      <EdifyButton
        disabled={loading}
        secondary
        noBackground
        textStyle={{
          color: AppColors.primary600
          // showHidden || loading ? AppColors.neutral700 : AppColors.primary600,
        }}
        buttonStyle={{ backgroundColor: AppColors.baseWhite }}
        title={`${showHidden ? 'Show All Forms' : 'Show Hidden Forms'}`}
        onClick={onClick}
      />
    </>
  )
}
export default ShowHiddenFormsButton
