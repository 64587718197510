import React, { useContext, useEffect, useState } from 'react'
import { ICorrectiveAction } from '../../../domain/interfaces/ICorrectiveAction'
import {
  adminDeleteCorrectiveActions,
  getCorrectiveActions,
  getCurrentOrgId,
  getCurrentUser,
  getOwnedCorrectiveActions,
  getPersonsCorrectiveActions,
  updateCorrectiveAction,
} from '../../../domain/domain'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { DEFAULT_LIMIT } from '../../components/datagrid/EdifyDataGrid'

import useQuery from '../../../core/hooks/useQuery'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { normalizeCorrectiveActionRows } from '../../components/datagrid/gridData'
import { useNavigate } from 'react-router-dom'
import { ErrorToast, SuccessToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { canCreateSubmission } from '../submission/submissionPermissionHelpers'
import useSessionQuery from '../../../core/hooks/useSessionQuery'
import { ROUTE_CORRECTIVE_ACTIONS } from './CorrectiveActionsPage'
import {
  hideConfirmationDialog,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import pluralize from 'pluralize'

export interface CorrectiveActionsPageViewModelProps {
  clearError?: () => void
  clearFilters: (reload: boolean) => void
  correctiveActionsLoading: boolean
  correctiveActionError: string | undefined
  fetchCorrectiveActions: (skip: number, query?: any) => void

  loadMore: (paginationModel: GridPaginationModel) => void
  queryHelper: Record<string, any>
  loadCorrectiveActions: () => void
  setTotalCount?: (value: number) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  correctiveActions: ICorrectiveAction[] | undefined
  totalCount: number
  // normalizeCAForDataGrid: () => any
  isLoading: boolean
  initialLoad: boolean
  correctiveActionUpdate: (id: string, updateObject: any) => void
  canCreate: boolean
  isGlobalClearing: boolean
  isShea: boolean
  errorObj: any | undefined
  noFilters: () => boolean
  deleteCorrectiveActions: (ids: string[]) => void
}

const CorrectiveActionPageContext =
  React.createContext<CorrectiveActionsPageViewModelProps | null>(null)

export function useCorrectiveActionsPageViewModel(): CorrectiveActionsPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(CorrectiveActionPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const CorrectiveActionsPageViewModel: React.FC<Props> = ({
  children,
}) => {
  const navigate = useNavigate()
  const { person, isContractor, findForm, org } = useOrganizationProvider()
  const caForm = findForm(org?.caFormId)
  const canCreate = canCreateSubmission(caForm, getCurrentUser()) ?? false
  const orgId = getCurrentOrgId()
  const isShea =
    caForm?.orgId === 'Shea Homes' ||
    orgId == 'f8782756-9dd8-45cf-9495-70131108fd7f'
      ? true
      : false

  const queryHelper = useQuery({
    skip: 0,
    limit: DEFAULT_LIMIT,
    sortBy: isShea ? 'dateInspected' : 'createdAt',
    sortOrder: 'desc',
    // could use this logic to get users individual ca
    ...(isContractor ? { personId: person?.id } : {}),
  })

  // const queryHelper = useSessionQuery(ROUTE_CORRECTIVE_ACTIONS,{
  //   skip: 0,
  //   limit: DEFAULT_LIMIT,
  //   // could use this logic to get users individual ca
  //   ...(isContractor ? { personId: person?.id } : {}),
  // },ROUTE_CORRECTIVE_ACTIONS)
  const { query, getQueryStringFromQuery, setNew, defaultQuery } = queryHelper

  // CorrectiveAction state
  const [totalCount, setTotalCount] = useState<number>(0)
  const [correctiveActionsLoading, setCorrectiveActionLoading] =
    useState<boolean>(false)
  const [correctiveActionError, setCorrectiveActionError] = useState<
    string | undefined
  >(undefined)
  const [errorObj, setErrorObj] = useState<any | undefined>(undefined)
  const [correctiveActions, setCorrectiveActions] = useState<
    ICorrectiveAction[]
  >([])
  const [initialLoad, setInitialLoad] = useState(true)
  const [isGlobalClearing, setIsGlobalClearing] = useState(false)

  const clearFilters = async () => {
    setIsGlobalClearing(true)
    await fetchCorrectiveActions(0, defaultQuery)
    setIsGlobalClearing(false)
    setNew(defaultQuery)
  }

  // handles loading correctiveActions or corrective actions
  const fetchCorrectiveActions = async (
    fetchSkip: number,
    passedQuery?: Record<string, any>,
  ) => {
    setCorrectiveActionLoading(true)
    setCorrectiveActionError(undefined)
    const newQuery =
      fetchSkip != -1 ? { ...passedQuery, skip: fetchSkip } : { ...query }
    const queryString = getQueryStringFromQuery(newQuery)
    let res
    if (isContractor) {
      res = await getPersonsCorrectiveActions(queryString)
    } else {
      res = await getCorrectiveActions(queryString)
    }
    setCorrectiveActionLoading(false)

    if (res.isRight()) {
      setCorrectiveActions(res.right?.data as ICorrectiveAction[])
      setTotalCount(res.right?.total_count || 0)
    } else {
      setCorrectiveActionError(res.left?.message ?? 'Error')
      setErrorObj(res.left)
    }
    setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchCorrectiveActions(fetchSkip, query)
  }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    const newQuery = {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    }
    fetchCorrectiveActions(0, newQuery)
  }

  const loadCorrectiveActions = () => {
    setCorrectiveActionLoading(true)

    setCorrectiveActions([])
    fetchCorrectiveActions(0, query)
  }

  const noFilters = () => {
    return JSON.stringify(query) == JSON.stringify(defaultQuery)
  }

  useEffect(() => {
    // This setups the UI to look/act like act the other pages.
    const init = async () => {
      await fetchCorrectiveActions(0, query)
      setInitialLoad(false)
    }
    init()
  }, [])

  const onClearError = () => {
    setCorrectiveActionError(undefined)
  }

  const correctiveActionUpdate = async (id: string, updateObject: any) => {
    const res = await updateCorrectiveAction(id, updateObject)
    if (res.isRight()) {
      setCorrectiveActions([])
      fetchCorrectiveActions(-1)
    } else {
      ErrorToast({ title: 'Error updating corrective action.' })
    }
  }

  const deleteCorrectiveActions = async (ids: string[]) => {
    const confirmMessage = `${ids.length} ${pluralize(
      'corrective action',
      ids.length,
    )}`
    showConfirmationDialog({
      title: 'Confirm Delete',
      message: `Are you sure you want to delete ${confirmMessage} from the database? This action cannot be undone.`,
      cancelText: 'Cancel',
      confirmText: 'Delete',
      onCancel: function (): void {
        hideConfirmationDialog()
      },
      onConfirm: async () => {
        const results = await adminDeleteCorrectiveActions(ids)
        if (results.isLeft()) {
          ErrorToast({
            title: `Failed to delete ${confirmMessage} from the database`,
          })
        }

        if (results.right?.badIds && results.right?.badIds.length > 0) {
          ErrorToast({
            title: `You tried to delete ${results.right?.badIds.length} corrective actions that should not be on your page. This is a bug on our end`,
          })
          fetchCorrectiveActions(0, query)
        }
        if (results.right?.updatedIds) {
          SuccessToast({
            title: `Successfully deleted ${confirmMessage} from the database`,
          })
          fetchCorrectiveActions(0, query)
        }

      },
    })
  }

  return (
    <CorrectiveActionPageContext.Provider
      value={{
        correctiveActionUpdate,
        correctiveActions,
        correctiveActionsLoading,
        loadMore,
        correctiveActionError,
        clearError: onClearError,
        loadCorrectiveActions,
        clearFilters,
        fetchCorrectiveActions,
        queryHelper,
        totalCount,
        sortClickedHandler,
        isLoading: correctiveActionsLoading,
        initialLoad,
        canCreate,
        isGlobalClearing,
        isShea,
        errorObj,
        noFilters,
        deleteCorrectiveActions,
      }}
    >
      {children}
    </CorrectiveActionPageContext.Provider>
  )
}
export default CorrectiveActionPageContext
