import * as React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { AppColors, AppFonts } from '../../Theme'
import dayjs from 'dayjs'
import { SxProps } from '@mui/material'
import moment from 'moment'

interface IEdifyTimePickerProps {
  time: string | null
  setTime: (time: dayjs.Dayjs | null) => void
  disabled?: boolean
  error?: boolean
  sx?: SxProps
}

const EdifyTimePicker: React.FC<IEdifyTimePickerProps> = ({
  time,
  setTime,
  disabled = false,
  error = false,
  sx,
}) => {
  const formattedTime = time ? moment(time).local().set('minute', 0).format('h:mm A') : ''

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        disabled={disabled}
        className={`form-control e-input ${error ? 'error' : ''}`}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& input': {
            ...AppFonts.textMRegular,
            padding: '11.5px',
            paddingLeft: '0px',
            color: time ? AppColors.gray950 : AppColors.gray25,
          },
          ...sx,
        }}
        views={['hours']}
        value={dayjs(formattedTime, 'h:mm A')}
        defaultValue={dayjs(formattedTime, 'h:mm A')}
        format='h:mm A'

        onChange={(newTime) => {
          setTime(newTime?.set('minute', 0) ?? newTime)
        }}
        viewRenderers={{
          hours: renderTimeViewClock,

        }}
        slotProps={{
          inputAdornment: {
            position: 'start',
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default EdifyTimePicker