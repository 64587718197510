import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/page/PageContainer'
import { EdifyButton } from '../../components/buttons'
import { Box } from '@mui/material'
import { RolePageViewModel, useRolePageViewModel } from './RolePageViewModel'
import { RolePageHeader } from './RolePageHeader'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { ETypography } from '../../components/fonts/ETypography'
import { flattenFormData, transformRoleDataForBackend } from './helpers'
import {
  adminCreateRole,
  adminUpdateRole,
  getCurrentOrgId,
} from '../../../domain/domain'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { useNavigate } from 'react-router-dom'
import RolesRenderer from './RolesRenderer'
import { ERole, IPermission } from '../../../domain/interfaces/IRole'
import usePermission from '../../../core/hooks/usePermission'
import NotFoundPage from '../NotFoundPage'
import { showLangConfirmationV2Message } from '../../components/dialogs/confirmation-dialog/ConfirmationDialogV2'
import AiFormFeature from './AiFormFeature'
import { formatStandardRoleName } from '../../components/datagrid/gridData/roles'

export const ROUTE_ROLES = '/roles'
interface IRolePageProps {
  isDuplicate?: boolean
  children?: React.ReactElement | React.ReactElement[]
}

export const RoleFormPage: React.FC<IRolePageProps> = (props) => {
  return (
    <RolePageViewModel>
      <_Page {...props}>
        <PermissionsForms />
      </_Page>
    </RolePageViewModel>
  )
}

export const _Page: React.FC<IRolePageProps> = ({
  isDuplicate = false,
  children,
}) => {
  const vm = useRolePageViewModel()
  const [name, setName] = useState('')
  const [test, setTest] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const canCreate = usePermission(ERole.FormCreate)
  const canEdit = usePermission(ERole.FormEdit)
  if (vm.id && !canEdit) return <NotFoundPage />
  if (!vm.id && !canCreate) return <NotFoundPage />
  const getHeaderMode = () => {
    if (vm.id) {
      return 'EDIT'
    }
    return 'CREATE'
  }
  const getBreadCrumbs = () => {
    if (vm.loading || !vm.role) return []
    if (vm.id && !isDuplicate) {
      return [
        {
          title: vm.role?.name ?? '',
          to: `${ROUTE_ROLES}/${vm.role?.id} ?? 'not found`,
        },
        { title: 'Edit', to: ROUTE_ROLES },
      ]
    }
    return [{ title: 'Create New Role', to: ROUTE_ROLES }]
  }

  const handleSubmit = async () => {
    if (name.trim() === '') {
      ErrorToast({ title: 'Role name is required' })
      return
    }
    if (!vm.hasChanged(name)) {
      showLangConfirmationV2Message(
        '',
        'To save this role you must make changes to the feature or form permissions.',
        'Continue Editing',
        'Cancel',
        () => -1,
        () => navigate(-1),
      )
      return
    }
    let res
    let verb
    setIsLoading(true)
    const data = {
      name,
      orgId: getCurrentOrgId(),
      roleData: transformRoleDataForBackend(vm.roleData as IPermission[]),
      formData: flattenFormData(vm.formData),
    }
    setTest(data)
    if (vm.id && !isDuplicate) {
      res = await adminUpdateRole(data, vm.id)
      verb = 'updated'
    } else {
      res = await adminCreateRole(data)
      verb = 'saved'
    }
    setIsLoading(false)
    if (res.isLeft()) {
      ErrorToast({ title: res.left?.message ?? 'Error saving role' })
      return
    }
    SuccessToast({ title: `Role successfully ${verb}.` })
    navigate(`${ROUTE_ROLES}/${res.right?.id}`)
  }

  const isNewPage = isDuplicate || getHeaderMode() == 'CREATE'
  const getTitle = () => {
    if (vm.loading) return 'Loading...'
    if (isNewPage) {
      if (isDuplicate) {
        return `Copy ${formatStandardRoleName(vm.role?.name ?? '')}`
      }
      return 'Create New Role'
    }
    return `Edit ${vm.role?.name}`
  }
  useEffect(() => {
    if (vm.id && vm.role && !isDuplicate) {
      setName(formatStandardRoleName(vm.role.name))
    }
  }, [vm.id, vm.role])
  return (
    <PageContainer
      mainPageLoading={vm.loading}
      mainPageError={vm.error}
      title={getTitle()}
      subTitleComponent={
        isDuplicate
          ? 'Edit Custom Role'
          : isNewPage
            ? 'Customize your new role'
            : 'Edit Custom Role'
      }
      breadCrumbs={[
        { title: 'Settings', to: '' },
        { title: 'Manage Roles', to: ROUTE_ROLES },
        ...getBreadCrumbs(),
      ]}
      renderRightItems={() => (
        <EdifyButton
          buttonStyle={{ height: '48px' }}
          title={isNewPage ? 'Save New Role' : 'Update Role'}
          onClick={handleSubmit}
          disabled={isLoading}
          loading={isLoading}
        />
      )}
    >
      <Box sx={{ marginBottom: '12px' }}>
        <RolePageHeader
          mode={getHeaderMode()}
          name={name}
          setName={setName}
          defaultRoles={vm.systemRoleOptions}
          setDefaultRole={vm.setActiveSystemRole}
          role={vm.activeSystemRole}
          getRole={vm.getSelectRole}
        />
      </Box>
      {children}

      <AiFormFeature />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt:24}} >
        <EdifyButton
          buttonStyle={{ height: '48px' }}
          title={isNewPage ? 'Save New Role' : 'Update Role'}
          onClick={handleSubmit}
          disabled={isLoading}
          loading={isLoading}
        />
      </Box>
    </PageContainer>
  )
}

const PermissionsForms: React.FC = () => {
  const vm = useRolePageViewModel()
  return (
    <>
      <InnerPageContainer
        innerPageLoading={vm.roleLoading}
        sx={{ padding: '0px', marginBottom: '24px' }}
      >
        <Box sx={{ padding: '24px 24px 0 24px', marginBottom: '12px' }}>
          <ETypography font='MS' color='gray900'>
            FEATURES
          </ETypography>
          <ETypography font='MM' color='neutral800'>
            List of all features within Safety Mojo and the ability to configure
            visibility.
          </ETypography>
        </Box>
        <RolesRenderer roleData={vm.roleData} setRoleData={vm.setRoleData} />
      </InnerPageContainer>
      <InnerPageContainer
        innerPageLoading={vm.roleLoading}
        sx={{ padding: '0px', marginBottom: '12px' }}
      >
        <Box sx={{ padding: '24px 24px 0 24px' }}>
          <ETypography font='MS' color='gray900'>
            FORMS
          </ETypography>
          <ETypography font='MM' color='neutral800'>
            List of all forms your organization currently has available and the
            ability to configure visibility for each one.
          </ETypography>
          {vm.hideForms && (
            <ETypography font='SR' color='danger600' sx={{ mt: '12px', mb:'24px' }}>
              NOTE: Submission Management (above) must be enabled to edit
              individual forms.
            </ETypography>
          )}
        </Box>

        {!vm.hideForms && (
          <RolesRenderer roleData={vm.formData} setRoleData={vm.setFormData} />
        )}

      </InnerPageContainer>
    </>
  )
}
