/* eslint-disable react/prop-types */
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, DialogActions } from '@mui/material'

import { EdifyButton } from '../../buttons'
import { AppColors, AppFonts } from '../../../Theme'
import { EdifyTextField } from '../../textfield/EdifyTextField'
import { ETypography } from '../../fonts/ETypography'
import { QRCode } from 'react-qrcode-logo'
// import smLogo from '../../../../assets/images/Mojo-AppleApp-Icon.svg'
import smLogo from '../../../../assets/images/Mojo-AppleApp-Icon.png'
// import smLogo from '../../../../assets/images/SafetyMojo_Logo_1.png'
// import smLogo from '../../../../assets/icons/photo.svg'
// import smLogo from '../../../../assets/images/mojoai.png'
import { CopyToClipBoard } from '../../../../core/components/icons/CopyToClipBoard'
import { MessageIcon } from '../../../../core/components/icons/MessageIcon'
import { PhotoIcon } from '../../../../core/components/icons/PhotoIcon'
import { PrintIcon } from '../../../../core/components/icons/PrintIcon'
import { EmailIcon } from '../../../../core/components/icons/Email'
import { useCopyToClipboard } from 'usehooks-ts'
import {
  ErrorToast,
  SuccessToast,
} from '../../../../core/utils/toast-notifications/ToastNotifications'

let setController:
  | React.Dispatch<React.SetStateAction<IQRCodeDialogV2Props | undefined>>
  | undefined

let showDialog: React.Dispatch<React.SetStateAction<boolean>> | undefined
/**
 * Show [Confirmation Dialog].
 *
 * @param submission
 */
export function showQRCodeDialogV2(props: IQRCodeDialogV2Props) {
  setController?.(props)
  showDialog?.(true)
}

export function hideQRCodeDialogV2() {
  setController?.(() => undefined)
  showDialog?.(false)
}

export interface IQRCodeDialogV2Props {
  title: string
  url: string
  message: string
  cancelText?: string
  confirmText?: string
  onCancel: () => void
  oneButton?: boolean
}

export const QRCodeDialogV2 = () => {
  const [open, setOpen] = React.useState(false)
  const [props, setProps] = React.useState<IQRCodeDialogV2Props>()
  const [copiedText, copy] = useCopyToClipboard()

  const canvasRef = React.useRef<HTMLCanvasElement | null>(null)

  setController = setProps
  showDialog = setOpen

  const closeDialog = () => {
    props?.onCancel()
    hideQRCodeDialogV2()
  }

  const downloadImage = (): void => {
    // Replace '#myCanvas' with the actual id or '.myCanvasClass' with the class of your canvas element
    const canvas = document.querySelector('canvas') as HTMLCanvasElement

    if (!canvas) {
      return
    }
    // Assuming the canvas context is not null as well, since we're able to draw on it elsewhere
    const image: string = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')

    const link: HTMLAnchorElement = document.createElement('a')
    link.download = 'my-canvas.png'
    link.href = image
    document.body.appendChild(link) // Append to the body to ensure it's in the document
    link.click()
    document.body.removeChild(link) // Clean up by removing the link from the document
  }

  const printCanvas = (): void => {
    const canvas = document.querySelector('canvas') as HTMLCanvasElement
    if (!canvas) {
      return
    }

    // Convert canvas to an image
    const imageData: string = canvas.toDataURL('image/png')
    // Specify your desired dimensions
    const desiredWidth = 300 // Example width in pixels
    const desiredHeight = 300 // Example height in pixels
    const imageElement = `<img src="${imageData}" width="${desiredWidth}" height="${desiredHeight}">`

    // const imageElement = `<img src="${imageData}">`

    // Create a hidden iframe
    const iframe: HTMLIFrameElement = document.createElement('iframe')
    iframe.style.position = 'absolute'
    iframe.style.width = '0'
    iframe.style.height = '0'
    iframe.style.border = '0'
    document.body.appendChild(iframe)

    // TypeScript safe check for contentDocument
    if (iframe.contentWindow && iframe.contentDocument) {
      // Write the image into the iframe
      iframe.contentDocument.write(imageElement)
      iframe.contentDocument.close() // Close the document to complete the writing process

      // Wait for the image to load in the iframe
      iframe.onload = () => {
        // Print the iframe content
        if (iframe.contentWindow) {
          iframe.contentWindow.print()

          // Optionally remove the iframe after a delay to ensure print dialog has opened
          setTimeout(() => {
            document.body.removeChild(iframe)
          }, 500)
        }
      }
    } else {
      ErrorToast({ title: 'Unable to print.' })
    }
  }

  const onCopyLink = () => {
    copy(props?.url ?? '').then(() => {
      SuccessToast({ title: 'Link copied to clipboard.' })
      // DO we want to show a message that the link has been copied?
    })
  }

  return (
    <Dialog
      data-testid='QRCodeDialogV2'
      open={open}
      maxWidth={'sm'}
      PaperProps={{
        sx: dialogStyling,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle
          sx={{
            ...AppFonts.textXLSemibold,
            color: AppColors.gray700,
            p: 0,
          }}
        >
          {props?.title}
        </DialogTitle>
        <ETypography
          font='BTSS'
          color='gray900'
          onClick={closeDialog}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          X
        </ETypography>
      </Box>
      <DialogContent sx={{ padding: '0 0 24px 0' }}>
        {/* <DialogContentText
          sx={{ ...AppFonts.textSMedium, color: AppColors.gray50, padding: 0 }}
        >
          {props?.message} </DialogContentText> */}
        <ETypography font='SR' color='gray700'>
          {props?.message}
        </ETypography>
      </DialogContent>
      <Box
        sx={{
          border: `3px solid ${AppColors.neutral600}`,
          borderRadius: '6px',
        }}
      >
        <QRCode
          // ref={canvasRef}
          value={props?.url}
          logoImage={smLogo}
          size={290}
          logoOpacity={1}
          logoWidth={90}
          logoHeight={90}
          // logoPadding={2}
        />
      </Box>
      <Box
        sx={{
          marginTop: '24px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          p: 0,
          gap: '12px',
        }}
      >
        <EdifyButton
          buttonStyle={{ width: '100%', flex: 1, justifyContent: 'center' }}
          data-testid='CopyLink'
          title={'Copy Link'}
          secondary
          icon={<CopyToClipBoard />}
          onClick={onCopyLink}
          textStyle={{ flex: 'none' }}
        />
        {/* <EdifyButton
          buttonStyle={{ width: '100%', flex: 1, justifyContent: 'center' }}
          data-testid='SendSMS'
          title={'Send SMS'}
          secondary
          icon={<MessageIcon />}
          onClick={onSaveAsDraftClick}
        /> */}
        <EdifyButton
          buttonStyle={{ width: '100%', flex: 1, justifyContent: 'center' }}
          data-testid='DownloadPhoto'
          title={'Download Photo'}
          icon={<PhotoIcon />}
          secondary
          onClick={downloadImage}
          textStyle={{ flex: 'none' }}
        />
        {/* <EdifyButton
          buttonStyle={{ width: '100%', flex: 1, justifyContent: 'center' }}
          data-testid='EmailQR'
          title={'Email'}
          icon={<EmailIcon />}
          secondary
          onClick={onSaveAsDraftClick}
        /> */}
        <EdifyButton
          buttonStyle={{ width: '100%', flex: 1, justifyContent: 'center' }}
          data-testid='DownloadPhoto'
          title={'Print'}
          icon={<PrintIcon />}
          secondary
          onClick={printCanvas}
          textStyle={{ flex: 'none' }}
        />
      </Box>
    </Dialog>
  )
}

const ANON_MESSAGE =
  'This is a qr code to create an anonymous submission. You can share this link with anyone.'

const USER_MESSAGE = 'Scan this QR Code to get information on this User'

const SELF_SIGNUP_MESSAGE = 'Users can scan this QR code or copy the link to signup.'

const SHARE_SUBMISSION_MESSAGE = 'Users can scan this QR to view this submission.'



export const showQRCodeMessage = async (url: string, type = 'anon') => {
  const message =
    type === 'anon'
      ? ANON_MESSAGE
      : type === 'signup'
        ? SELF_SIGNUP_MESSAGE
        : type == 'share' ?  SHARE_SUBMISSION_MESSAGE : USER_MESSAGE
  return showQRCodeDialogV2({
    title: 'QR Code',
    url,
    message: message,
    cancelText: 'Save as Draft',
    confirmText: 'Create',
    onCancel: function (): void {
      hideQRCodeDialogV2()
    },
  })
}

const dialogStyling = {
  width: '366px',
  height: 'auto',
  backgroundColor: AppColors.g000,
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 24,
}
