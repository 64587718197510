import {
  Box,
  DialogContent,
  DialogTitle,
  Modal,
} from '@mui/material'
import React, { useEffect } from 'react'
import { AppColors, AppFonts } from '../../Theme'
import ErrorContainer from '../../components/error/ErrorContainer'
import {
  SubmissionsPageViewModel,
  useSubmissionsPageViewModel,
} from '../submissions/SubmissionsViewModel'
import DynamicSubmissionFilter from '../submissions/DynamicSubmissionFilter'
import SubmissionDataGrid from '../submissions/SubmissionsDataGrid'
import { GridRowSelectionModel } from '@mui/x-data-grid'


interface Props {
  open: boolean
  handleClose: (reload?: any) => void
  handleAddSelection: (
    formId: string,
    formTitle: string,
    selections: string[],
  ) => void
  formId: string
  selectedSubmissionIds?: string[]
  basicModal?: boolean
  readOnlyFormJson?: any
}

const _SubmissionsModal: React.FC<Props> = ({
  open,
  handleClose,
  formId,
  handleAddSelection,
  selectedSubmissionIds,
  basicModal=false,
  readOnlyFormJson
}) => {
  const vm = useSubmissionsPageViewModel()
  useEffect(() => {
    // ?
  }, [open])
  const getTitle = () => {
    return vm.form?.title || 'Submissions'
  }
  const renderError = () => {
    return <ErrorContainer>{'error'}</ErrorContainer>
  }

  const handleAddSelected = (selections: GridRowSelectionModel) => {
    // here we have the IDS maybe pass through to VM of hook
    handleAddSelection &&
      handleAddSelection(
        formId,
        getTitle(),
        selections.map((s) => s+''),
      )
  }

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby='child-modal-title'
        aria-describedby='child-modal-description'
        sx={{ marginTop: '50px' }}
      >
        <Box
          sx={{
            ...styles,
            width: {
              sm: '100%',
              md: '90%',
            },
          }}
        >
          <DialogTitle
            sx={{
              ...AppFonts.headlineSDesktopSemibold,
              color: AppColors.gray700,
              p: 0,
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            {getTitle()}
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              pb: 12,
              overflowY: 'scroll',
              height: 'auto',
              overflow: 'scroll',
            }}
          >
            {/* <SubmissionFilter vm={vm} /> */}
            <DynamicSubmissionFilter vm={vm} showDrafts={false} />
            {/* TEMP FIX FOR SORT LOOP BUG<  */}
            {vm.form && vm.form.id == formId && (
              <SubmissionDataGrid
                vm={vm}
                showPDF={false}
                title={getTitle()}
                modalButtonGroup={true}
                handleAddSelected={handleAddSelected}
                handleCloseModal={handleClose}
                selectedSubmissionIds={selectedSubmissionIds}
                basicModal={basicModal}
                readOnlyFormJson={readOnlyFormJson}
              />
            )}
          </DialogContent>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

const styles = {
  position: 'absolute',
  top: '10vh',
  left: '50%',
  transform: 'translate(-50%, -50px)',
  height: 'auto',
  maxHeight: '80vh',
  overflow: 'scroll',
  backgroundColor: AppColors.g000,
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 24,
}

// export default _SubmissionsModal

const SubmissionsModal: React.FC<Props> = (props) => {
  return (
    <SubmissionsPageViewModel formIdProp={props.formId}>
      <_SubmissionsModal {...props} />
    </SubmissionsPageViewModel>
  )
}

export default SubmissionsModal
