import React from 'react'
import { Box, Input, SxProps, Typography, InputProps } from '@mui/material'
import { SvgIconComponent } from '@mui/icons-material'
import { UseFormRegister } from 'react-hook-form' // Optional if you're using react-hook-form
import { AppColors } from '../../../Theme'

interface Props {
  label: string // Input label
  placeholder: string // Placeholder text
  icon: SvgIconComponent // Material-UI icon component
  register: UseFormRegister<any> // react-hook-form's register function
  name: string // Name of the input field
  errors: any // Form errors object
  sx?: SxProps
  disabled?: boolean
  inputProps?: InputProps  
}

const EdifyFormFieldWithIcon: React.FC<Props> = ({
  label,
  placeholder,
  icon: Icon,
  register,
  name,
  errors,
  disabled=false,
  inputProps,
  sx,
}) => {
  return (
    <Box sx={{ flex: 1, marginTop:'0px', ...sx}}>
      {/* Label */}
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          color: 'text.primary',
          marginBottom: '8px',
        }}
      >
        {label}
      </Typography>

      {/* Input Container */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid',
          borderColor: errors?.[name] ? AppColors.d600 : AppColors.neutral600,
          borderRadius: '4px',
          paddingX: '8px',
          backgroundColor: '#fff',
          height: '48px',
          '&:focus-within': {
            borderColor: 'primary.main',
          },
        }}
      >
        {/* Icon */}
        <Icon sx={{ color: AppColors.neutral950, marginRight: '8px' }} />

        {/* Input */}
        <Input
          disableUnderline
          placeholder={placeholder}
          fullWidth
          disabled={disabled}
          {...register(name)}
          {...inputProps}
          sx={{
            fontSize: '16px',
            color: 'text.primary',
          }}
        />
      </Box>

      {/* Error Message */}
      {errors?.[name] && (
        <Typography
          sx={{
            color: AppColors.danger600,
            fontSize: '12px',
            marginTop: '4px',
          }}
        >
          {errors[name].message}
        </Typography>
      )}
    </Box>
  )
}

export default EdifyFormFieldWithIcon
