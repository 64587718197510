import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { App } from './presentation/App'
import reportWebVitals from './reportWebVitals'
import { AppToaster } from './core/utils/toast-notifications/ToastNotifications'
import { Formio } from 'formiojs'
import premium from '@formio/premium'

Formio.use(premium)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <AppToaster />
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
