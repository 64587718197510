import React, { useState } from 'react'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'

import { saveToLocalStorage } from '../../../data/data'
import { SuccessToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { EdifyFormField, EdifyFieldLabel } from '../../components/form'
import ErrorContainer from '../../components/error/ErrorContainer'
import { updateOrg } from '../../../data/repositories/orgs/OrgRepository'
import EdifyToggle from '../../components/form/EdifyToggle/EdifyToggle'
import pluralize from 'pluralize'

export default function OrgFlags() {
  const { org, flags, setFlags, getHierarchyName } = useOrganizationProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const projectName = getHierarchyName(0)

  // mobile request for isAskMojoEnabled to enable/disable mobile homepage widgets
  const isAskMojoEnabledToggled = async () => {
    setLoading(true)
    const res = await updateOrg(org!.id, {
      isAskMojoEnabled: !flags.isAskMojoEnabled,
    })
    setLoading(false)
    if (res.isRight()) {
      SuccessToast({
        title: `Mobile homepage widgets are now ${
          res.right?.isAskMojoEnabled ? 'enabled' : 'disabled'
        }.`,
      })
      saveToLocalStorage('org', JSON.stringify(res.right))
      setFlags({
        ...flags,
        isAskMojoEnabled: res.right?.isAskMojoEnabled ? true : false,
      })
      return
    }
    if (res.isLeft()) {
      setError(res.left?.message ?? 'Unsuccessfully toggled Mobile Documents.')
    }
  }

  const documentLibWebViewToggled = async () => {
    setLoading(true)
    const res = await updateOrg(org!.id, {
      documentLibWebViewEnabled: !flags.documentLibWebViewEnabled,
    })
    setLoading(false)
    if (res.isRight()) {
      SuccessToast({
        title: `Web documents are now ${
          res.right?.documentLibWebViewEnabled ? 'enabled' : 'disabled'
        }.`,
      })
      saveToLocalStorage('org', JSON.stringify(res.right))
      setFlags({
        ...flags,
        documentLibWebViewEnabled: res.right?.documentLibWebViewEnabled
          ? true
          : false,
      })
      return
    }
    if (res.isLeft()) {
      setError(res.left?.message ?? 'Unsuccessfully toggled Web Documents.')
    }
  }

  const documentLibraryEnabledToggle = async () => {
    setLoading(true)
    const res = await updateOrg(org!.id, {
      isDocumentLibraryEnabled: !flags.isDocumentLibraryEnabled,
    })
    setLoading(false)
    if (res.isRight()) {
      SuccessToast({
        title: `Documents are now ${
          res.right?.isDocumentLibraryEnabled ? 'enabled' : 'disabled'
        }.`,
      })
      saveToLocalStorage('org', JSON.stringify(res.right))
      setFlags({
        ...flags,
        isDocumentLibraryEnabled: res.right?.isDocumentLibraryEnabled
          ? true
          : false,
      })
      return
    }
    if (res.isLeft()) {
      setError(res.left?.message ?? 'Unsuccessfully toggled Web Documents.')
    }
  }

  const locationFlagToggled = async () => {
    setLoading(true)
    const res = await updateOrg(org!.id, {
      isLocationEnabled: !flags.isLocationEnabled,
      isSubLocationEnabled: false,
    })
    setLoading(false)
    if (res.isRight()) {
      SuccessToast({
        title: `Locations are now ${
          res.right?.isLocationEnabled ? 'enabled' : 'disabled'
        }.`,
      })
      saveToLocalStorage('org', JSON.stringify(res.right))
      setFlags({
        ...flags,
        isLocationEnabled: res.right?.isLocationEnabled ? true : false,
        isSubLocationEnabled: false,
      })
      return
    }
    if (res.isLeft()) {
      setError(res.left?.message ?? 'Unsuccessfully toggled location flag.')
    }
  }

  const subLocationFlagToggled = async () => {
    setLoading(true)
    const res = await updateOrg(org!.id, {
      isLocationEnabled: true,
      isSubLocationEnabled: !flags.isSubLocationEnabled,
    })
    setLoading(false)
    if (res.isRight()) {
      SuccessToast({
        title: `Sub locations are now ${
          res.right?.isSubLocationEnabled ? 'enabled' : 'disabled'
        }.`,
      })
      saveToLocalStorage('org', JSON.stringify(res.right))
      setFlags({
        ...flags,
        isSubLocationEnabled: res.right?.isSubLocationEnabled ? true : false,
      })
      return
    }
    if (res.isLeft()) {
      setError(res.left?.message ?? 'Unsuccessfully toggled sub location flag.')
    }
  }

  const shareUserTrainingCertificationsToggled = async (e: any) => {
    setLoading(true)
    const res = await updateOrg(org!.id, {
      isUserQrCodeEnabled: !flags.isUserQrCodeEnabled,
    })
    setLoading(false)
    if (res.isLeft()) {
      setError(
        res.left?.message ??
          'Unsuccessfully toggled User Trainings and Certifications.',
      )
      setFlags({
        ...flags,
        isUserQrCodeEnabled: e.target.checked ? false : true,
      })
      return
    }
    if (res.isRight()) {
      SuccessToast({
        title: `User Trainings and Certifications are now ${
          res.right?.isUserQrCodeEnabled ? 'enabled' : 'disabled'
        }.`,
      })
      saveToLocalStorage('org', JSON.stringify(res.right))
      setFlags({
        ...flags,
        isUserQrCodeEnabled: res.right?.isUserQrCodeEnabled ? true : false,
      })
      return
    }
  }

  const orgSelfSignupFlagToggled = async () => {
    setLoading(true)
    const res = await updateOrg(org!.id, {
      isOrgSelfSignupEnabled: !flags.isOrgSelfSignupEnabled,
    })
    setLoading(false)
    if (res.isRight()) {
      SuccessToast({
        title: `Self Signup is now ${
          res.right?.isOrgSelfSignupEnabled ? 'enabled' : 'disabled'
        }.`,
      })
      saveToLocalStorage('org', JSON.stringify(res.right))
      setFlags({
        ...flags,
        isOrgSelfSignupEnabled: res.right?.isOrgSelfSignupEnabled
          ? true
          : false,
      })

      
      return
    }
    if (res.isLeft()) {
      setError(res.left?.message ?? 'Unsuccessfully toggled Self Signup')
      setFlags({
        ...flags,
      })
    }
  }
  console.log('flags', flags)
  return (
    <>
      <InnerPageContainer
        headerTitle='Organization Flags'
        sx={{ marginTop: '24px', paddingBottom: '24px' }}
      >
        {error && (
          <ErrorContainer sx={{ margin: '24px 0' }}>{error}</ErrorContainer>
        )}
        <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
          <EdifyFieldLabel label='Project Locations' />
          <EdifyToggle
            text='Locations'
            subText='Shows location information in project section'
            onChange={locationFlagToggled}
            checked={flags.isLocationEnabled}
          />
        </EdifyFormField>
        <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
          <EdifyToggle
            disabled={!flags.isLocationEnabled}
            checked={flags.isSubLocationEnabled}
            text='Sub Locations'
            subText='Shows locations sub location information in project section'
            onChange={subLocationFlagToggled}
          />
        </EdifyFormField>
        <EdifyFormField
          sx={{ flex: 1, marginRight: '24px', marginTop: '24px' }}
        >
          <EdifyFieldLabel label='User Information' />
          <EdifyToggle
            disabled={loading}
            checked={flags.isUserQrCodeEnabled}
            text='Share Trainings and Certifications'
            subText='Generates a option to share users training and certification in the user view page'
            onChange={shareUserTrainingCertificationsToggled}
          />
        </EdifyFormField>
        <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
          <EdifyFieldLabel label='Document Library' />
          <EdifyToggle
            text='Document Library'
            subText='Allows user to view/upload documents on mobile'
            onChange={documentLibraryEnabledToggle}
            checked={flags.isDocumentLibraryEnabled}
          />
        </EdifyFormField>

        <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
          <EdifyToggle
            disabled={!flags.isDocumentLibraryEnabled}
            text='Web Document Library'
            subText='Allows user to view/upload documents on web (Document Library must be enabled).'
            onChange={documentLibWebViewToggled}
            checked={
              flags.isDocumentLibraryEnabled && flags.documentLibWebViewEnabled
            }
          />
        </EdifyFormField>
        <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
          <EdifyToggle
            disabled={!flags.isDocumentLibraryEnabled}
            text='Mobile Home Document'
            subText='Show document library widget on mobile home page  (Document Library must be enabled).'
            onChange={isAskMojoEnabledToggled}
            checked={flags.isAskMojoEnabled && flags.isAskMojoEnabled}
          />
        </EdifyFormField>
        <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
          <EdifyFieldLabel label='Self Signup' />
          <EdifyToggle
            disabled={loading}
            checked={flags.isOrgSelfSignupEnabled}
            text={pluralize(projectName)}
            subText={`If enabled, allows users to self signup to and get added to a  ${projectName}.`}
            onChange={orgSelfSignupFlagToggled}
          />
        </EdifyFormField>
      </InnerPageContainer>
    </>
  )
}
