import React, { useState } from 'react'
import { Box } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'

import { AdminFormUpdate } from '../../../domain/domain'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import { searchForms } from '../../../data/data'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { IForm } from '../../../domain/interfaces/IForm'
import { globalSearch } from '../../../domain/usecases/utils/UtilsUsecasses'
import EdifyToggle from '../../components/form/EdifyToggle/EdifyToggle'

interface AdditionalFormsProps {
  form: IForm
}
export const AdditionalForms: React.FC<AdditionalFormsProps> = ({ form }) => {
  const { forms } = useOrganizationProvider()
  const [loading, setLoading] = useState<boolean>(false)
  const [additionalForms, setAdditionalForms] = useState<ISearchResult[]>(
    form.additionalForms!.map((form) => ({ name: form.title, id: form.id })),
  )
  const [linkedForms, setLinkedForms] = useState<ISearchResult[]>(
    form.linkForms!.map((form) => ({ name: form.title, id: form.id })),
  )
  const allForms = forms.map((form) => ({ name: form.title, id: form.id }))

  const additionalFormsSelected = (forms: ISearchResult[]) => {
    setAdditionalForms(forms)
  }

  const linkedFormsSelected = (forms: ISearchResult[]) => {
    setLinkedForms(forms)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const linkFormIds = linkedForms.map((form) => form.id)
    const additionalFormIds = additionalForms.map((form) => form.id)
    const res = await AdminFormUpdate(form.id, {
      linkFormIds,
      additionalFormIds,
    })
    setLoading(false)
    if (res.isRight()) {
      SuccessToast({ title: 'Forms Updated Successfully' })
    }
    if (res.isLeft()) {
      ErrorToast({ title: res.left?.message ?? 'Error Updating Form' })
    }
  }
  const [showAsLink, setShowAsLink] = useState<boolean>(form.showAsLink!)
  const [showAsLinkLoading, setShowAsLinkLoading] = useState<boolean>(false)
  const showAsLinkToggle = async () => {
    const id = form.id ?? ''
    setShowAsLinkLoading(true)
    const res = await AdminFormUpdate(id, { showAsLink: !showAsLink })
    setShowAsLinkLoading(false)
    if (res.isRight()) {
      setShowAsLink(res.right!.showAsLink!)
    }
  }

  return (
    <InnerPageContainer
      headerTitle='Connected Forms And Submissions'
      collapsible
      collapsed={true}
    >
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}>
        <EdifyFormField>
          <EdifyFieldLabel label={'Add Additional Forms'} />
          <EdifySearchDropdown
            defaultItems={allForms}
            multiSelect
            pillListWidth={'100%'}
            width={512}
            onSelect={additionalFormsSelected}
            initialSelectedItems={additionalForms}
            nameKey='title'
            globalSearchKey='forms'
            globalSearchPayload={{
              entities: ['forms'],
              properties: ['id'],
              returns: ['id', 'title'],
            }}
            searchFunction={globalSearch}
          />
        </EdifyFormField>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}>
        <EdifyFormField>
          <EdifyFieldLabel label={'Add Existing Form'} />
          <EdifySearchDropdown
            defaultItems={allForms}
            multiSelect
            pillListWidth={'100%'}
            width={512}
            key={'name'}
            onSelect={linkedFormsSelected}
            searchFunction={searchForms}
            initialSelectedItems={linkedForms}
          />
        </EdifyFormField>
      </Box>

      {/* IF WE WANT showAsLink AS Flag  */}
      <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
        <EdifyToggle
          text='Show Form as Link'
          subText='If toggled, the form submission will be shown as a link instead of in a tab.'
          onChange={showAsLinkToggle}
          checked={
            showAsLink
          }
          disabled={showAsLinkLoading}
        />
      </EdifyFormField>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          marginTop: '48px',
        }}
      >
        <Box>
          <EdifyButton
            onClick={handleSubmit}
            loading={loading}
            type='submit'
            primary
            title={'Save'}
          />
        </Box>
      </Box>
    </InnerPageContainer>
  )
}

export default AdditionalForms
