import React from 'react'
import {
  Modal,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import { AppColors, AppFonts } from '../../Theme'
import { ETypography } from '../../components/fonts/ETypography'
import { MercuryLogo } from '../../../core/components/icons/MercuryLogo'
import { useMediaQuery } from 'usehooks-ts'
import { SafetyMojoIcon } from '../../../core/components/icons/SafteyMojoLogo'
import { Close } from '@mui/icons-material'

import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material'

// Define props interface if you need to pass any props
interface HiddenSpanProps {
  children: React.ReactNode;
  className?: string;
}

const HiddenSpan = styled('span')<HiddenSpanProps>(({ theme }: { theme: Theme }) => ({
  display: 'inline',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

interface SingupSuccessModalProps {
  open: boolean
  onClose: () => void
}

const SingupSuccessModal: React.FC<SingupSuccessModalProps> = ({
  open,
  onClose,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '425px',
    height: 'auto',
    backgroundColor: AppColors.g000,
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    padding: 24,
  }
  interface Navigator {
    userAgentData?: {
      mobile: boolean
    }
  }
  const isMobile = useMediaQuery('(max-width:768px)') // Adjust the breakpoint if needed

  // Function to handle download button click
  const handleDownloadClick = () => {
    const userAgent = navigator.userAgent.toLowerCase()

    // // Check if `navigator.userAgentData` is available and use it for modern mobile detection
    // if (navigator.userAgentData && navigator.userAgentData.mobile) {
    //   if (/android/i.test(userAgent)) {
    //     // Navigate to Google Play Store
    //     window.location.href = 'https://play.google.com/store/apps/details?id=com.example.app'
    //   } else if (/iphone|ipod|ipad/i.test(userAgent)) {
    //     // Navigate to Apple App Store
    //     window.location.href = 'https://apps.apple.com/app/id123456789'
    //   }
    // } else {
    // Fallback to checking the userAgent string for older browsers
    if (/android/i.test(userAgent)) {
      // Navigate to Google Play Store
      window.location.href =
        'https://play.google.com/store/apps/details?id=ai.edify.rome'
    } else if (/iphone|ipod|ipad/i.test(userAgent)) {
      // Navigate to Apple App Store
      window.location.href = 'https://apps.apple.com/app/id6446137466'
    } else {
      alert('App download is only supported on mobile devices.')
    }
    onClose()
    // }
  }
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='child-modal-title'
        aria-describedby='child-modal-description'
        sx={{ marginTop: '50px' }}
      >
        <Box
          sx={{
            ...style,
            width: {
              sm: '90%',
              xs: '90%',
              md: '60%',
              lg: '640px',
            },
            maxWidth: '640px',
          }}
        >
          {/* <SafetyMojoLogo  width={116} height={35}/> */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SafetyMojoIcon width={160} height={30} />

            <Close
              sx={{
                color: AppColors.neutral900,
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={onClose}
            />
          </Box>
          <Box sx={{ justifyItems: 'center' }}>
            <Box sx={{ margin: '16px', textAlign:'-webkit-center' }}>
              <MercuryLogo width={132} height={132} sx={{display:'flex', justifyContent:'center', textAlign:'-webkit-center'}} />
            </Box>
            <DialogTitle
              sx={{
                ...AppFonts.headlineSDesktopSemibold,
                color: AppColors.gray700,
                p: 0,
                fontSize: '24px',
                fontWeight: 600,
                marginBottom: '8px',
                textAlign:'-webkit-center',
                width: '100%',
              }}
            >
              Sign Up Complete!
            </DialogTitle>
            <DialogContent
              sx={{
                p: 0,
                maxWidth: '295px',
                pb: 12,
                height: 'auto',
                margin: 'auto',
                textAlign:'-webkit-center'
              }}
            >
              <ETypography
                font='LR'
                color='gray50'
                sx={{ marginBottom: '12px', textAlign:'-webkit-center'}}
              >
                Your account has been created! <HiddenSpan>Tap below to download the app.</HiddenSpan>
                You’ve also received a welcome email with your login information
                and an SMS message with a link to download the app later.
              </ETypography>

              <ETypography
                font='LR'
                color='gray50'
                sx={{
                  marginBottom: '12px',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '14px',
                  textAlign:'-webkit-center'
                }}
              >
                If you’re having trouble find the email, check your junk or
                spam.
              </ETypography>

              {!isMobile && (
                <ETypography
                  font='LR'
                  color='gray50'
                  sx={{
                    marginBottom: '12px',
                    marginTop: '32px',
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '14px',
                    textAlign: 'center',
                  }}
                >
                  You can close this window.
                </ETypography>
              )}
            </DialogContent>
            {isMobile && (
              <DialogActions
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <EdifyButton
                  data-testid='DownloadButton'
                  fullWidth
                  style={{ margin: '6px' }}
                  title={'Download the App  >'}
                  primary
                  onClick={() => handleDownloadClick()}
                />
                <EdifyButton
                  data-testid='backButton'
                  fullWidth
                  style={{ margin: '6px' }}
                  title={'<  Back'}
                  secondary
                  onClick={() => onClose()}
                />
              </DialogActions>
            )}
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

export default SingupSuccessModal
