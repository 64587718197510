import { createTheme, Theme } from '@mui/material'
import 'typeface-inter'

export class AppColors {
  static readonly p000 = '#F8F2FF'
  static readonly p25  = '#ECEBFF'
  static readonly p100 = '#E7D3FE'
  static readonly p200 = '#CCA7FE'
  static readonly p300 = '#AE7CFE'
  static readonly p400 = '#945BFD'
  static readonly p500 = '#6A25FC'
  static readonly p600 = '#511BD8'
  static readonly p700 = '#3C12B5'
  static readonly p800 = '#290B92'
  static readonly p900 = '#1C0778'
  static readonly p1000 = '#0F0344'

  static readonly s000 = '#F4FBED'
  static readonly s100 = '#E7FBD4'
  static readonly s200 = '#CAF8AB'
  static readonly s300 = '#A1EB7E'
  static readonly s400 = '#79D85A'
  static readonly s500 = '#44BF2B'
  static readonly s600 = '#2BA41F'
  static readonly s700 = '#178915'
  static readonly s800 = '#0D6E13'
  static readonly s900 = '#085B13'
  static readonly s1000 = '#032508'

  static readonly i000 = '#E5F2FF'
  static readonly i100 = '#D3E9FE'
  static readonly i200 = '#A7D1FE'
  static readonly i300 = '#7CB5FE'
  static readonly i400 = '#5B9CFD'
  static readonly i500 = '#2574FC'
  static readonly i600 = '#1B59D8'
  static readonly i700 = '#1242B5'
  static readonly i800 = '#0B2E92'
  static readonly i900 = '#071F78'
  static readonly i1000 = '#04134B'

  static readonly w000 = '#FFFBEC'
  static readonly w100 = '#FFF6CF'
  static readonly w200 = '#FFE99F'
  static readonly w300 = '#FFDA70'
  static readonly w400 = '#FFCB4C'
  static readonly w500 = '#FFB311'
  static readonly w600 = '#DB920C'
  static readonly w700 = '#B77408'
  static readonly w800 = '#935805'
  static readonly w900 = '#7A4403'
  static readonly w1000 = '#402402'

  static readonly d000 = '#FFF5EF'
  static readonly d100 = '#FEE4D5'
  static readonly d200 = '#FEC2AD'
  static readonly d300 = '#FE9983'
  static readonly d400 = '#FD7264'
  static readonly d500 = '#FC3232'
  static readonly d600 = '#D82434'
  static readonly d700 = '#B51934'
  static readonly d800 = '#920F31'
  static readonly d900 = '#920F31'
  static readonly d1000 = '#3E041B'

  static readonly g000 = '#FFFFFF'
  static readonly g25 = '#E9FCED'
  static readonly g100 = '#F2F1F5'
  static readonly g200 = '#E6E4EB'
  static readonly g300 = '#BDBBC5'
  static readonly g400 = '#84828B'
  static readonly g500 = '#3A393E'
  static readonly g600 = '#2B2935'
  static readonly g700 = '#1F1C2C'
  static readonly g800 = '#141223'
  static readonly g900 = '#0D0A1D'
  static readonly g1000 = '#05040C'

  static readonly purple = '#5D3C98'
  static readonly darkPurple = '#4735B2'
  static readonly red = '#ED3B66'
  static readonly darkRed = '#B7022D'
  static readonly black = '#404040'
  static readonly whiteGrey = '#F2F1F5'
  static readonly grey = '#F6F6F6'

  static readonly gray950 = '#181818'
  static readonly gray900 = '#252525'
  static readonly gray800 = '#2B2D2F'
  static readonly gray700 = '#323539'
  static readonly gray600 = '#383D43'
  static readonly gray500 = '#3F454D'
  static readonly gray400 = '#454D56'
  static readonly gray300 = '#4C5560'
  static readonly gray200 = '#525D6A'
  static readonly gray100 = '#596574'
  static readonly gray50 = '#5A6472'
  static readonly gray25 = '#979AA0'

  static readonly primary950 = '#1B4397'
  static readonly primary900 = '#1750B1'
  static readonly primary800 = '#125DCB'
  static readonly primary700 = '#0E69E2'
  static readonly primary600 = '#0A77FF'
  static readonly primary500 = '#2C8AFF'
  static readonly primary400 = '#4D9CFF'
  static readonly primary300 = '#6FAFFF'
  static readonly primary200 = '#90C2FF'
  static readonly primary100 = '#B2D5FF'
  static readonly primary25 = '#ECF5FF'
  static readonly primary50 = '#D3E7FF'

  static readonly neutral950 = '#A5ACBA'
  static readonly neutral900 = '#6B7B8F'
  static readonly neutral800 = '#ABAFB7'
  static readonly neutral700 = '#DDDFE4'
  static readonly neutral600 = '#EAEBF0'
  static readonly neutral500 = '#EFEFF1'
  static readonly neutral400 = '#F5F5F5'
  static readonly neutral300 = '#F7F7F8'
  static readonly neutral200 = '#f9f9f9'
  static readonly neutral100 = '#F8F9FB'
  static readonly neutral50 = '#FAFBFC'
  static readonly neutral25 = '#FCFCFD'

  static readonly success900 = '#22762C'
  static readonly success800 = '#268E34'
  static readonly success700 = '#2AA63C'
  static readonly success600 = '#2EBF43'
  static readonly success500 = '#32D74B'
  static readonly success400 = '#52DD67'
  static readonly success300 = '#71E382'
  static readonly success200 = '#91E99E'
  static readonly success100 = '#B1EEB9'
  static readonly success50 = '#E9FCED'
  static readonly success25 = '#F8FBF8'

  static readonly purpleSecondary600 = '#574EFA'



  static readonly warning600 = '#EEA23E'
  static readonly warning500 = '#FFAE43'
  static readonly warning400 = '#FFC772'
  static readonly warning300 = '#FFD08A'
  static readonly warning200 = '#FFDDA1'
  static readonly warning100 = '#FFE4C0'
  static readonly warning50 = '#FFF8EB'
  static readonly warning25 = '#FBFAF8'

  static readonly danger950 = '#590C0C'
  static readonly danger900 = '#751614'
  static readonly danger800 = '#901F1B'
  static readonly danger700 = '#C8322B'
  static readonly danger600 = '#F04438'
  static readonly danger500 = '#E33B32'
  static readonly danger400 = '#FF645A'
  static readonly danger300 = '#FE827B'
  static readonly danger200 = '#FE827B'
  static readonly danger100 = '#FDDDDC'
  static readonly danger50 = '#FFEFEE'
  static readonly danger25 = '#FDFCFC'

  static readonly blue25 = '#E5EEFA'

  static readonly mercuryBorder = '#DEE2E6'

  static readonly baseWhite = '#FFFFFF'
  static readonly baseBlue = 'linear-gradient(90deg, #009FFC 0%, #56BFFF 100%)'
  static readonly badgeBG = 'var(--danger-600, #E33B32)'
}

//------------------------------------------------------------------------------
export class AppBorders {
  static readonly primary = {
    borderRadius: '5px',
    border: `1px solid ${AppColors.neutral600}`
  }
  //TODO: add more borders
  static readonly secondary = {
    borderRadius: '5px',
    border: `1px solid ${AppColors.neutral600}`
  }
  static readonly dashed = {
    borderRadius: '5px',
    border: `1px dashed ${AppColors.neutral600}`
  }
}

//------------------------------------------------------------------------------
export class AppHovers {
  static readonly dark = {
    pointer: 'cursor',
    background: AppColors.gray400
  }

  static readonly primary = {
    pointer: 'cursor',
    background: AppColors.neutral400
  }
  static readonly primaryButtonOutlined = {
    pointer: 'cursor',
    border: `1px solid ${AppColors.primary500}`,
    background: 'white',
  }

  static readonly primaryButton = {
    pointer: 'cursor',
    background: AppColors.primary500
  }

  static readonly primaryButtonText = {
    pointer: 'cursor',
    color: AppColors.primary500
  }
}

//------------------------------------------------------------------------------
export class AppFonts {
  static readonly montserratBase = {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    lineHeight: '120%',
    color: AppColors.g900,
  }

  static readonly latoBase = {
    fontFamily: 'Lato',
    fontWeight: 400,
    lineHeight: '120%',
    color: AppColors.g1000,
  }

  static readonly interBase = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    lineHeight: '22px',
  }

  static readonly textSMedium = {
    ...AppFonts.interBase,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  }
  static readonly textXSMedium = {
    ...AppFonts.interBase,
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '18px',
  }
  static readonly textSSemibold = {
    ...AppFonts.interBase,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  }
  static readonly textMSemibold = {
    ...AppFonts.interBase,
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '22px',
  }
  static readonly textMMedium = {
    ...AppFonts.interBase,
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '22px',
  }
  static readonly textMRegular = {
    ...AppFonts.interBase,
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px'
  }
  static readonly textXLRegular = {
    ...AppFonts.interBase,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '26px'
  }

  /* Text L/Semibold */
  static readonly textLSemibold = {
    ...AppFonts.interBase,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px'
  }


  static readonly textXLSemibold = {
    ...AppFonts.interBase,
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '28px',
  }

  static readonly textLMedium = {
    ...AppFonts.interBase,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  }

  static readonly textLRegular = {
    ...AppFonts.interBase,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  }
  //styleName: Text S/Regular;
  static readonly textSRegular = {
    ...AppFonts.interBase,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    textAlign: 'left'
  }

  static readonly textButtonSemiboldSmall = {
    ...AppFonts.interBase,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  }


  static readonly textButtonSemiboldRegular = {
    ...AppFonts.interBase,
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '22px',
  }

  static readonly headlineSDesktopSemibold = {
    ...AppFonts.interBase,
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '38px',
    letterSpacing: '-0.42px'
  }

  static readonly headlineXSDesktopSemibold = {
    ...AppFonts.interBase,
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '30px',
  }
  /* Headline XXS/Desktop/Medium */

  static readonly headlineXXSDesktopSemibold = {
    ...AppFonts.interBase,
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '28px', /* 140% */
    letterSpacing: '-0.2px'
  }



  static readonly montserratMassive = {
    ...AppFonts.montserratBase,
    fontSize: '39.82px',
  }

  static readonly montserratMega = {
    ...AppFonts.montserratBase,
    fontSize: '33.18px',
  }

  static readonly montserratHuge = {
    ...AppFonts.montserratBase,
    fontSize: '27.65px',
  }

  static readonly montserratBig = {
    ...AppFonts.montserratBase,
    fontSize: '23.04px',
  }

  static readonly montserratLarge = {
    ...AppFonts.montserratBase,
    fontSize: '19.2px',
  }

  static readonly montserratMedium = {
    ...AppFonts.montserratBase,
    fontSize: '16px',
  }

  static readonly montserratSmall = {
    ...AppFonts.montserratBase,
    fontSize: '13.33px',
  }

  static readonly montserratTiny = {
    ...AppFonts.montserratBase,
    fontSize: '11.11px',
  }

  static readonly montserratMicro = {
    ...AppFonts.montserratBase,
    fontSize: '9.26px',
  }

  static readonly montserratNano = {
    ...AppFonts.montserratBase,
    fontSize: '7.72px',
  }

  static readonly latoMassive = {
    ...AppFonts.latoBase,
    fontSize: '39.82px',
  }

  static readonly latoMega = {
    ...AppFonts.latoBase,
    fontSize: '33.18px',
  }

  static readonly latoHuge = {
    ...AppFonts.latoBase,
    fontSize: '27.65px',
  }

  static readonly latoBig = {
    ...AppFonts.latoBase,
    fontSize: '23.04px',
  }

  static readonly latoLarge = {
    ...AppFonts.latoBase,
    fontSize: '19.2px',
  }

  static readonly latoMedium = {
    ...AppFonts.latoBase,
    fontSize: '16px',
  }

  static readonly latoSmall = {
    ...AppFonts.latoBase,
    fontSize: '13.33px',
  }

  static readonly latoTiny = {
    ...AppFonts.latoBase,
    fontSize: '11.11px',
  }

  static readonly latoMicro = {
    ...AppFonts.latoBase,
    fontSize: '9.26px',
  }

  static readonly latoNano = {
    ...AppFonts.latoBase,
    fontSize: '7.72px',
  }

  static readonly interMSemibold = {
    ...AppFonts.interBase,
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '22px',
  }

  static readonly interHeadlineMSemibold = {
    ...AppFonts.interBase,
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
  }

  /* Headline XS/Desktop/Medium */
  static readonly XSHeadlineMedium = {
    ...AppFonts.interBase,
    fontSize: '22px',
    fontWeight: '500',
    lineHeight: '30px', /* 136.364% */
    letterSpacing: '-0.22px'
  }




  static readonly interTextSMedium = {
    ...AppFonts.interBase,
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  }
  static readonly interTextSSemibold = {
    ...AppFonts.interBase,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px', /* 142.857% */
    letterSpacing: '-0.1px'
  }



  static readonly interTextButtonSemiBoldSmall = {
    ...AppFonts.interBase,
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  }

  /* UPPERCASE/Semibold Uppercase */
  static readonly upperCaseSemibold = {
    ...AppFonts.interBase,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',/* 125% */
    textTransform: 'uppercase'
  }


}

//------------------------------------------------------------------------------
export const theme: Theme = createTheme({
  spacing: 1,
  typography: {
    fontFamily: 'Inter, sans-serif',
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 1,
  },
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: `1px solid ${AppColors.neutral700}`, borderRadius: '5px'
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {

            // root: {
            '& fieldset': {
              // border: `1px solid ${AppColors.neutral700}`,
              // border: '1px solid #2D3545',
              borderRadius: 5,
              ...AppFonts.textLMedium,
              // },
              // input: {
              '&:-webkit-autofill': {
                '-webkit-border-radius': '5px',
                padding: '16px 12px',
              },
              '&::placeholder': {
                ...AppFonts.textLMedium,  // Style the placeholder color
                color: AppColors.neutral950,
              },

            },
          },
        }]
    },
    // TODO make standard
    MuiOutlinedInput: {

      styleOverrides: {
        root: {
          '& fieldset': {
            // border: `1px solid ${AppColors.neutral700}`,
            borderRadius: 5
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: AppColors.neutral950 // Change border color on hover
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: AppColors.neutral700, // Change border color on focus
          },
          '&::placeholder': {  // Style the placeholder color
            color: AppColors.neutral950,
            fontFamily: 'Inter, sans-serif',
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            borderRadius: 8,
            backgroundColor: AppColors.red,
            color: AppColors.g000,
            fontWeight: 600,
            fontSize: 18,
            '&:hover': {
              ...AppHovers.primary,
              boxShadow: 'none',
            },
            '&:disabled': {
              backgroundColor: AppColors.red,
              color: AppColors.g000,
            },
            boxShadow: 'none',
          },
        },
        // DO we use this anywhere?
        // {
        //   props: { variant: 'outlined' },
        //   style: {
        //     borderRadius: 8,
        //     p: '8px 16px',
        //     backgroundColor: AppColors.purple,
        //     color: AppColors.g000,
        //     fontWeight: 600,
        //     fontSize: 18,
        //     borderColor: AppColors.g000,
        //     '&:hover': {
        //       backgroundColor: AppColors.d700,
        //       borderColor: AppColors.d700,
        //     },
        //     '&:disabled': {
        //       color: AppColors.g000,
        //     },
        //   },
        // },
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: 5,
            p: '10px 12px',
            backgroundColor: AppColors.baseWhite,
            color: AppColors.gray700,
            fontWeight: 600,
            fontSize: 18,
            borderColor: AppColors.neutral600,
            '&:hover': {
              ...AppHovers.primary,
              borderColor: AppColors.neutral700,
            },
            '&:disabled': {
              color: AppColors.g000,
            },

          },
        },
        {
          props: { variant: 'text' },
          style: {
            justifyContent: 'flex-start',
            fontSize: 12,
            fontWeight: 700,
            color: '#4735B2',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      ],
      // TODO: remove if not needed
      // styleOverrides: {
      //   root: {
      //     borderRadius: 8,
      //     backgroundColor: AppColors.red,
      //     color: 'white',
      //     fontWeight: 600,
      //     fontSize: 18,
      //     '&:hover': {
      //       backgroundColor: AppColors.red,
      //     },
      //     '&:disabled': {
      //       color: 'white',
      //     },
      //   },
      // },
    },
  },
})

export class AppButtons {
  /** Used in Select button for Client Tile on Send a Form Dialog */
  static readonly absoluteButton = {
    position: 'absolute',
    right: '16px',
    top: '16px',
    'z-index': 2,
  }

  static readonly buttonBorder = {
    color: AppColors.p500,
    background: AppColors.g000,
    border: `2px solid ${AppColors.p500}`,
    borderRadius: 24,
  }

  static readonly hoverButtonBorder = {
    color: AppColors.g000,
    background: AppColors.p500,
    border: `2px solid ${AppColors.p500}`,
    borderRadius: 24,
  }
}


// BELOW IS A DEMO FOR CREATING A FONT COMPONENT
export type FontNames = 'LM'
  | 'SR'
  | 'SS'
  | 'SM'
  | 'XSM'
  | 'MS'
  | 'MR'
  | 'XLR'
  | 'XLS'
  | 'LM'
  | 'LR'
  | 'BTSS'
  | 'BTSR'
  | 'HXXSDS'
  | 'HMS'
  | 'HSDS'
  | 'HXSS'
  | 'US'
  | 'MM'
  | 'XSHM'
  | 'LSB'

type TextMap = {
  [K in FontNames]: any;
}



export const TextMappings: TextMap = {
  SR: AppFonts.textSRegular,
  SS: AppFonts.textSSemibold,
  SM: AppFonts.textSMedium,
  LM: AppFonts.textLMedium,
  LR: AppFonts.textLRegular,
  XSM: AppFonts.textXSMedium,
  MS: AppFonts.textMSemibold,
  MR: AppFonts.textMRegular,
  XLR: AppFonts.textXLRegular,
  XLS: AppFonts.textXLSemibold,
  BTSS: AppFonts.textButtonSemiboldSmall,
  BTSR: AppFonts.textButtonSemiboldRegular,
  HXXSDS: AppFonts.headlineXXSDesktopSemibold,
  HMS: AppFonts.interHeadlineMSemibold,
  HSDS: AppFonts.headlineSDesktopSemibold,
  HXSS: AppFonts.headlineXSDesktopSemibold,
  US: AppFonts.upperCaseSemibold,
  MM: AppFonts.textMMedium,
  XSHM: AppFonts.XSHeadlineMedium,
  LSB: AppFonts.textLSemibold

}

export type ColorNames = 'gray950'
  | 'gray900'
  | 'gray800'
  | 'gray700'
  | 'gray600'
  | 'gray300'
  | 'gray400'
  | 'gray50'
  | 'gray25'
  | 'primary25'
  | 'primary200'
  | 'primary500'
  | 'primary600'
  | 'neutral950'
  | 'neutral800'
  | 'neutral600'
  | 'neutral500'
  | 'neutral300'
  | 'neutral200'
  | 'neutral25'
  | 'neutral700'
  | 'neutral100'
  | 'neutral50'
  | 'warning50'
  | 'danger600'
  | 'danger500'
  | 'danger50'
  | 'white'


type ColorMap = {
  [K in ColorNames]: string;
};

export const ColorMappings: ColorMap = {
  gray950: AppColors.gray950,
  gray900: AppColors.gray900,
  gray800: AppColors.gray800,
  gray700: AppColors.gray700,
  gray600: AppColors.gray600,
  gray300: AppColors.gray300,
  gray400: AppColors.gray400,
  gray50: AppColors.gray50,
  gray25: AppColors.gray25,
  primary25: AppColors.primary25,
  primary200: AppColors.primary200,
  primary500: AppColors.primary500,
  primary600: AppColors.primary600,
  neutral950: AppColors.neutral950,
  neutral800: AppColors.neutral800,
  neutral600: AppColors.neutral600,
  neutral500: AppColors.neutral500,
  neutral300: AppColors.neutral300,
  neutral200: AppColors.neutral200,
  neutral25: AppColors.neutral25,
  neutral700: AppColors.neutral700,
  neutral100: AppColors.neutral100,
  neutral50: AppColors.neutral50,
  warning50: AppColors.warning50,
  danger600: AppColors.danger600,
  danger500: AppColors.danger500,
  danger50: AppColors.danger50,
  white: AppColors.baseWhite,
}


export type spacingNames = '0'
  | '4'
  | '6'
  | '8'
  | '12'
  | '16'
  | '24'
  | '32'

type SpaceMap = {
  [K in spacingNames]: string;
};

export const SpaceMappings: SpaceMap = {
  '0': '0px',
  '4': '4px',
  '6': '6px',
  '8': '8px',
  '12': '12px',
  '16': '16px',
  '24': '24px',
  '32': '32px',
}

// TODO ADD MORE BORDER OPTIONS
export type borderNames = 'none'
  | 'primary'
  | 'secondary'
  | 'dashed'

type BorderMap = {
  [K in borderNames]: any;
};

export const BorderMappings: BorderMap = {
  'none': 'none',
  'primary': AppBorders.primary,
  'secondary': AppBorders.secondary,
  'dashed': AppBorders.dashed,

}
