import React, { useContext, useEffect, useState } from 'react'
import { phoneInputFormatter } from '../../../core/utils/input-formatters/InputFormatters'
import {
  isValidEmail,
  isValidPhoneNumber,
  validateUSPhoneNumber,
} from '../../../core/utils/validators/Validators'
import {
  clearCurrentOrg,
  requestEmailOtpCode,
  requestPhoneOtpCode,
  setCurrentOrg,
  verifyEmailOtpCode,
  verifyPhoneOtpCode,
} from '../../../domain/domain'
import { setCurrentOrgId } from '../../../data/repositories/orgs/OrgRepository'
import { saveToLocalStorage } from '../../../data/data'
import { IVerifyOTPPayload } from '../../../domain/interfaces/IUserDetails'

export interface LoginPageViewModelProps {
  onInputChange: (value: string) => void
  continueOrSignin: () => Promise<boolean>
  handleCancel: () => void
  state: TLoginValue
  formData: ILoginFormData
  errorMessage?: string | null
  currentPage: TPageType
  mobileLink: string | undefined
}

const LoginPageContext = React.createContext<LoginPageViewModelProps | null>(
  null,
)

export function useLoginPageViewModel(): LoginPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(LoginPageContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

type TLoginValue = 'loading' | 'ready' | 'submitting' | 'error' | 'done'
type TInputType = 'email' | 'phone' | 'code' | undefined
type TPageType = 'signin' | 'codeVerification' | undefined

interface ILoginFormData {
  inputValue: string
  inputType: TInputType
}

interface ILoginState {
  value: TLoginValue
  page: TPageType
  formData: ILoginFormData
  readyData: ILoginFormData | null
  errorMessage?: string | null
  statusCode?: number
}

const initialState: ILoginState = {
  value: 'loading',
  page: 'signin',
  formData: {
    inputType: undefined,
    inputValue: '',
  },
  readyData: null,
  errorMessage: '',
}

export const LoginPageViewModel: React.FC<Props> = ({ children }) => {
  const [loginState, updateLoginState] = useState<ILoginState>(initialState)
  const [mobileLink, setMobileLink] = useState<string | undefined>(undefined)

  const onInputChange = (input: string) => {
    let inputType: TInputType = undefined

    if (loginState.page === 'signin') {
      /** check if input is email */
      if (isValidEmail(input)) {
        inputType = 'email'
        /** check if input is phone number */
      }
      // Uncomment this code to support phone in login
      else if (validateUSPhoneNumber(input)) {
        inputType = 'phone'
      }
    }
    if (loginState.page === 'codeVerification') {
      inputType = 'code'
    }

    updateLoginState({
      ...loginState,
      formData: {
        ...loginState.formData,
        inputType,
        /**
        * Uncomment this code to support phone in login
        inputValue: inputType == 'phone' ? phoneInputFormatter(input) : input,
        */
        inputValue: input
        // inputValue: input,
      },
      value: 'ready',
      errorMessage: '',
    })
  }

  /** handle when clicking either continue or signin */
  const continueOrSignin = async (): Promise<boolean> => {
    if (loginState.page === 'signin') {
      return await handleRequestOTP()
    }
    if (loginState.page === 'codeVerification') {
      return await handleSignIn()
    }
    return false
  }

  /** handle request otp */
  const handleRequestOTP = async (): Promise<boolean> => {
    if (!loginState.formData.inputValue) return false
    const { inputType, inputValue } = loginState.formData

    if (
      inputType &&
      (loginState.value !== 'error' || loginState.statusCode == 429)
    ) {
      /** set state to submitting */
      updateLoginState({
        ...loginState,
        value: 'submitting',
      })
      /** request OTP */
      const result =
        inputType == 'email'
          ? await requestEmailOtpCode(inputValue.toLowerCase())
          : await requestPhoneOtpCode(inputValue.replaceAll('-', ''))
      if (result.right && result.right.isSSORedirect) {
        // redirect to SSOCheckPage Component
        window.location.href = result.right.redirectUrl
        return true
      }
      /** [do]
       *  - change state to back 'ready'
       *  - put current input to 'readyData' field
       *  - change page to 'codeVerification'
       *  - clear 'formdata' & 'errorMessage'
       */
      if (result.left && result.left.statusCode === 429) {
        updateLoginState({
          ...loginState,
          value: 'error',
          errorMessage: result.left.message,
          statusCode: 429,
        })
        return false
      }
      updateLoginState({
        ...loginState,
        value: 'ready',
        page: 'codeVerification',
        errorMessage: '',
        readyData: {
          ...loginState.formData,
        },
        formData: {
          inputType: undefined,
          inputValue: '',
        },
      })
      return true
    } else {
      /** - change state to 'error'
       *  - put errorMessage
       */
      updateLoginState({
        ...loginState,
        value: 'error',
        /** Note: this could be dynamic if phone login will be supported */
        errorMessage: 'The email / phone number entered is not in the correct format',
      })
      return false
    }
  }

  /** handle signin */
  const handleSignIn = async (): Promise<boolean> => {
    if (!loginState.formData.inputValue) return false
    if (!loginState.readyData) return false

    const { inputType: currentInputType, inputValue: inputtedPin } =
      loginState.formData
    const { inputType, inputValue } = loginState.readyData

    if (currentInputType && loginState.value !== 'error') {
      updateLoginState({
        ...loginState,
        value: 'submitting',
      })

      /** submit to signin */
      const result =
        inputType == 'email'
          ? await verifyEmailOtpCode(inputtedPin, inputValue.toLowerCase())
          : await verifyPhoneOtpCode(
            inputtedPin,
            inputValue.replaceAll('-', ''),
          )

      if (!result || result.isLeft()) {
        /** once failed [do]
         *  - change state to 'error'
         *  - put errorMessage
         */
        const errorMessage = result.left?.message ??
         'Verification failed. Ensure your email and OTP are correct and try again.'
        updateLoginState({
          ...loginState,
          value: 'error',
          errorMessage: errorMessage,
        })
        return false
      }
      /** once success [do]
       *  - change state to 'done'
       *  - clear 'errorMessage' & navigate to org selection page
       */
      // check id person is a member
      saveToLocalStorage('person', JSON.stringify(result.right))
      const orgs = result.right?.orgs ?? []
      const contractors = result.right?.contractors ?? []

      if (orgs.length == 1 && contractors.length == 0) {
        await setCurrentOrg(orgs[0].id)
      } else {
        await clearCurrentOrg()
        if (contractors.length == 1 && orgs.length == 0) {
          setCurrentOrgId(contractors[0].orgId)
        }
      }
      updateLoginState({
        ...loginState,
        value: 'done',
        page: 'codeVerification',
        errorMessage: '',
      })
      return true
    } else {
      /** - change state to 'error'
       *  - put errorMessage
       */
      updateLoginState({
        ...loginState,
        value: 'error',
        errorMessage: 'Verification failed. Ensure your email and OTP are correct and try again.',
      })
      return false
    }
  }

  const handleCancel = () => {
    if (loginState.page === 'signin') {
      updateLoginState({
        ...initialState,
        value: 'ready',
      })
    }

    if (loginState.page === 'codeVerification') {
      updateLoginState({
        ...initialState,
        value: 'ready',
      })
    }
  }

  function checkMobile() {
    if (/Mobi/.test(navigator.userAgent)) {
      const userAgent = navigator.userAgent.toLowerCase()
      const isiOS = /iphone|ipad|ipod/.test(userAgent)
      const isAndroid = /android/.test(userAgent)

      if (isiOS) {
        setMobileLink(
          'https://apps.apple.com/us/app/safetymojo-ai/id6446137466',
        )
      } else if (isAndroid) {
        setMobileLink(
          'https://play.google.com/store/apps/details?id=ai.edify.rome',
        )
      }
    }
  }

  /**component mount */
  useEffect(() => {
    checkMobile()
    updateLoginState({ ...loginState, value: 'ready' })
  }, [])

  return (
    <LoginPageContext.Provider
      value={{
        formData: loginState.formData,
        state: loginState.value,
        currentPage: loginState.page,
        continueOrSignin,
        handleCancel,
        onInputChange,
        errorMessage: loginState.errorMessage,
        mobileLink,
      }}
    >
      {children}
    </LoginPageContext.Provider>
  )
}
