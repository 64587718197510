import { SxProps, Typography } from '@mui/material'
import React from 'react'
import { AppColors, AppFonts } from '../../Theme'

interface PageHeaderProps {
  title: string
  sx?: SxProps
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, sx }) => {
  return (
    <Typography
      className='ellipsis-text'
      sx={{
        ...AppFonts.headlineSDesktopSemibold,
        color: AppColors.gray700,
        ml: {
          xs: '24px',  // Mobile
          sm: 0        // Tablet and up
        },
      
        ...sx

      }}
    >
      {title}
    </Typography>
  )
}

export default PageHeader
