import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box, Stack, Switch, Typography } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import { httpPost, saveToLocalStorage } from '../../../data/data'
import { ETypography } from '../../components/fonts/ETypography'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import {
  EmailOutlined,
  PhoneOutlined,
  PersonOutline,
} from '@mui/icons-material'
import SingupSuccessModal from './SignupSuccessModal'
import EdifyFormFieldWithIcon from '../../components/form/shared/EdifyFormFieldWithIcon'
import PageContainer, {
  MAX_BODY_CONTENT_WIDTH,
} from '../../components/page/PageContainer'
import { IContractor } from '../../../domain/interfaces/IContractor'
import GlobalSearch from '../../components/form/EdifySearch/GlobalSearch'
import useQuery from '../../../core/hooks/useQuery'
import { AppColors } from '../../Theme'
import ErrorContainer from '../../components/error/ErrorContainer'
import { RomeLogo } from '../../../core/core'

type ProfileInputs = {
  fullNoAutoName: string
  workEmail: string
  confirmWorkEmail: string
  companyName: string
  phone: string
  confirmPhone: string
  smsNotifications: boolean
}

interface MetaSignUpFormProps {
  id?: string | undefined
  onCancel?: () => void
}

export const MetaSignUpForm: React.FC<MetaSignUpFormProps> = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedCompany, setSelectedCompany] = useState<any>()
  const [selectedCompanyError, setSelectedCompanyError] = useState<
    string | null
  >(null)
  const [submissionError, setSubmissionError] = useState<string | null>(null)
  const [contractorsLoading, setContractorsLoading] = useState<boolean>(true)
  const [searchParams] = useSearchParams()
  const orgId = searchParams.get('orgId')
  const projectId = searchParams.get('projectId')
  const [count, setCount] = useState(0)

  const queryHelper = useQuery({
    skip: 0,
    limit: 20,
  })
  const { query } = queryHelper

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 556)

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 900)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Function to clean phone numbers - removes all non-numeric characters
  const cleanPhoneNumber = (phone: string): string => {
    return phone.replace(/[^\d]/g, '')
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const resolver: Resolver<ProfileInputs> = async (values) => {
    const errors: FieldErrors<ProfileInputs> = {}
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    const phoneRegex = /^[0-9]{10}$/

    // Full Name validation
    if (!values.fullNoAutoName) {
      errors.fullNoAutoName = { type: 'required', message: 'Name is required' }
    }

    // Check contact method
    const hasEmail = values.workEmail && values.workEmail.trim().length > 0
    const hasPhone = values.phone && values.phone.trim().length > 0

    // Validate that only one contact method is provided
    if (!hasEmail && !hasPhone) {
      errors.workEmail = {
        type: 'required',
        message: 'Phone is required',
      }
      errors.phone = {
        type: 'required',
        message: 'Phone is required',
      }
    } else if (hasEmail && hasPhone) {
      errors.workEmail = {
        type: 'invalid',
        // message: 'Please provide either email or phone, not both',
        message: 'Please provide phone number',
      }
      errors.phone = {
        type: 'invalid',
        // message: 'Please provide either email or phone, not both',
        message: 'Please provide phone number',
      }
    } else {
      // Validate the provided contact method
      if (hasEmail) {
        if (!emailRegex.test(values.workEmail)) {
          errors.workEmail = {
            type: 'invalid',
            message: 'The email address you entered is not formatted correctly',
          }
        }
        if (!values.confirmWorkEmail) {
          errors.confirmWorkEmail = {
            type: 'required',
            message: 'Please confirm your email',
          }
        } else if (values.workEmail !== values.confirmWorkEmail) {
          errors.confirmWorkEmail = {
            type: 'mismatch',
            message: 'The email addresses do not match',
          }
        }
      }

      if (hasPhone) {
        const cleanedPhone = cleanPhoneNumber(values.phone)
        if (cleanedPhone.length !== 10) {
          errors.phone = {
            type: 'invalid',
            message: 'The phone you entered is not valid',
          }
        }
        if (!values.confirmPhone) {
          errors.confirmPhone = {
            type: 'required',
            message: 'Please confirm your phone number',
          }
        } else if (
          cleanPhoneNumber(values.phone) !==
          cleanPhoneNumber(values.confirmPhone)
        ) {
          errors.confirmPhone = {
            type: 'mismatch',
            message: 'The phone numbers do not match',
          }
        }
        if (!values.smsNotifications) {
          errors.smsNotifications = {
            type: 'required',
            message: 'SMS notifications must be enabled when phone is provided',
          }
        }
      }
    }

    return { values, errors }
  }

  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm<ProfileInputs>({
    resolver,
    mode: 'onSubmit',
    defaultValues: { smsNotifications: false },
  })

  const phoneValue = watch('phone')
  const emailValue = watch('workEmail')
  const isSmsEnabled = watch('smsNotifications')

  const resetForm = () => {
    setCount(count + 1)
    onGlobalSearchClear()
    setSubmissionError(null)
    setSelectedCompany('')
    setSelectedCompanyError(null)
    reset() // Reset values to defaultValues
    clearErrors() // Clear all errors
  }

  // Clear relevant errors when input changes
  React.useEffect(() => {
    if (phoneValue || emailValue) {
      clearErrors(['workEmail', 'phone'])
    }
  }, [phoneValue, emailValue, clearErrors])

  React.useEffect(() => {
    // loadContractors()
    orgId && saveToLocalStorage('currentOrgId', orgId)
  }, [])

  const onSubmit = handleSubmit(async (data) => {
    if (!selectedCompany) {
      setSelectedCompanyError('Please select a contractor')
      return
    }
    setLoading(true)
    const res = await httpPost('/selfSignUp', {
      email: data.workEmail,
      phone: data.phone,
      fullName: data.fullNoAutoName,
      confirmEmail: data.confirmWorkEmail,
      confirmPhone: data.confirmPhone,
      orgId,
      projectId,
      contractorId: selectedCompany.selectedId,
    })
    setLoading(false)

    if (res.isRight()) {
      setModalOpen(true)
      resetForm()
    } else {
      setSubmissionError(
        res.left?.message ?? 'An error occurred during self sign up.',
      )
    }
  })

  const onGlobalSearchSelect = (selectedId: string, selectedValue: string) => {
    const newQuery = { ...query }
    delete newQuery.searchKey
    setSelectedCompany({ selectedId, selectedValue })
    setSelectedCompanyError(null)
  }
  const onGlobalSearchKey = (key: string) => {
    // Don't need
  }

  const onGlobalSearchClear = async () => {
    setSelectedCompany(null)
    setSelectedCompanyError('Please select a contractor')
    const newQuery = { ...query }
    delete newQuery.searchKey
    delete newQuery.filterId
    delete newQuery.filterValue
  }

  return (
    <Stack
      sx={{
        position: 'relative',
        p: {
          xs: 0, // Zero padding on the smallest breakpoint (mobile)
          sm: 32, // Padding 32 starting from small devices and up
        },
        mt: {
          xs: 32,
          sm: 0,
        },
        maxWidth: MAX_BODY_CONTENT_WIDTH,
        margin: 'auto',
        height: '100%',
        background: AppColors.neutral25,
      }}
    >
      <Box sx={{ padding: 0 }}>
        <SingupSuccessModal open={modalOpen} onClose={handleCloseModal} />

        <Box sx={{ paddingX: { xs: 24, s: 0, md: 0 } }}>
          <ETypography font='HSDS' color='gray700'>
            Create Your Account
          </ETypography>
          <ETypography font='LR' color='gray50' sx={{ marginBottom: '24px' }}>
            Welcome! Please make sure this information is accurate and complete
            before submitting.
          </ETypography>
        </Box>
        <form onSubmit={onSubmit} key={`form-${count}`}>
          <Box>
            <InnerPageContainer sx={{ p: '24px' }}>
              {submissionError && (
                <ErrorContainer sx={{ mb: '12px' }}>
                  <Typography
                    sx={{
                      color: AppColors.d600,
                      fontSize: '12px',
                      marginTop: '4px',
                    }}
                  >
                    {submissionError}
                  </Typography>
                </ErrorContainer>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  // mt: '24px',
                }}
              >
                <EdifyFormFieldWithIcon
                  inputProps={{
                    autoComplete: 'new-password',
                    autoCorrect: 'off',
                    autoCapitalize: 'off',
                    spellCheck: 'false',
                  }}
                  label='Full Name (Required)'
                  placeholder='John Doe'
                  icon={PersonOutline}
                  register={register}
                  name='fullNoAutoName'
                  errors={errors}
                  sx={{ mr: { md: '24px' } }}
                />
                <Box sx={{ flex: 1, mt: 0 }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: 'text.primary',
                      marginBottom: '8px',
                      mt: { xs: 8, s: 8, md: 0 },
                    }}
                  >
                    Contractor (Required)
                  </Typography>

                  <GlobalSearch
                    key={count}
                    errors={selectedCompanyError}
                    placeholder='Search'
                    hideTextSearch
                    isContractorSelfSignup
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        flex: 1,
                        padding: '6px 0px 6px 9px !important',
                        paddingRight: '9px !important',
                      },
                    }}
                    entities='contractors'
                    {...(query.searchKey
                      ? { defaultValue: query.searchKey }
                      : {})}
                    {...(query.filterValue
                      ? { defaultValue: query.filterValue }
                      : {})}
                    onGlobalSearchSelect={onGlobalSearchSelect}
                    onGlobalSearchClear={onGlobalSearchClear}
                    onGlobalSearchWithText={onGlobalSearchKey}
                  />
                  {selectedCompanyError && (
                    <Typography
                      sx={{
                        color: AppColors.d600,
                        fontSize: '12px',
                        marginTop: '4px',
                      }}
                    >
                      {selectedCompanyError}
                    </Typography>
                  )}
                </Box>
              </Box>
              <ETypography
                font='HXSS'
                color='gray700'
                sx={{ marginTop: '24px' }}
              >
                Login Information
              </ETypography>
              <ETypography font='LR' color='gray50' sx={{ mb: '8px' }}>
              To create an account, you must enter your phone number. This will be how you login.
              </ETypography>

              {/* Email and Phone Fields */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* Main Email and Phone Row */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                  }}
                >
                  {/* <EdifyFormFieldWithIcon
                    inputProps={{
                      autoComplete: 'new-password',
                      autoCorrect: 'off',
                      autoCapitalize: 'off',
                      spellCheck: 'false',
                    }}
                    label='Work Email'
                    placeholder='name@workemail.com'
                    icon={EmailOutlined}
                    register={register}
                    name='workEmail'
                    errors={errors}
                    disabled={!!phoneValue}
                    sx={{ mr: { md: '24px' } }}
                  /> */}
                  {/* {!isDesktop && emailValue && (
                    <EdifyFormFieldWithIcon
                      inputProps={{
                        autoComplete: 'new-password',
                        autoCorrect: 'off',
                        autoCapitalize: 'off',
                        spellCheck: 'false',
                      }}
                      label='Confirm Work Email (Required)'
                      placeholder='name@workemail.com'
                      icon={EmailOutlined}
                      register={register}
                      name='confirmWorkEmail'
                      errors={errors}
                      disabled={!!phoneValue}
                      sx={{ mr: { md: '24px' }, mt: 8 }}
                    />
                  )} */}

                  <EdifyFormFieldWithIcon
                    label='Phone (Required)'
                    placeholder='(555) 123-4567'
                    icon={PhoneOutlined}
                    register={register}
                    disabled={!!emailValue}
                    name='phone'
                    errors={errors}
                    sx={{ mt: { xs: 8, s: 8, md: 0 } }}
                  />
                  <Box sx={{ flex: 1 }} />
                </Box>

                {/* Confirmation Fields Row */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    mb: 0,
                  }}
                >
                  {/* {emailValue && isDesktop ? (
                    <EdifyFormFieldWithIcon
                      inputProps={{
                        autoComplete: 'new-password',
                        autoCorrect: 'off',
                        autoCapitalize: 'off',
                        spellCheck: 'false',
                      }}
                      label='Confirm Work Email (Required)'
                      placeholder='name@workemail.com'
                      icon={EmailOutlined}
                      register={register}
                      name='confirmWorkEmail'
                      errors={errors}
                      disabled={!!phoneValue}
                      sx={{ mr: { md: '24px' }, mt: 8 }}
                    />
                  ) : (
                    <Box sx={{ flex: 1, mr: '24px' }} />
                  )} */}
                  <>
                    {/* {phoneValue ? (    */}
                    <EdifyFormFieldWithIcon
                      sx={{ mt: 8 }}
                      label='Confirm Phone (Required)'
                      placeholder='(555) 123-4567'
                      icon={PhoneOutlined}
                      register={register}
                      name='confirmPhone'
                      errors={errors}
                      disabled={!!emailValue}
                    />
                    <Box sx={{ flex: 1 }} />
                  </>
                  {/* )  */}
                  {/* : (
                    <Box sx={{ flex: 1 }} />
                  )} */}
                </Box>
              </Box>
            </InnerPageContainer>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              paddingX: { xs: 24, s: 0, md: 0 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                // marginRight: '24px',
              }}
            >
              <ETypography sx={{ marginTop: '12px' }} font='SM' color='gray700'>
                SMS Notifications
              </ETypography>
              <ETypography font='SR' color='gray50'>
                I agree to the{' '}
                <Link to='https://getmojo.ai/sms-policy/' target='_blank'>
                  SMS Terms of Use
                </Link>
              </ETypography>
            </Box>
            <Switch {...register('smsNotifications')} color='primary' />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              marginTop: '24px',
              paddingX: { xs: 24, md: 0 },
            }}
          >
            <EdifyButton noBackground onClick={resetForm} title='Clear' />
            <EdifyButton
              disabled={loading || !isSmsEnabled}
              loading={loading}
              type='submit'
              data-testid={'SubmissionButton'}
              primary
              title={loading ? 'Creating Account...' : 'Create My Account'}
            />
          </Box>
        </form>
      </Box>
    </Stack>
  )
}

export default MetaSignUpForm
