import { Failure } from '../../../core/failures/Failure'
import { Either } from '../../../core/utils/Either'
import { saveToLocalStorage } from '../../../data/data'
import {
  clearOrg,
  createNewOrg,
  currentOrg,
  currentOrgId,
  divisionStatsGet,
  getHierarchyNames,
  getOrgById,
  getProjects,
  getProjectsFromQuery,
  getRoles,
  levelCreate,
  levelDelete,
  levelFavorite,
  levelGetById,
  levelUpdate,
  levelUnfavorite,
  levelsDelete,
  levelsSearch,
  projectDelete,
  projectFavorite,
  projectGetById,
  projectUnfavorite,
  projectsDelete,
  projectsSearch,
  setCurrentOrgId,
  singleLevelsGet,
  projectCreate,
  projectUpdate,
  getOrgs,
  getMinimalHierarchyNames,
  getMinimalUsers,
  getMinimalContractor,
  getMinimalLevels,
  getMinimalProjects,
  getMinimalSubLocationHierarchyNames,
  getRole,
  postRole,
  updateRole,
  getRolesWithQuery,
  deleteRole,
  projectCustomizeFieldsGet,
  projectCustomizeFieldCreate,
  projectCustomizeFieldUpdate,
  getMinimalContacts,
  getMinimalLocations,
} from '../../../data/repositories/orgs/OrgRepository'
import { IHierarchyNameList } from '../../interfaces/IHierarchyName'
import { IMinimalSubLocationName } from '../../interfaces/ILocation'
import { IMinimalIdName, IOrg, IOrgList } from '../../interfaces/IOrg'
import { IDivisionStats, IDeletedObject, ILevel, ILevelList, IProject, IProjectList, LevelOptions, IProjectFormData, IProjectCustomField, IProjectCustomFieldFormData } from '../../interfaces/IProjects'
import { IRole, IRoleResponse, IRoles } from '../../interfaces/IRole'
import { IMinimalUser } from '../../interfaces/IUser'

//TODO CHECK WITH BE WHEN THEY ARE DONE
export interface ILevelsQueryField {
  limit?: number,
  skip?: number,
  tab?: string,

  // status?: string, active and inactive are the status
  project?: string //This might be its own call
}

export interface ICreateLevel {
  name?: string,
  parentId?: string | null,
  level?: number,
  pointOfContact?: string | null
  projectIds?: string[] | null
}

export async function getDivisionStats(): Promise<Either<Failure, IDivisionStats>> {
  return await divisionStatsGet()
}

export async function submitNewOrg(
  orgName: string,
  orgAddress: string,
  orgPolicyNumber: string,
): Promise<Either<Failure, IOrg>> {
  return await createNewOrg(orgName, orgAddress, orgPolicyNumber)
}

export async function getOrgDetailsById(
  orgId: string,
): Promise<Either<Failure, IOrg>> {
  return await getOrgById(orgId)
}

export async function getAllOrgs(): Promise<Either<Failure, IOrgList>> {
  return await getOrgs()
}

export function getCurrentOrg(): IOrg | undefined {
  return currentOrg()
}

export function getCurrentOrgId(): string | undefined {
  return currentOrgId()
}

export const setCurrentOrg = async (orgId: string) => {
  await setCurrentOrgId(orgId)
  //no need to wait for this. just update the current org in the background
  await updateCurrentOrg(orgId)
}

export const clearCurrentOrg = async () => {
  clearOrg()
}

const updateCurrentOrg = async (orgId: string) => {
  const orgRes: Either<Failure, IOrg> = await getOrgDetailsById(orgId)
  if (orgRes.isRight() && orgRes.right) {
    saveToLocalStorage('org', JSON.stringify(orgRes.right))
  }
}

export async function getOrgHierarchyNames(): Promise<
  Either<Failure, IHierarchyNameList>
  // eslint-disable-next-line indent
> {
  const result = await getHierarchyNames()

  return result
}

export async function adminGetRoles(query:string): Promise<Either<Failure, IRoles>> {
  return await getRolesWithQuery(query)
}
export async function adminGetRolesWithQuery(queryString:string): Promise<Either<Failure, IRoles>> {
  return await getRolesWithQuery(queryString)
}


export async function adminGetRole(id:string): Promise<Either<Failure, IRoleResponse>> {
  return await getRole(id)
}

export async function adminDeleteRole(id:string, roleReplacementId:string): Promise<Either<Failure, IRole>> {
  return await deleteRole(id,roleReplacementId)
}


export async function adminCreateRole(data:any): Promise<Either<Failure, IRoleResponse>> {
  return await postRole(data)
}

export async function adminUpdateRole(data:any, id:string): Promise<Either<Failure, IRoleResponse>> {
  return await updateRole(data,id)
}


export async function adminGetProjects(): Promise<Either<Failure, IProject[]>> {
  return await getProjects()
}

// ED -1386
export async function getAdminProjects(queryString: string): Promise<Either<Failure, IProjectList>> {
  return await getProjectsFromQuery(queryString)
}

export async function getSingleLevels(queryString: string, level = LevelOptions.One): Promise<Either<Failure, ILevelList>> {
  return await singleLevelsGet(queryString, level)
}

export async function deleteLevel(id: string): Promise<Either<Failure, ILevel>> {
  return await levelDelete(id)
}

export async function deleteLevels(ids: string[]): Promise<Either<Failure, IDeletedObject[]>> {
  return await levelsDelete(ids)
}

export async function deleteProject(id: string): Promise<Either<Failure, IProject>> {
  return await projectDelete(id)
}

// //ED-1386
export async function deleteProjects(ids: string[]): Promise<Either<Failure, IDeletedObject[]>> {
  return await projectsDelete(ids)
}

export async function getLevelById(id: string): Promise<Either<Failure, ILevel>> {
  return await levelGetById(id)
}

// TODO test
export async function getProjectById(id: string): Promise<Either<Failure, IProject>> {
  return await projectGetById(id)
}

export async function getProjectCustomFields(): Promise<Either<Failure, IProjectCustomField[]>> {
  return await projectCustomizeFieldsGet()
}

export async function createProjectCustomizeField(  data: IProjectCustomField): Promise<Either<Failure, IProjectCustomField>> {
  return await projectCustomizeFieldCreate(data)
}

export async function updateProjectCustomizeField( id:string, data: IProjectCustomField): Promise<Either<Failure, IProjectCustomField>> {
  return await projectCustomizeFieldUpdate(id, data)
}


export async function searchProjects(searchTerm: string): Promise<Either<Failure, IProject[]>> {
  return await projectsSearch(searchTerm)
}

export async function favoriteLevel(id: string): Promise<Either<Failure, ILevel>> {
  return await levelFavorite(id)
}

export async function unfavoriteLevel(id: string): Promise<Either<Failure, ILevel>> {
  return await levelUnfavorite(id)
}

export async function favoriteProject(id: string): Promise<Either<Failure, IProject>> {
  return await projectFavorite(id)
}

export async function unfavoriteProject(id: string): Promise<Either<Failure, IProject>> {
  return await projectUnfavorite(id)
}

export async function searchLevels(searchTerm: string, level = LevelOptions.One): Promise<Either<Failure, ILevel[]>> {
  return await levelsSearch(searchTerm, level)
}

export async function createLevel(levelData: ICreateLevel): Promise<Either<Failure, ILevel>> {
  return await levelCreate(levelData)
}

export async function updateLevel(id: string, levelData: ICreateLevel): Promise<Either<Failure, ILevel>> {
  return await levelUpdate(id, levelData)
}

export async function createProject( data: IProjectFormData): Promise<Either<Failure, IProject>> {
  return await projectCreate(data)
}
export async function updateProject(id: string, projectData: IProjectFormData): Promise<Either<Failure, IProject>> {
  return await projectUpdate(id, projectData)
}

//Minimal endpoints
export async function getMinimalOrgHierarchyNames(orgId:string, formId?:string): Promise<
  Either<Failure, IHierarchyNameList>
  // eslint-disable-next-line indent
> {
  const result = await getMinimalHierarchyNames(orgId, formId)

  return result
}

export async function getOrgMinimalUsers(orgId:string, formId?:string): Promise<
  Either<Failure, IMinimalUser[]>
  // eslint-disable-next-line indent
> {
  const result = await getMinimalUsers(orgId, formId)

  return result
}


export async function getOrgMinimalContacts(orgId:string, formId?:string): Promise<
  Either<Failure, IMinimalUser[]>
  // eslint-disable-next-line indent
> {
  const result = await getMinimalContacts(orgId, formId)

  return result
}

export async function getOrgMinimalContractors(orgId:string, formId?:string): Promise<
  Either<Failure, IMinimalUser[]>
  // eslint-disable-next-line indent
> {
  const result = await getMinimalContractor(orgId, formId)

  return result
}

export async function getOrgMinimalLevels(orgId:string, formId?:string): Promise<
  Either<Failure, IMinimalIdName[]>
  // eslint-disable-next-line indent
> {
  const result = await getMinimalLevels(orgId, formId)

  return result
}

export async function getOrgMinimalProjects(orgId:string, formId?:string): Promise<
  Either<Failure, IMinimalIdName[]>
  // eslint-disable-next-line indent
> {
  const result = await getMinimalProjects(orgId, formId)

  return result
}

export async function getOrgMinimalLocations(orgId:string, formId?:string): Promise<
  Either<Failure, IMinimalIdName[]>
  // eslint-disable-next-line indent
> {
  const result = await getMinimalLocations(orgId, formId)

  return result
}

export async function getOrgMinimalSubLocationHierarchyNames(orgId:string, formId?:string): Promise<
  Either<Failure, IMinimalSubLocationName[]>
  // eslint-disable-next-line indent
> {
  const result = await getMinimalSubLocationHierarchyNames(orgId, formId)

  return result
}
