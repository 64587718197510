import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridItem } from '../gridItems'
import { IRole } from '../../../../domain/interfaces/IRole'
import { formatSnakeCase } from '../../../../core/utils/text-formaters/TextFormatters'

export const role_columns: GridColDef[] = [
  {
    field: 'name',
    renderHeader: () => <GridItem text={'Role Name'} />,
    flex: 1,
    renderCell: ({ row }) => <GridItem text={row.name} />,
  },
  {
    field: 'usersCount',
    renderHeader: () => <GridItem text={'Number of Users'} />,
    flex: 1,
    renderCell: ({ row }) => <GridItem text={row.usersCount} />,
  },
]

export const normalizeRolesForDataGrid: any = (roles: IRole[] = []) => {
  return roles.map((role: any) => {
    return {
      id: role.id,
      name: formatStandardRoleName(role.name),
      usersCount: role.usersCount,
      type: role.isSystemRole ? 'Default' : 'Custom',
    }
  })
}

export const formatStandardRoleName = (roleName: string) => {
  if(['SUPER_ADMIN', 'ADMIN', 'USER'].includes(roleName)) {
    return formatSnakeCase(roleName)
  }
  return roleName
}
