import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import { AppColors, AppFonts } from '../../Theme'
import {
  LoginPageViewModel,
  LoginPageViewModelProps,
  useLoginPageViewModel,
} from './LoginPageViewModel'
import ReactOtpInput from 'react-otp-input'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getCurrentOrgId } from '../../../domain/domain'
import { ROUTE_ORG_SELECTION } from '../org_selection/OrgSelectionPage'
import { ROUTE_DASHBOARD } from '../dashboard/DashboardPage'

import { SafetyMojoLoginIcon } from '../../../core/components/icons/SafetyMojoLoginIcon'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import { ETypography } from '../../components/fonts/ETypography'
import FormErrorText from '../../components/form/FormErrorText'
import { EdifyButton } from '../../components/buttons'
import MobileDownLoadScreen from './MobileDownLoadScreen'
import { useTranslation } from 'react-i18next'

export const ROUTE_LOGIN = '/login'

export const LoginPage: React.FC = () => {
  return (
    <LoginPageViewModel>
      <Page />
    </LoginPageViewModel>
  )
}

const Page: React.FC = () => {
  const vm: LoginPageViewModelProps = useLoginPageViewModel()

  if (vm.mobileLink) {
    return <MobileDownLoadScreen mobileLink={vm.mobileLink} />
  }
  return (
    <Box
      data-testid='LoginPage'
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: AppColors.baseWhite,
      }}
    >
      <SafetyMojoLoginIcon width={180} height={48} />
      <Form />
    </Box>
  )
}

const Form: React.FC = () => {
  const vm: LoginPageViewModelProps = useLoginPageViewModel()
  const navigate = useNavigate()
  const [checkedSMS, setCheckedSMS] = useState<boolean>(false)
  const {t} = useTranslation()

  const handleContinue = async () => {
    if (!vm.continueOrSignin) {
      return
    }
    const success = await vm.continueOrSignin()
    if (success && vm.currentPage == 'codeVerification') {
      /** navigate to org selection page on login success */
      const currOrg = getCurrentOrgId()
      if (currOrg) {
        navigate(ROUTE_DASHBOARD)
        return
      }
      navigate(ROUTE_ORG_SELECTION)
    }
  }
  const handleSMSClicked = async () => {
    // todo API call to confirm
    setCheckedSMS(!checkedSMS)
  }

  const handleCancel = async () => {
    if (!vm.handleCancel) {
      return
    }
    vm.handleCancel()
  }

  return (
    <Box sx={{ width: '412px' }}>
      <Box
        sx={{
          marginTop: '96px',
          alignItems: 'center',
          padding: '32px 24px',
          borderRadius: '10px',
          border: `1px solid ${AppColors.neutral25}`,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        }}
      >
        <ETypography
          font='HMS'
          color='gray700'
          data-testid='FormTypeLabel'
          sx={{ textAlign: 'start', alignSelf: 'flex-start' }}
        >
          {vm.currentPage === 'signin' ? t('Sign In') : 'Enter Verification Code'}
        </ETypography>
        {vm.currentPage === 'signin' ? (
          <SignInContent />
        ) : (
          <VerificationContent />
        )}
        {vm.state === 'error' ? (
          <FormErrorText>{vm.errorMessage}</FormErrorText>
        ) : null}

        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '32px' }}
        >
          <EdifyButton
            noBackground
            secondary
            buttonStyle={{ padding: '10px 16px' }}
            title={vm.currentPage == 'signin' ? 'Clear' : 'Cancel'}
            onClick={handleCancel}
            disabled={vm.state == 'loading' || vm.state == 'submitting'}
            data-testid='FormCancelButton'
          />

          <EdifyButton
            primary
            buttonStyle={{ padding: '10px 16px' }}
            title={vm.currentPage == 'signin' ? 'Continue' : 'Sign In'}
            data-testid='FormContinueOrSigninButton'
            onClick={handleContinue}
            disabled={vm.state == 'loading' || vm.state == 'submitting'}
          />
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: '16px',
          // padding: '12px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: '16px',
        }}
      >
        <Link to='https://getmojo.ai/security-policy/' target='_blank'>Security Policy</Link>
        <Link to='https://getmojo.ai/privacy-policy/' target='_blank'>Privacy Policy</Link>
        <Link to='https://getmojo.ai/sms-policy/' target='_blank'>SMS Terms of Use</Link>
      </Box>

    </Box>
  )
}

const SignInContent: React.FC = () => {
  const vm: LoginPageViewModelProps = useLoginPageViewModel()
  const location = useLocation()
  const ssoError = location.state?.error 

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = e.target
    vm.onInputChange(value)
  }

  return (
    <>
      <EdifyFormField>
        <EdifyFieldLabel label='EMAIL / PHONE NUMBER' />
        <TextField
          variant='standard'
          size='medium'
          sx={{
            flex: 'none',
            '& label': {
              ...AppFonts.interHeadlineMSemibold,
              color: AppColors.neutral950,
              fontSize: '15px',
              fontWeight: '400px',
              lineHeight: '22px',
              letterSpacing: '-0.1px',
              flex: 'none',
              order: 1,
              flexGrow: 0,
              marginTop: '-8px',
              top: '-5px',
              left: '14px',
            },
            '& label.Mui-focused': {
              fontWeight: 400,
              fontSize: 12,
              color: AppColors.gray25,
            },
            alignItems: 'center',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            height: '40px',
            width: '360px',
            border: `1px solid ${vm.state === 'error' ? AppColors.danger600 : AppColors.neutral700}`,
          }}
          label={!vm.formData.inputValue ? 'Enter your email or phone number' : ''}
          value={vm.formData.inputValue}
          focused={vm.formData.inputValue.length > 0}
          InputProps={{
            disableUnderline: true,
            style: {
              backgroundColor: !vm.formData.inputValue
                ? 'transparent'
                : 'inherit',
              left: '12px',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              width: '340px',
              textDecoration: 'none',
              top: !vm.formData.inputValue ? '-8px' : '0px',
            },
            endAdornment: (
              <InputAdornment
                position='end'
                style={{
                  color:
                    vm.state === 'error'
                      ? AppColors.danger600
                      : AppColors.baseWhite,
                }}
              >
                <InfoOutlinedIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleOnChange}
        />
        {ssoError && <FormErrorText>{ssoError}</FormErrorText>}
      </EdifyFormField>

    </>
  )
}

const VerificationContent: React.FC = () => {
  const vm: LoginPageViewModelProps = useLoginPageViewModel()

  return (
    <Box data-testid='FormCodeVerificationBox' style={{ marginTop: '16px' }}>
      <ReactOtpInput
        value={vm.formData.inputValue}
        onChange={(otp: string) => {
          vm.onInputChange(otp)
        }}
        numInputs={6}
        inputStyle={{
          fontSize: '32px',
          fontWeight: 'light',
          width: '52px',
          height: '70px',
          marginRight: '8px',
          borderTop: '0px',
          borderLeft: '0px',
          borderRight: '0px',
          outline: 'none',
          borderColor: '#00000040',
          border:
            vm.state == 'error'
              ? `1px solid ${AppColors.danger600}`
              : `0px solid ${AppColors.baseWhite}`,
          borderRadius: '8px',
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
          background: AppColors.baseWhite,
          WebkitAppearance: 'none',
          MozAppearance: 'textfield',
        }}
        containerStyle={{
          margin: '0px',
          padding: '0px',
        }}
        inputType='tel'
        renderInput={(props) => (
          <input
            {...props}
            className='login-input'
            data-testid='FormCodeVerificationField'
          />
        )}
      />
    </Box>
  )
}
