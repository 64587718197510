import React, { ReactNode, useEffect, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
// import '../EdifyImage/filepond-overrides.css'

import { EBox } from '../../box/Ebox'
import { getFromLocalStorage, getInitialToken } from '../../../../data/data'
import { REACT_APP_SERVER_API } from '../../../../config'
import FormErrorText from '../FormErrorText'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import { ErrorToast } from '../../../../core/utils/toast-notifications/ToastNotifications'
import ImageWithToken from '../EdifyImage/ImageWithToken'
import { useWindowSize } from 'usehooks-ts'

interface EdifyImageUploaderProps {
  noUI?: boolean
  edifyUI?: boolean
  label?: string
  handleLoad: (url: string | any) => void
  // these two are needed for the noUI version
  handleOnData?: (data?: FormData) => void
  handleOnError?: (err: any) => void
  handleOnAddStart?: (file?: any) => void
  singleFile?: boolean
  uploadUrl?: string | null
  dataKey?: string
  decodeResponse?: (object: any) => string
  resetFilesOnSuccess?: boolean
  acceptedFileTypes?: string[]
  additionalData?: any
  onFilesProcessed: () => void
  fullWidth: boolean
}

// const addImageSVG = `<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M21.0002 14.3334V27.6667M27.6668 21L14.3335 21" stroke="#323539" stroke-width="1.5" stroke-linecap="round"/>
// <rect x="1" y="1" width="40" height="40" rx="20" stroke="#D6D7DD" stroke-linecap="round" stroke-dasharray="4 4"/>
// </svg>`

// Register the plugins

const EdifyPDFUploader: React.FC<EdifyImageUploaderProps> = ({
  noUI = false,
  label = '<p class="filepond--drop-file-text">Drop your files here <p class="filepond--label-action">Or browse your files</p>',
  handleLoad,
  handleOnData,
  handleOnError,
  handleOnAddStart,
  uploadUrl = '/common/fileUpload',
  dataKey = 'images',
  decodeResponse = (object: any) => object.data[0],
  singleFile = false,
  edifyUI = false,
  resetFilesOnSuccess = true,
  acceptedFileTypes = ['application/pdf'],
  additionalData = {},
  onFilesProcessed,
  fullWidth = true,
}) => {
  // if (!noUI) {
  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType, 
  )

  // }
  const authToken: string = getInitialToken()
  const storedActiveOrg = getFromLocalStorage('currentOrgId')
  const org = storedActiveOrg != null ? storedActiveOrg : ''

  const [files, setFiles] = useState<any[]>([])
  const [actualFile, setActualFile] = useState<any[]>([])
  const [title, setTitle] = useState<string>(label)
  const [error, setError] = useState<any | undefined>(undefined)

  const handleRemoveFile = (file: any) => {
    // TODO STILL CALLS WRONG API HERE
  }

  // TODO TEMPORARY FIX
  useEffect(() => {
    setActualFile(files)
  }, [files])

  const handleWarning = (warning:any, props:any) => {

    if (warning && warning.body && warning.body =='Max files' ) {
      // You can use your preferred method to show this message to the user
      ErrorToast({ title: 'Sorry, you can only upload (5) files at a time.' })
      // setError('Maximum number of files reached (5)');
    }
  }

  const renderFilePond = () => {
    return (
      <FilePond
        key='filepond-pdf'
        onwarning={(warning, props) => handleWarning(warning, props)}
        files={files}
        onupdatefiles={setFiles}
        // BE call this is using does not accept pdf
        acceptedFileTypes={acceptedFileTypes}
        labelIdle={title}
        allowMultiple={singleFile ? false : true}
        maxFiles={singleFile ? 1 : 5}
        name='images'
        onremovefile={handleRemoveFile}
        allowRevert={false}
        // server={{ process: handleUpload }}
        server={{
          process: {
            url: `${REACT_APP_SERVER_API}${uploadUrl}`,
            method: 'POST',
            headers: {
              Authorization: authToken,
              orgId: org,
            },
            withCredentials: false,
            // called when frontend gets the file
            ondata: () => {
              noUI && setError(undefined)
              handleOnData && handleOnData()
              const formData: FormData = new FormData()
              additionalData &&
                Object.keys(additionalData).forEach((key) => {
                  formData.append(key, additionalData[key])
                })
              // prevents filepond from getting stuck loading
              // need to send an error if this happens though
              // if (files[0] && files[0].file) {
              //   const file = files[0].file
              //   formData.append(dataKey, file)

              // }
              files.forEach((fileItem) => {
                if (fileItem && fileItem.file) {
                  formData.append(dataKey, fileItem.file)
                }
              })

              return formData
            },
            // called when unsuccessfully uploaded
            onerror: (error: any) => {
              const res = JSON.parse(error)
              noUI && setTitle(label)
              noUI && setError(res.message ?? 'Error uploading image')
              handleOnError && handleOnError(res)
              ErrorToast({ title: res?.message ?? 'Error uploading image' })
            },

            // called when successfully uploaded
            // onload: (props: any) => {
            //   noUI && setTitle(label)
            //   const res = JSON.parse(props)
            //   const url = decodeResponse(res)
            //   handleLoad(url)
            //   resetFilesOnSuccess && setFiles([])
            //   return url
            // },
          },
        }}
        onaddfilestart={() => {
          noUI && setTitle('Uploading...')
          handleOnAddStart && handleOnAddStart()
        }}
        // onprocessfile={(error, file) => {
        //   if (error) {
        //     console.error(error)
        //     return
        //   }
        //   // IS THE FIRST FILL THAT SUCCESSFULLY UPLOADS DESPITE HOW MANY ARE
        //   const response = JSON.parse(file.serverId)
        //   const url = decodeResponse(response)
        //   handleLoad({ ...file, url })
        //   resetFilesOnSuccess && setFiles([])
        //   return file
        // }}
        onprocessfiles={() => {
          resetFilesOnSuccess && setFiles([])
          onFilesProcessed && onFilesProcessed()
        }}
      />
    )
  }

  if (edifyUI) {
    return <CustomUIWrapper>{renderFilePond()}</CustomUIWrapper>
  }
  const getClassName = () => {
    if (noUI) return 'no-upload-ui'
    if (edifyUI) return 'edify-upload-ui'
    return ''
  }

  const width = label == 'Add Photo' ? 75 : 95
  return (
    <EBox
      p='0'
      border={noUI ? 'none' : 'dashed'}
      sx={{ position: 'relative', width: noUI ? width : 'auto' }}
      className={getClassName()}
    >
      <>
        {renderFilePond()}
        {error && (
          <FormErrorText
            sx={{
              position: 'absolute',
              width: '200px',
              // width - width of EBox
              left: `-${200 - width}px`,
              textAlign: 'right',
              marginTop: '8px',
            }}
          >
            {error}
          </FormErrorText>
        )}
      </>
    </EBox>
  )
}

export default EdifyPDFUploader

{
  /*TODO: This work but makes it unClickable, which makes it pointless (because the z-index of this to make it visible but cover the clickable element)  */
}
{
  /* <ETypography
        font='SM'
        color='primary600'
        sx={{ textAlign: 'center', position: 'relative', zIndex: '99',top:66 }}
      >
        Or browse your computer
      </ETypography> */
}

interface ICustomUIWrapperProps {
  children: ReactNode
  imgURL?: string
  fullWidth?: boolean
}
const CustomUIWrapper: React.FC<ICustomUIWrapperProps> = ({
  children,
  imgURL,
  fullWidth = true,
}) => {
  const width = (fullWidth || useWindowSize().width < 495) ? '100%' : '495px' 
  return (
    <EBox
      p='0'
      border='primary'
      // onClick={handleOpenInNewTab}
      sx={{
        display: 'flex',
        jc: 'flex-start',
        fd: 'row',
        width: width,
        borderRadius: '10px',
        // minWidth: 'calc(50% - 12px)',
        // ...sx,
      }}
    >
      {imgURL && (
        <ImageWithToken
          src={imgURL}
          style={{
            width: '48px',
            height: '48px',
            marginBottom: '16px',
            marginRight: '16px',
          }}
        />
      )}
      <EBox style={{ flex: 1 }}>{children}</EBox>
    </EBox>
  )
}