// PersonProfileForm.tsx
import React, { useEffect, useState } from 'react'
import { useForm, Controller, FieldErrors, Resolver } from 'react-hook-form'
import { Input, Box, SxProps } from '@mui/material'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import { phoneInputFormatter } from '../../../core/utils/input-formatters/InputFormatters'
import FormErrorText from '../../components/form/FormErrorText'

import { personUpdate, saveToLocalStorage } from '../../../data/data'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { getCurrentPerson, getCurrentUser } from '../../../domain/domain'
import { IVerifyOTPPayload } from '../../../domain/interfaces/IUserDetails'
import { EdifyButton } from '../../components/buttons'
import NotificationsSettings from '../../components/forms/PersonProfileForm/NotificationsSettings'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'

type ProfileInputs = {
  fullName: string
  email: string
  phone: string
}

interface IProfileFormProps {
  setUserName: (name: string) => void
  uploadImage?: string | null
  sx?: SxProps
}

export const UserPersonForm: React.FC<IProfileFormProps> = ({
  setUserName,
  uploadImage,
  sx,
}) => {
  const { resetProfileTileCallback } = useOrganizationProvider()
  const [person, setPerson] = useState<IVerifyOTPPayload | undefined>(undefined)
  const [emailChecked, setEmailChecked] = useState<boolean>(true)
  const [smsChecked, setSMSChecked] = useState<boolean>(false)
  const [SMSTermsChecked, setSMSTermsChecked] = useState<boolean>(false)
  const [SMSTermsChanged, setSMSTermsChanged] = useState<boolean>(false)
  const [notificationError, setNotificationError] = useState<
    string | undefined
  >(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const resolver: Resolver<ProfileInputs> = async (values) => {
    const errors: FieldErrors<ProfileInputs> = {}

    if (!values.fullName) {
      errors.fullName = {
        type: 'required',
        message: 'Name is required',
      }
    }
    // Check if at least one contact method is provided
    if (!values.email && !values.phone) {
      errors.email = {
        type: 'required',
        message: 'Either email or phone number is required',
      }
      errors.phone = {
        type: 'required',
        message: 'Either email or phone number is required',
      }
    }

    if (values.phone) {
      const phoneNumberPattern = /^\d{3}-\d{3}-\d{4}$/
      if (!phoneNumberPattern.test(values.phone)) {
        errors.phone = {
          type: 'pattern',
          message: 'Invalid phone number',
        }
      }
    }
    return {
      values,
      errors,
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    clearErrors,
  } = useForm<ProfileInputs>({
    resolver,
    mode: 'onSubmit',
  })

  const handlePhoneInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    const formattedValue = phoneInputFormatter(value)
    if (value.length > 0) {
      clearErrors(['phone', 'email'])
    }
    setValue('phone', formattedValue, {
      shouldValidate: value.length > 11 || value.length == 0,
    })
  }

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true)
    const res = await personUpdate(
      {
        // ...(data.phone !== '' && { phone: data.phone }),
        imageURL: uploadImage ?? '',
        fullName: data.fullName,
        smsNotifEnabled: smsChecked,
        emailNotifEnabled: emailChecked,
        phone: data.phone == '' ? '' : data.phone,
        email: data.email == '' ? '' : data.email,
      },
      person?.id ?? '',
    )
    setLoading(false)

    if (res.isLeft()) {
      const title = res.left?.message ?? 'Error saving new changes.'
      ErrorToast({ title: title })
    } else {
      SuccessToast({ title: 'Profile Successfully Update.' })
      res.right && saveToLocalStorage('person', JSON.stringify(res.right))
      resetProfileTileCallback(data.fullName, uploadImage ?? '')
      setUserName(data.fullName)
    }
  })


  useEffect(() => {
    if (!emailChecked && !smsChecked) {
      setNotificationError('You must select at least one of these options')
    } else {
      setNotificationError(undefined)
    }
  }, [emailChecked, smsChecked])
  useEffect(() => {
    const personData = getCurrentPerson()
    setPerson(personData ?? undefined)
    setEmailChecked(personData?.emailNotifEnabled ?? true)
    setSMSChecked(personData?.smsNotifEnabled ?? false)

    setValue('fullName', personData?.fullName ?? '')
    setValue('email', personData?.email ?? '')
    setValue(
      'phone',
      personData?.phone ? phoneInputFormatter(personData?.phone) : '',
    )
  }, [setValue])

  return (
    <InnerPageContainer headerTitle='Profile Settings' sx={{ ...sx }}>
      <form onSubmit={onSubmit}>
        <Box>
          <Box style={{ display: 'flex', flex: 1 }}>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel required label='NAME' />
              <Input
                disableUnderline
                placeholder='Enter name'
                {...register('fullName', { required: 'Name is required' })}
                className={`form-control ${errors?.fullName ? 'error' : ''}`}
              />
              {errors.fullName && (
                <FormErrorText>{errors.fullName.message}</FormErrorText>
              )}
            </EdifyFormField>
          </Box>
          <Box style={{ display: 'flex', flex: 1 }}>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='PHONE NUMBER' />
              <Controller
                name='phone'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    {...field}
                    data-testid='PhoneNumber'
                    disableUnderline
                    placeholder='000-000-0000'
                    className={`form-control ${errors?.phone ? 'error' : ''}`}
                    value={field.value}
                    onChange={handlePhoneInputChange}
                  />
                )}
              />
              {errors?.phone && (
                <FormErrorText>{errors.phone.message}</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='EMAIL' />
              <Input
                data-testid='Email'
                disableUnderline
                placeholder='sample@email.com'
                className={`form-control ${errors?.email ? 'error' : ''}`}
                {...register('email', {
                  onChange: () => clearErrors(['email', 'phone']),
                })}
              />
              {errors?.email && (
                <FormErrorText>{errors.email.message}</FormErrorText>
              )}
            </EdifyFormField>
          </Box>

          <NotificationsSettings
            setEmailChecked={setEmailChecked}
            setSMSChecked={setSMSChecked}
            emailChecked={emailChecked}
            smsChecked={smsChecked}
            setSMSTermsChecked={setSMSTermsChecked}
            SMSTermsChecked={SMSTermsChecked}
            setSMSTermsChanged={setSMSTermsChanged}
            SMSTermsChanged={SMSTermsChanged}
            notificationError={notificationError}
          />
          {notificationError && (
            <FormErrorText>{notificationError}</FormErrorText>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <EdifyButton
              // disabled={notificationError != undefined}
              disabled={Object.keys(errors).length > 0 || loading || notificationError != undefined}
              type='submit'
              primary
              title='Save Changes'
            />
          </Box>
        </Box>
      </form>
    </InnerPageContainer>
  )
}

export default UserPersonForm
