import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Modal,
} from '@mui/material'
import React, { useEffect } from 'react'
import { AppColors, AppFonts } from '../../Theme'
import { EdifyButton } from '../../components/buttons'

import { IForm } from '../../../domain/interfaces/IForm'
import useSubmissionLoader from '../../../core/hooks/useSubmission'
import {
  getCurrentUser,
} from '../../../domain/domain'
import useFormio, { IUseFormioReturn } from '../../../core/hooks/useFormio'
import MojoFormioRenderer1 from './MojoFormRenderer1'
import ErrorContainer from '../../components/error/ErrorContainer'

interface Props {
  open: boolean
  handleClose: (reload?: any) => void
  orgId: string
  childForm?: IForm
  formId: string
  subId: string | undefined
  formReadOnly?: IForm | undefined
  form: IForm

}

const BasicSubmissionModal: React.FC<Props> = ({
  open,
  handleClose,
  orgId,
  subId = undefined,
  formId,
  formReadOnly,
  form,
  // initMode = 'VIEW',
}) => {
  const [mode, setMode] = React.useState<'VIEW' | 'EDIT' | 'CREATE'>('VIEW')
  const [error, setError] = React.useState<string | undefined>('')
  const submissionLoader = useSubmissionLoader(subId)
  const { submission, submissionLoading, setSubmission,  } =
    submissionLoader
  const fio: IUseFormioReturn = useFormio(formId, submission, {
    form: form,
    orgId: orgId,
    isAnon: false,
  })


  useEffect(() => {

    return () => {
      setSubmission(undefined)
    }
  }, [])

  const renderError = () => {
    return <ErrorContainer>{error}</ErrorContainer>
  }

  const currentUser = getCurrentUser()

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby='child-modal-title'
        aria-describedby='child-modal-description'
        sx={{ marginTop: '50px' }}

      >
        <Box
          sx={{
            ...styles,
            width: {
              sm: '100%',
              md: '100%',
            },
            maxWidth:'940px'
          }}
        >
          <DialogTitle
            sx={{
              ...AppFonts.headlineSDesktopSemibold,
              color: AppColors.gray700,
              p: 0,
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            {/* {childForm.title} */}
            {error && renderError()}
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              pb: 12,
              overflowY: 'scroll',
              height: 'auto',
              overflow: 'scroll',
            }}
          >
            {!submissionLoading && (
              <>
                <MojoFormioRenderer1
                  key={mode}
                  // readOnly={true}
                  mode={mode}
                  orgId={orgId}
                  formId={formId}
                  submissionId={submission?.id ?? 'ERROR NEED ID'}
                  options={{ readOnly: mode === 'VIEW' }}
                  form={formReadOnly}
                  formLoading={false}
                  formSubmissionError={undefined}
                  formReadOnly={formReadOnly}
                  isAnon={false}
                  {...submissionLoader}
                  {...fio}
                />
              </>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              p: 0,
            }}
          >
            <EdifyButton
              data-testid='CloseDialogButton'
              fullWidth
              title={'Close'}
              secondary
              onClick={() => handleClose(false)}
            />
          </DialogActions>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

export default BasicSubmissionModal

const styles = {
  position: 'absolute',
  top: '10vh',
  left: '50%',
  transform: 'translate(-50%, -50px)',
  height: 'auto',
  maxHeight: '80vh',
  overflow: 'scroll',
  backgroundColor: AppColors.g000,
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 24,
}
