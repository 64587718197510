import React from 'react'
import { IRole } from '../../../domain/interfaces/IRole'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Grid, Input, MenuItem } from '@mui/material'
import { ETypography } from '../../components/fonts/ETypography'
import { EdifySelect } from '../../components/form'
interface Props {
  mode: 'VIEW' | 'EDIT' | 'CREATE'
  setName?: (name: string) => void
  setDefaultRole?: any
  name: string
  defaultRoles?: { name: string; id: string }[]
  role?: { name: string; id: string }
  getRole?: (id: string) => void
}

import { formatStandardRoleName } from '../../components/datagrid/gridData/roles'
export const RolePageHeader: React.FC<Props> = ({
  mode,
  setName,
  name,
  setDefaultRole,
  defaultRoles,
  role,
  getRole,
}) => {
  const renderInput = () => {
    return (
      <Input
        data-testid='RoleName'
        disableUnderline
        placeholder='Role Name'
        className={'form-control'}
        onChange={(e) => setName && setName(e.target.value)}
        value={name}
        sx={{ marginTop: '24px', marginRight: '24px !important' }}
      />
    )
  }
  const handleChange = (e: any) => {
    setDefaultRole({ id: e.target.value, name: '' })
    getRole && getRole(e.target.value)
  }
  const renderCreate = () => {
    return (
      <Grid container spacing={12} sx={{ p: 0, m: 0 }}>
        <Grid item lg={6} m={6} sm={12} xs={12} sx={{ p: 6, m: 0 }}>
          <ETypography font='LSB' color='gray700'>
            Role Name
          </ETypography>
          <ETypography font='MM' color='neutral800'>
            This name should reflect the type of work this user does. Most
            companies use names like “Foreman” or “Superintendent”.
          </ETypography>
          {renderInput()}
        </Grid>
        <Grid item lg={6} m={6} sm={12} xs={12} sx={{ p: 6, m: 0 }}>
          <ETypography font='LSB' color='gray700'>
            System Permissions
          </ETypography>
          <ETypography font='MM' color='neutral800'>
            These are the default permissions defining how this role will be
            able to interact with the features they have access to.
          </ETypography>
          <EdifySelect
            sx={{ marginTop: '24px', width: '100%' }}
            value={role?.id}
            dropDownItems={defaultRoles ?? []}
            onChange={handleChange}
            renderMenuItem={(role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            )}
          ></EdifySelect>
        </Grid>
      </Grid>
    )
  }
  const renderEdit = () => {
    return (
      <Grid container spacing={0} sx={{ p: 0, m: 0 }} gap='12px'>
        <Grid item m={12} xs={12} sx={{ p: 0, m: 0 }}>
          <ETypography font='LSB' color='gray700'>
            Role Name
          </ETypography>
          <ETypography font='MM' color='neutral800'>
            This name should reflect the type of work this user does. Most
            companies use names like “Foreman” or “Superintendent”.
          </ETypography>
          {renderInput()}
        </Grid>
      </Grid>
    )
  }
  const renderView = () => {
    return (
      <Grid container spacing={0} sx={{ p: 0, m: 0 }} gap='12px'>
        <Grid item m={12} xs={12} sx={{ p: 0, m: 0 }}>
          <ETypography
            font='LSB'
            color='neutral800'
            sx={{ marginBottom: '12px' }}
          >
            ROLE NAME
          </ETypography>
          <ETypography font='MM' color='gray700'>
            {formatStandardRoleName(name)}
          </ETypography>
        </Grid>
      </Grid>
    )
  }
  const renderSection = () => {
    if (mode === 'CREATE') {
      return renderCreate()
    }
    if (mode === 'EDIT') {
      return renderEdit()
    }
    return renderView()
  }

  return <InnerPageContainer>{renderSection()}</InnerPageContainer>
}
