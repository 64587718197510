import React, { useState } from 'react'
import {
  downloadCorrectiveActionPDF,
  downloadCorrectiveActionsPDF,
} from '../../../domain/domain'
import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import { ErrorToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { ROUTE_CORRECTIVE_ACTIONS } from './CorrectiveActionsPage'

import { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { correctiveActionColumnsOld } from '../../components/datagrid/gridData'
import { useNavigate } from 'react-router-dom'
import BulkDownloadButton from '../../components/buttons/BulkButtons/BulkDownloadButton'
import { CorrectiveActionsPageViewModelProps } from './CorrectiveActionsViewModel'
import fileDownload from 'js-file-download'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import pluralize from 'pluralize'
import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'

interface ICorrectiveActionPageFilterProps {
  vm: CorrectiveActionsPageViewModelProps
  title: string
  rows: any[]
  canArchive?: boolean
}

const CorrectiveActionDataGridOld: React.FC<
  ICorrectiveActionPageFilterProps
> = ({ vm, title, rows, canArchive }) => {
  const navigate = useNavigate()
  const { getHierarchyName, flags } = useOrganizationProvider()

  const [downloadingPDF, setDownloadingPDF] = useState<boolean>(false)
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>(() => [])

  /**
   * Handles the row click event in the data grid.
   *
   * note that we have two cases here isCorrectiveActionView and not isCorrectiveActionView so the id comes in different ways
   * @param {GridRowParams} params - The parameters object containing row data.
   */
  const handleRowClicked = (params: GridRowParams) => {
    navigate(`${ROUTE_CORRECTIVE_ACTIONS}/${params.row.id}`)
    return
  }

  /**
   * Gets the PDF IDs for downloading.
   * @returns {string} A string representing the PDF IDs. ex 1;2;3
   *
   */
  const getPDFIds = (): any => {
    //TODO ONCE BE implemetns bulk down;oad fix
    return selectionModel as string[]
  }

  /**
   * Downloads the PDF files. can handle 1 file
   */
  const downloadPDF = async () => {
    if (!selectionModel.length) return
    setDownloadingPDF(true)
    const ids = getPDFIds()[0]
    const res = await downloadCorrectiveActionPDF(ids)
    if (res.isLeft() || !res.right) {
      ErrorToast({ title: 'Error downloading PDF.' })
      setDownloadingPDF(false)
      return
    }
    //TODO ONCE BE implemetns bulk down;oad fix
    // extractAndMergeFilesToPDF(res.right, 'corrective action')
    fileDownload(res.right, 'corrective-action.pdf')
    setDownloadingPDF(false)
  }

  /**
   * Downloads the PDF files. can handle 1 file
   */
  const downloadPDFs = async () => {
    if (!selectionModel.length) return
    setDownloadingPDF(true)
    const ids = getPDFIds()
    const res = await downloadCorrectiveActionsPDF(ids)
    if (res.isLeft() || !res.right) {
      ErrorToast({ title: 'Error downloading PDF.' })
      setDownloadingPDF(false)
      return
    }
    fileDownload(res.right, 'corrective-action.pdf')
    setDownloadingPDF(false)
  }

  const removeClicked = async () => {
    vm.deleteCorrectiveActions(selectionModel as string[])
    console.log(selectionModel)
    setSelectionModel([])
  }

  const renderHeaderButtons = () => {
    const noSelections = selectionModel.length === 0
    return (
      <BulkDeleteButton noSelections={noSelections} onClick={removeClicked} />
    )
  }

  return (
    <>
      <EdifyDataGrid
        data-testid='CorrectiveActionDataGrid'
        title={title}
        noRowsText={`No ${pluralize(title)} found.`}
        renderHeaderButtons={
          <>
            <BulkDownloadButton
              onClick={downloadPDFs}
              downloadingPDF={downloadingPDF}
              noSelections={selectionModel.length == 0}
            />
            {/* Later for bulk archive */}
            {/* {canArchive && renderHeaderButtons()} */}
          </>
        }
        columns={correctiveActionColumnsOld(
          getHierarchyName(0),
          flags.isLocationEnabled,
          flags.isSubLocationEnabled,
        )}
        rows={rows}
        paginationMode='server'
        error={vm.correctiveActionError}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleRowClicked={handleRowClicked}
        loading={vm.correctiveActionsLoading || vm.initialLoad}
        rowCount={vm.totalCount || 0}
        loadMoreRows={vm.loadMore}
        initSort={[
          {
            field: 'createdAt',
            sort: 'desc',
          },
        ]}
        reload={vm.loadCorrectiveActions}
        sortClickedHandler={vm.sortClickedHandler}
      />
    </>
  )
}

export default CorrectiveActionDataGridOld
