import React, { useState, useEffect, useRef } from 'react'
import {
  Autocomplete,
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton,
  SxProps,
  Box,
} from '@mui/material'

import { MagnifyGlassIcon } from '../../../../core/components/icons/MagnifyGlassIcon'
import ClearIcon from '@mui/icons-material/Clear'
import { getMinimalContractor, globalMinimalSearch } from '../../../../domain/usecases/utils/UtilsUsecasses'
import { EGlobalSearchPayload } from '../../../../data/repositories/utils/UtilsRepository'
import { GridBadge, IGridBadgeType } from '../../datagrid/gridItems'
import { AppColors } from '../../../Theme'
import { ETypography } from '../../fonts/ETypography'

interface GlobalSearchProps {
  globalSearchPayload?: EGlobalSearchPayload
  entities: string
  onGlobalSearchSelect: (selectedValueId: string, selectedValue: string) => void
  onGlobalSearchWithText: (searchText: string) => void
  hideTextSearch?: boolean
  onGlobalSearchClear: () => void
  placeholder?: string
  defaultValue?: string
  showBadge?: boolean
  l1Name?: string
  l0Name?: string
  projectName?: string
  sx?: SxProps
  globalClear?: boolean
  errors?: any // Optional error message
  isContractorSelfSignup?: boolean
}

const GlobalSearch: React.FC<GlobalSearchProps> = ({
  globalSearchPayload,
  entities,
  onGlobalSearchClear,
  onGlobalSearchSelect,
  onGlobalSearchWithText,
  placeholder = 'Search',
  defaultValue = '',
  showBadge = false,
  l1Name = 'Division',
  projectName = 'Project',
  sx,
  globalClear = false,
  hideTextSearch = false,
  errors = null,
  isContractorSelfSignup = false
}) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<any[]>([])
  const [inputValue, setInputValue] = useState(defaultValue)
  const [loading, setLoading] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)
  const [hasChanged, setHasChanged] = useState(false)
  // hack to clear the input field when X is clicked
  const [inputKey, setInputKey] = useState(Math.random().toString())

  const fetchOptions = async () => {
    setLoading(true)
    let res
    if(isContractorSelfSignup){
      res = await getMinimalContractor() 
    }else{
      res = await globalMinimalSearch(entities, inputValue) 
    }
    setLoading(false)
    if (res.right && res.right.data) {
      const newOptions = res.right.data.map((option: any) => ({
        value: option.id,
        label: option.name ? option.name : option.fullName,
        entity: option.entity,
      }))
      if (inputValue.length >= 1 && !hideTextSearch) {
        setOptions([
          {
            value: inputValue,
            label: inputValue,
            entity: 'search',
          },
          ...newOptions,
        ])
      } else {
        setOptions(newOptions)
      }
    }
  }
  useEffect(() => {
    if (globalClear) {
      // just need to reset value in field
      setInputKey(Math.random().toString())
      setInputValue('')
      setSelectedValue(null)
      setOptions([])
    }

  }, [globalClear])

  useEffect(() => {
    if (inputValue !== '') {
      setHasChanged(true)
    }
    // do not search if empty input, but reload if empty (like hitting clear)
    if (inputValue === '') {
      if (hasChanged) handleClear()
      return undefined
    }

    fetchOptions()
  }, [inputValue])

  const handleClear = async () => {
    // force a re-render to clear the input field
    setInputKey(Math.random().toString())
    setInputValue('')
    setSelectedValue(null)
    setOptions([])
    await onGlobalSearchClear()
  }

  const handleInputChange = (event: any, value: any) => {
    if (event == null) return
    setInputValue(value)
  }
  function capitalizeFirstLetter(string: string | undefined) {
    if (!string) return string // handle empty or undefined strings
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  const getBadgeText = (entity: string) => {
    if (entity.toLocaleLowerCase() === 'project') {
      return projectName
    }
    if (entity.toLocaleLowerCase() === 'level') {
      return l1Name
    }
    return entity
  }
  const getType = (entity: string): IGridBadgeType => {
    if (entity.toLocaleLowerCase() === 'project') {
      return 'success'
    }
    if (entity.toLocaleLowerCase() === 'level') {
      return 'danger'
    }
    if (entity.toLocaleLowerCase() === 'user') {
      return 'primary'
    }
    if (entity.toLocaleLowerCase() === 'contractor') {
      return 'warn'
    }
    return 'grey'
  }

  return (
    <Autocomplete
      key={inputKey}
      sx={{
        '& .MuiOutlinedInput-root': {
          width: '100%',
          border: 'none !important',
          padding: '4px 0px 4px 9px',
          paddingRight: '9px !important',
        },
        '& .MuiSelect-icon': {
          display: 'none',
        },
        '& .MuiFormControl-root fieldset': {
          border: `1px solid ${errors ? AppColors.danger600 : AppColors.neutral600} !important`,
        },
        ...sx,
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.value === value?.value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onFocus={fetchOptions}
      onChange={(event, newValue) => {
        if (newValue === null) {
          setInputValue('')
          return
        }
        setSelectedValue(newValue)

        if (newValue.entity === 'search') {
          onGlobalSearchWithText(newValue.value)
        } else {
          onGlobalSearchSelect(newValue.value, newValue.label)
        }
      }}
      options={options}
      loading={loading}
      renderOption={(props: any, option: any) => {
        const isSearch = option.entity === 'search'
        return (
          <Box
            {...props}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            key={props.id}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
              {isSearch && (
                <Box
                  paddingRight='8px'
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <MagnifyGlassIcon sx={{ marginRight: '12px' }} />
                  <ETypography color='gray700' font='MM'>
                    {/* {`Search for " ${option.label} "`} */}
                    {`"${option.label}"`}
                  </ETypography>
                </Box>
              )}
              {!isSearch && (
                <Box paddingRight='8px'>
                  <ETypography color='gray700' font='MM'>
                    {option.label}
                  </ETypography>
                </Box>
              )}
            </Box>
            {showBadge && (
              <ETypography color='gray700' font='MS'>
                {getBadgeText(option.entity)}
              </ETypography>
              // <GridBadge
              //   type={getType(option.entity)}
              //   sx={{ mr: '0px', ml: '8px' }}
              //   text={getBadgeText(option.entity)}
              // />
            )}
          </Box>
        )
      }}
      renderInput={(params) => (
        <TextField
          sx={{
            backgroundColor: AppColors.baseWhite,
            '& MuiTextField-root': {
              borderRadius: '5px',
              border: 'none !important',
            },
          }}
          {...params}
          placeholder={capitalizeFirstLetter(placeholder)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <MagnifyGlassIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {selectedValue || defaultValue ? (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <>
                    {loading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                  </>
                )}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default GlobalSearch
