import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridItem, GridStackItem } from '../gridItems'
import { dateToMMMDYYYYUTC } from '../../../../core/core'
import { IProject } from '../../../../domain/interfaces/IProjects'

export const project_columns: GridColDef[] = [
  {
    field: 'name',
    renderHeader: () => <GridItem text='Name' />,
    flex: 1.5,
    renderCell: ({ row }) => (
      <GridStackItem
        favorite={row.favorite}
        inactive={row.inactive}
        topText={row.name}
        bottomText={row.pointOfContact}
      />
    ),
  },
  {
    field: 'endDate',
    renderHeader: () => <GridItem text='End Date' />,
    flex: 1,
    renderCell: ({ row }) => (
      <GridItem text={dateToMMMDYYYYUTC(row.endDate)} inactive={row.inactive} />
    ),
  },
  {
    field: 'daysRemaining',
    renderHeader: () => <GridItem text='Days Remaining' />,
    flex: 1,
    renderCell: ({ row }) => (
      <GridItem text={row.daysRemaining} inactive={row.inactive} />
    ),
  },
]

export const normalizeProjectsForDataGrid: any = (projects?: IProject[]) => {
  if (!projects) return []

  return projects?.map((project: IProject, i) => {
    return {
      id: project.id,
      name: project.name,
      startDate: project.startDate,
      endDate: project.endDate,
      daysRemaining: project.daysRemaining ?? 'NA',
      pointOfContact: project.pointOfContact?.fullName ?? 'NA',
      favorite: project.favorited,
      status: project.active ? 'Active' : 'Inactive',
      inactive: !project.active,
      index: i,
    }
  })
}
