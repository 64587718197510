import { REACT_APP_SERVER_API } from '../../../config'
import { ErrorToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { getAuthToken, getCurrentUser } from '../../../domain/domain'
import { IForm } from '../../../domain/interfaces/IForm'
import { ISubmission } from '../../../domain/interfaces/ISubmission'

/**
 * Generates a success message based on the provided parameters.
 *
 * @param {boolean} isDraft - Whether the action is a draft or not.
 * @param {boolean} isUpdate - Whether the action is an update or not.
 * @param {string} title - The title to include in the success message.
 * @returns {string} - Returns a success message based on the given parameters.
 */
export const getSuccessMessage = (
  isDraft: boolean,
  isUpdate: boolean,
  title: string,
) => {
  let verb = 'added'
  if (isUpdate) verb = 'updated'

  let message = ''

  if (isDraft) message = title + ' draft '
  else if (!isDraft && !isUpdate) message = 'New ' + title
  else message = title

  return `${message} ${verb}.`
}

/**
 * Generates an error message based on the provided parameters.
 *
 * @param {boolean} isDraft - Whether the action is a draft or not.
 * @param {boolean} isUpdate - Whether the action is an update or not.
 * @param {string} title - The title to include in the error message.
 * @returns {string} - Returns an error message based on the given parameters.
 */
export const getErrorMessage = (
  isDraft: boolean,
  isUpdate: boolean,
  title: string,
) => {
  let verb = ''
  if (isUpdate) verb = 'updating'
  else if (isDraft) verb = 'saving'
  else verb = 'adding new'

  let message = `Error ${verb} ${title}`

  if (isDraft) message = message + 'as draft.'
  else message = message + '.'

  return message
}

export const addOrgIdAndTokenToFormComponents = (
  form: IForm,
  level1Name: string,
  projectName: string,
  orgId: string,
  readOnly = false,
) => {
  // WHAT IS GOING TO BE THE TOKEN FOR
  const token = getAuthToken()
  const currentUserId = getCurrentUser()?.id ?? ''

  let formString = JSON.stringify(form)
  // error was getting thrown when form was undefined
  if (formString) {
    formString = formString.replace(/{{data.formId}}/g, form.id!)
    formString = formString.replace(/{{serverUrl}}/g, REACT_APP_SERVER_API)
    formString = formString.replace(/{{data.orgId}}/g, orgId!)
    formString = formString.replace(/{{data.token}}/g, token!)
    formString = formString.replace(
      /{{data.dataSource.level1Name}}/g,
      level1Name,
    )
    formString = formString.replace(
      /{{data.dataSource.projectName}}/g,
      projectName,
    )
    // TEMPORARILY ADDED THESE BELOW AS SOME FORMS HAVE DIFFERENT TAGS
    formString = formString.replace(/{{data.level1Name}}/g, level1Name)
    formString = formString.replace(/{{data.projectName}}/g, projectName)
    formString = formString.replace(
      /{{data.level1URL}}/g,
      `${REACT_APP_SERVER_API}/minimal/levels`,
    )
    formString = formString.replace(
      /{{data.projectsURL}}/g,
      `${REACT_APP_SERVER_API}/minimal/projects`,
    )
    formString = formString.replace(
      /{{data.usersURL}}/g,
      `${REACT_APP_SERVER_API}/minimal/users`,
    )
    formString = formString.replace(
      /{{data.contractorsURL}}/g,
      `${REACT_APP_SERVER_API}/minimal/contractors`,
    )

    formString = formString.replace(
      /{{data.contractorContactsURL}}/g,
      `${REACT_APP_SERVER_API}/minimal/contacts`,
    )
    formString = formString.replace(
      /{{data.responsiblePartyURL}}/g,
      `${REACT_APP_SERVER_API}/minimal/responsibleParty`,
    )

    formString = formString.replace(
      /{{data.locationsURL}}/g,
      `${REACT_APP_SERVER_API}/locations`,
    )

    formString = formString.replace(
      /{{data.subLocationsURL}}/g,
      `${REACT_APP_SERVER_API}/subLocations`,
    )

    formString = formString.replace(
      /{{data.locationsParams}}/g,
      'projectId={{data.project}}',
    )

    formString = formString.replace(/{{data.subLocationsParams}}/g, readOnly ? 'includeDeleted=true&ignoreFences=true' : '')

    formString = formString.replace(/{{data.contractorsParams}}/g, readOnly ? 'includeDeleted=true&ignoreFences=true' : '')
    formString = formString.replace(
      /{{data.contractorContactsParams}}/g,
      readOnly ? 'contractorId={{row.responsibleParty.id}}&includeDeleted=true&ignoreFences=true' :
        'contractorId={{row.responsibleParty.id}}&firstProjectId={{data.project}}',

    )

    //HERE with ID
    // IGNORE USING ID FOR LEVEL GRABS ALL PROJECTS
    formString = formString.replace(
      /{{data.projectsParams}}/g,
      readOnly ? 'levelId={{data.level1}}&includeDeleted=true&ignoreFences=true' :
        'levelId={{data.level1}}',
    )

    // formString = formString.replace(
    //   /{{data.projectsParams}}/g,
    //   readOnly ? 'includeDeleted=true' :
    //     ''
    // )
    formString = formString.replace(/{{data.level1Params}}/g, readOnly ? 'includeDeleted=true&ignoreFences=true' : '')
    formString = formString.replace(/{{data.usersParams}}/g, readOnly ? 'includeDeleted=true&ignoreFences=true' : '')

    formString = formString.replace(/{{data.responsiblePartyParams}}/g, readOnly ? 'includeDeleted=true&ignoreFences=true' : '')

    // from latest postsubmission merge.
    formString = formString.replace(
      /{{data.signaturesURL}}/g,
      `${REACT_APP_SERVER_API}/minimal/globalSearch`,
    )
    formString = formString.replace(
      /{{data.signaturesParams}}/g,
      readOnly ? `entities=users,projects,levels&orgId=${orgId}&includeDeleted=true&ignoreFences=true` :
        `entities=users,projects,levels&orgId=${orgId}`,
    )
    formString = formString.replace(
      /{{data.userAutofillSelf}}/g, currentUserId
    )


    return JSON.parse(formString)
  }
}

export const preSubmissionChecks = (
  formioForm: any,
  submissionPayload: ISubmission,
) => {
  if (!formioForm) {
    ErrorToast({
      title: 'Form not loaded',
    })
    return false
  }
  const isValid = formioForm.checkValidity(false, true, true, false)
  if (!isValid) {
    ErrorToast({
      title: 'Please fill out all required fields',
    })
    return false
  }

  if (!submissionPayload) {
    ErrorToast({
      title: 'No Change Made',
    })
    return false
  }
  return true
}
