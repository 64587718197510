import { Box } from '@mui/material'
import React from 'react'
import { ETypography } from '../../components/fonts/ETypography'
import BasicSubmissionModal from './BasicSubmissionModal'

export interface ILinkedFormsRendererProps {
  linkFormsState: any[]
}

const LinkedFormsRenderer: React.FC<ILinkedFormsRendererProps> = ({
  linkFormsState,
}) => {
  const [submissionOpen, setSubmissionOpen] = React.useState(false)
  const [selectedSubmission, setSelectedSubmission] = React.useState<{
    orgId: string;
    formId: string;
    subId: string;
    form: any;
    formReadOnly: any;
  } | null>(null)

  const handleSubmissionClick = (linkFormState: any, submission: any) => {
    setSelectedSubmission({
      orgId: linkFormState.orgId,
      formId: linkFormState.formId,
      subId: submission.id,
      form: linkFormState.formReadOnly,
      formReadOnly: linkFormState.formReadOnly,
    })
    setSubmissionOpen(true)
  }

  const handleClose = () => {
    setSubmissionOpen(false)
    setSelectedSubmission(null)
  }

  const renderSubmissions = (linkFormState: any) => {
    return linkFormState.submissions.map((submission: any, index: number) => (
      <ETypography
        key={submission.id}
        font='MR'
        color='primary600'
        sx={{ display: 'inline', '&:hover': { cursor: 'pointer' } }}
        onClick={() => handleSubmissionClick(linkFormState, submission)}
      >
        {linkFormState.title}
        {index === linkFormState.submissions.length - 1 ? '' : ', '}
      </ETypography>
    ))
  }

  if (!linkFormsState || linkFormsState.length === 0) return null

  return (
    <Box sx={{ marginTop: '-14px', marginLeft: '-0px', marginBottom: '14px' }}>
      {selectedSubmission && (
        <BasicSubmissionModal
          key={selectedSubmission.subId}
          open={submissionOpen}
          handleClose={handleClose}
          orgId={selectedSubmission.orgId}
          formId={selectedSubmission.formId}
          subId={selectedSubmission.subId}
          form={selectedSubmission.form}
          formReadOnly={selectedSubmission.formReadOnly}
        />
      )}
      {linkFormsState.map((linkFormState, index) => (
        <Box key={index} sx={{ display: 'block' }}>
          {renderSubmissions(linkFormState)}
        </Box>
      ))}
    </Box>
  )
}

export default LinkedFormsRenderer
