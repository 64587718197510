
import { el } from 'date-fns/locale'
import Moment from 'moment'
import momentTZ from 'moment-timezone'

/**
 * Formats [date] to "MMM d YYYY hh:mm a" format.
 *
 * @param date
 * @returns formatted Date format.
 */
export const dateToMMMDYYYYHHMMA = (date: Date | string): string => {
  if (date instanceof Date) return Moment(date).format('MMM d, YYYY hh:mm a')
  else return Moment(date).format('lll').toString()
}

/**
 * Formats [date] to "MMM d YYYY format.
 *
 * @param date
 * @returns formatted Date format.
 */
export const dateToMMMDYYYY = (date: Date | string): string => {
  return Moment(date).format('ll').toString()
}
export const dateToMMMDYYYYUTC = (date: Date | string): string => {
  return Moment(date).utc().format('ll').toString()
}
export const dateTodayMMMDYYYYatTime = (date: Date | string): string => {
  return Moment(date).format('dddd, MMMM D, YYYY [at] h:mmA').toString()
}



export function dateToMMDDYY(dateString: string): string {
  // Parse the input date string using Moment.js
  const date = Moment(dateString)

  // Check if the date is valid
  if (!date.isValid()) {
    return 'Invalid date'
  }

  // Format the date as 'MM/DD/YY'
  return date.format('MM/DD/YY')
}




export function getTimeAgo(dateString: string, noDateMessage = 'No date'): string {
  if (!dateString) {
    return noDateMessage
  }
  const date = new Date(dateString)
  const now = new Date()

  const elapsedMilliseconds = now.getTime() - date.getTime()
  const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000)
  const elapsedMinutes = Math.floor(elapsedSeconds / 60)
  const elapsedHours = Math.floor(elapsedMinutes / 60)
  const elapsedDays = Math.floor(elapsedHours / 24)
  const elapsedMonths = Math.floor(elapsedDays / 30)

  if (elapsedMonths > 0) {
    return `${elapsedMonths} month${elapsedMonths > 1 ? 's' : ''} ago`
  } else if (elapsedDays > 0) {
    return `${elapsedDays} day${elapsedDays > 1 ? 's' : ''} ago`
  } else if (elapsedHours > 0) {
    return `${elapsedHours} hour${elapsedHours > 1 ? 's' : ''} ago`
  } else if (elapsedMinutes > 0) {
    return `${elapsedMinutes} minute${elapsedMinutes > 1 ? 's' : ''} ago`
  } else {
    return 'Just now'
  }
}

export function getDaysLeft(dateString: string, noDateMessage = 'No date'): string {
  if (!dateString) {
    return noDateMessage
  }
  const now = new Date()
  const futureDate = new Date(dateString)

  const elapsedMilliseconds = futureDate.getTime() - now.getTime()
  const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000)
  const elapsedMinutes = Math.floor(elapsedSeconds / 60)
  const elapsedHours = Math.floor(elapsedMinutes / 60)
  let elapsedDays = Math.floor(elapsedHours / 24) + 1
  if (elapsedDays < 0) {
    elapsedDays = 0
  }

  return `${elapsedDays} Day${elapsedDays > 1 || elapsedDays == 0 ? 's' : ''} left`

}

export function getDaysLeftNumber(dateString: string): number {

  const now = new Date()
  const futureDate = new Date(dateString)

  const elapsedMilliseconds = futureDate.getTime() - now.getTime()
  const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000)
  const elapsedMinutes = Math.floor(elapsedSeconds / 60)
  const elapsedHours = Math.floor(elapsedMinutes / 60)
  const elapsedDays = Math.floor(elapsedHours / 24) + 1


  return elapsedDays

}



export const formatTimestampMDYYYYathmmaz = (timestamp: string | undefined): string => {
  if (timestamp == undefined) return 'no date'
  const date = Moment(timestamp)
  const formattedDate = date.format('M.D.YYYY [at] h:mma z')
  return formattedDate
}

export const formatTimestampYYYYMMDDhmmaz = (timestamp: string | undefined): string => {
  if (timestamp == undefined) return 'no date'
  const date = Moment(timestamp)
  const formattedDate = date.format('YYYY-MM-DD h:mma z')
  return formattedDate
}



export const getTimeZone = (): string => {
  const ANAtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return momentTZ.tz(ANAtimezone).format('z')
}



