import React from 'react'
import { Box, SxProps, Typography } from '@mui/material'
import { AppColors, AppFonts } from '../../../Theme'
import EdifyTooltip from '../../button-dropdown/EdifyTooltip'

interface IFieldlabelProps {
  label: string
  disabled?: boolean
  tooltipText?: string
  required?: boolean
  upperCaseSemibold?: boolean
  leftActionButton?: React.ReactElement
  sx?: SxProps
}

export const EdifyFieldLabel: React.FC<IFieldlabelProps> = ({
  label,
  disabled,
  tooltipText,
  required = false,
  upperCaseSemibold = true,
  leftActionButton = null,
  sx
}) => {
  return (
    <Box sx={{ display: 'flex', ...sx }}>
      <Typography
        className={`formio-component-panel card-title ${
          required && 'sm-label-required'
        }`}
        //TODO: try to match with just the class name so when it updates so will this.
        sx={{
          ...upperCaseSemibold ? AppFonts.upperCaseSemibold : AppFonts.textLMedium,
          color: disabled ? AppColors.neutral800 : AppColors.gray700,
          marginBottom: '16px',

        }}
      >
        {label}
      </Typography>
      {tooltipText && (
        <EdifyTooltip sx={{ marginLeft: '16px' }} text={tooltipText} />
      )}
      {leftActionButton && leftActionButton}
    </Box>
  )
}
export default EdifyFieldLabel
