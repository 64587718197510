import { ILevel, IProject } from './IProjects'
import { IRole } from './IRole'
import { IUser } from './IUser'

export enum EGoalIncentive {
  BRAGGING_RIGHTS = 'Bragging Rights',
  SALARY_BONUS = 'Salary Bonus',
  TEAM_LUNCH = 'Team Lunch',
  SWAG = 'Branded Apparel',
}
export enum EGoalStatus {
  SCHEDULED = 'scheduled',
  ACTIVE = 'active',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  COMPLETE = 'complete',
}

export enum ETrackingPeriod {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
}

export enum EGoalReminder {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  ONE_DAY_BEFORE_ENDING = 'One Day Before Ending',
  NO_REMINDER = 'No Reminder',
}

export enum EGoalDuration {
  WEEKLY = 'Ends in a Week',
  MONTHLY = 'Ends in a Month',
  QUARTERLY = 'Ends in a Quarter',
  NONE = 'No End Date',
}
export interface ETemplate {
  id: string
  name: string
  description: string
}

export const TEMPLATES: ETemplate[] = [
  {
    id: 'bf099928-50b0-4be3-8c95-e319a49081cb',
    name: 'Form Completion',
    description:
      'Increase situational safety awareness in and around the job site',
  },
  {
    id: '8722b3a8-6df2-49f8-a33f-fcf1e5d65d78',
    name: 'Daily Login',
    description:
      'Make checking for organization updates and safety tips a habit',
  },
]
export interface IGoalFormData {
  name: string
  description: string
  templateId: string
  formId?: string | null
  startDate: string
  goalCount: number
  trackingPeriod: ETrackingPeriod | string
  selectedDaysOfWeek?: string[]
  duration: EGoalDuration | string
  incentive: EGoalIncentive | string
  watchersReminder: EGoalReminder | string
  participantsReminder: EGoalReminder | string
  participantsReminderTime: string
  state: EGoalStatus
  watcherIds: string[]
  userParticipantIds: string[]
  divisionParticipantIds: string[]
  projectParticipantIds: string[]
  roleIds: string[]

}
interface Watcher {
  id: string
  fullName: string
  email: string
  phone: string
  // need role.
}

export interface IGoal {
  id: string
  orgId: string
  userId: string
  ownerName?: string
  templateName?: 'Form Completion' | 'Daily Login'
  goalCount?: number | string
  templateId?: string
  formId?: string
  goalName: string
  name: string
  description: string
  startDate: string
  trackingPeriod: ETrackingPeriod
  selectedDaysOfWeek: string[]
  duration: EGoalDuration
  incentive: EGoalIncentive
  watchersReminder: EGoalReminder
  participantsReminder: EGoalReminder
  participantsReminderTime: string
  status: EGoalStatus
  state: string
  // EXTRA FROM DANS
  form: 'QPR'
  submissions: string | number
  totalSubmissions: string | number
  participantCount?: string | number
  participantsCount?: string | number
  daysRemaining: string | number
  creatorName: string
  watchers: Watcher[]
  levels: ILevel[]
  users: IUser[]
  projects: IProject[]
  roles: IRole[]
  totalPossibleActivities: number
  totalActivities: number
}
export interface IGoalListItem {
  id: string
  name: string
  creatorName: string
  ownerName: string
  templateName: string
  incentive: EGoalIncentive
  status: EGoalStatus
  // Participants not sure what this will be string | string[]
  // daysLeft not sure number | number[]
}

export interface IGoalList {
  data: IGoalListItem[]
  total_count: number
  message: string
}
export interface IParticipantsList {
  data: IParticipant[]
  total_count: number
  message: string
}
export interface IParticipant {
  id: string
  fullName: string
  email: string
  streak: string | number
  totalActivities: number
  paused: boolean
  phone: string
  state: string
}
export interface IGoalsQueryField {
  limit?: number
  skip?: number
  tab?: string
  // not sure what this is called but will be the search term
  goal?: string
}

export interface IParticipantsQueryField {
  limit?: number
  skip?: number
}
