import React, { useState } from 'react'
import { EdifyButton } from '../../components/buttons'
import { Box } from '@mui/material'
import FilterButtonGroup from '../../components/buttons/filter-button-group/FilterButtonGroup'
import { GoalsPageViewModelProps } from './GoalsPageViewModel'
import { useNavigate } from 'react-router-dom'
import { useTableWidth } from '../../../core/hooks'
import FilterContainer from '../../components/page/FilterContainer'
import { IGoalsQueryField } from '../../../domain/interfaces/IGoal'
import { ROUTE_GOALS } from './GoalsPage'
import GlobalSearch from '../../components/form/EdifySearch/GlobalSearch'
import { usePublicOrganizationProvider } from '../../../providers/PublicOrganizationProvider'

interface IGoalsFilterProps {
  vm: GoalsPageViewModelProps
  buttonTitle: string
  canCreate: boolean
}

const FILTER_OPTIONS = ['All', 'Active', 'Complete', 'Canceled']

const GoalsFilter: React.FC<IGoalsFilterProps> = ({
  vm,
  buttonTitle,
  canCreate,
}) => {
  const navigate = useNavigate()
  const { query } = vm.queryHelper
  const orgVm = usePublicOrganizationProvider()
  const l1Name = orgVm.level1Name
  const projectName = orgVm.level0Name
  const initButtonValue = query.state
    ? query.state.charAt(0).toUpperCase() + query.state.slice(1)
    : 'All'
  const [activeButton, setActiveButton] = useState<string>(initButtonValue)
  const [isGlobalClearing, setIsGlobalClearing] = React.useState<boolean>(false)
  const width = useTableWidth(true)

  const handleFilterButtonClicked = (button: string) => {
    const newGoalsQueryField: IGoalsQueryField = {
      ...query,
      state: button.toLocaleLowerCase(),
    }
    vm.fetchGoals(0, newGoalsQueryField)
  }

  const onGlobalSearchSelect = (selectedId: string, selectedValue: string) => {
    const newQuery = { ...query }
    delete newQuery.searchKey
    vm.fetchGoals(0, {
      ...newQuery,
      filterId: selectedId,
      filterValue: selectedValue,
    })
  }

  // for the X in the global search
  const onGlobalSearchClear = async () => {
    const newQuery = { ...query }
    delete newQuery.searchKey
    delete newQuery.filterId
    delete newQuery.filterValue
    setIsGlobalClearing(true)
    await vm.fetchGoals(0, {
      ...newQuery,
    })
    setIsGlobalClearing(false)
  }

  const onGlobalSearchKey = (key: string) => {
    vm.fetchGoals(0, {
      ...query,
      searchKey: key,
    })
  }

  return (
    <>
      <FilterContainer data-testid={'GoalsFilter'}>
        <FilterButtonGroup
          onButtonClicked={handleFilterButtonClicked}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          buttons={[...FILTER_OPTIONS]}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <GlobalSearch
            globalClear={isGlobalClearing}
            sx={{
              '& .MuiOutlinedInput-root': {
                width: '300px',
                padding: '6px 0px 6px 9px !important',
                paddingRight: '9px !important',
              },
            }}
            entities='users,projects,levels'
            // placeholder={`Author, ${l1Name}, ${projectName}`}
            placeholder={'Search'}
            {...(query.searchKey ? { defaultValue: query.searchKey } : {})}
            {...(query.filterValue ? { defaultValue: query.filterValue } : {})}
            showBadge
            l1Name={l1Name}
            projectName={projectName}
            onGlobalSearchSelect={onGlobalSearchSelect}
            onGlobalSearchClear={onGlobalSearchClear}
            onGlobalSearchWithText={onGlobalSearchKey}
          />

          {canCreate && (
            <EdifyButton
              onClick={() => navigate(`${ROUTE_GOALS}/new`)}
              buttonStyle={{ marginLeft: '8px' }}
              title={width < 856 ? 'New' : `Create New ${buttonTitle}`}
              primary
            />
          )}
        </Box>
      </FilterContainer>
    </>
  )
}

export default GoalsFilter
