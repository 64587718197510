import { getCurrentPerson, getCurrentUser } from '../../domain/domain'
import { IRole, ERole } from '../../domain/interfaces/IRole'
import { useGlobalProvider } from '../../providers/GlobalProvider'

const usePermissionOld = (role: keyof IRole, byPassGlobalCheck = false) => {
  const currentUser = getCurrentUser()
  const person = getCurrentPerson()
  const vm = useGlobalProvider()
  const isGlobalUserView  = vm ? vm.isGlobalUserView : false 

  // What should globalRole have permission to do?
  if (!byPassGlobalCheck && isGlobalUserView && person?.globalRole) {
    if (person?.globalRole[role] == 1) {
      return true
    }
  }
  if (!currentUser || !currentUser.roles || !currentUser.roles[0]) {
    return false
  }

  const roles: IRole = currentUser.roles[0]
  return roles[role] == 1 ? true : false
}

const usePermission = (
  action: ERole,
  checkGlobal = false,
  idToCheck?: string,
) => {  
  const currentUser = getCurrentUser()
  const person = getCurrentPerson()
  const vm = useGlobalProvider()
  const isGlobalUserView  = vm ? vm.isGlobalUserView : false 
  if (!currentUser || !currentUser.roles || !currentUser.roles[0]) return false

  const role: IRole = currentUser.roles[0]

  // Check if the action is a feature access check
  if (action.endsWith('FeatureAccess')) {
    return role[action] === 1 // Feature access is TRUE if 1, FALSE if 0
  }

  // Helper function to check permission level
  const checkPermissionLevel = (level: number): boolean => {
    switch (level) {
      case 2:
        return true // FULL permission
      case 1: // LIMITED permission
        if (
          idToCheck !== undefined &&
          [
            'submissionEdit',
            'correctiveActionEdit',
            'userEdit',
            'userCreate',
            'goalEdit',
            'goalRead',
            'correctiveActionComment',
            'correctiveActionChangeState',
            'correctiveActionArchive',          
            'locationEdit',
            'projectEdit',
            'projectRead',
            'levelEdit',
            'levelRead',
            'locationRead',
            'trainingCertificateEdit',
            'trainingCertificateCreate',
            'trainingCertificateRead',  
          ].includes(action)
        ) {
          return currentUser.id === idToCheck
        }
        return true
      case 0:
        return false
      default:
        return false
    }
  }

  // Check global user view permission
  if (checkGlobal && isGlobalUserView && person?.globalRole) {
    const globalPermission = person.globalRole[action]
    if (checkPermissionLevel(globalPermission)) return true
  }
  // For other permissions, use the checkPermissionLevel function
  return checkPermissionLevel(role[action])
}

export default usePermission
