import React, { useState } from 'react'
import { Box } from '@mui/material'
import { EdifyNavViewModel } from './EdifyNavViewModel'

import { useNavigate } from 'react-router-dom'

import EdifySearchDropdown, {
  ISearchResult,
} from '../../form/EdifySearch/EdifySearchDropdown'
import { EdifySelect } from '../../form'
import EdifyMenuItem from '../../form/EdifyMenuItem'
import { useOrganizationProvider } from '../../../../providers/OrganizationProvider'
import usePermission from '../../../../core/hooks/usePermission'
import { ERole } from '../../../../domain/interfaces/IRole'
import { searchGlobalMinimal } from '../../../../data/repositories/utils/UtilsRepository'
import { goalsSearch } from '../../../../data/repositories/goals/GoalsRepository'

enum DropDownOptions {
  ALL = 'All',
  PROJECTS = 'Projects',
  DIVISIONS = 'Divisions',
  CONTRACTORS = 'Contractors',
  USERS = 'Users',
  LEVELS = 'Levels',
  EQUIPMENT = 'Equipment',
  GOAL_CONTROL = 'Goals & Control',
  FORMS = 'Forms',
}

// Will add more as the become available

export const EdifyLinkSearch: React.FC = () => {
  const { isContractor } = useOrganizationProvider()


  if (isContractor) return null
  return (
    <EdifyNavViewModel>
      <_Page />
    </EdifyNavViewModel>
  )
}

export const _Page: React.FC = () => {
  const { getHierarchyName } = useOrganizationProvider()
  const canViewProjects = usePermission(ERole.ProjectRead)
  const canViewContractors = usePermission(ERole.ContractorRead)
  const canViewForms = usePermission(ERole.SubmissionRead)
  const canViewLevel = usePermission(ERole.LevelRead)
  const canViewProject = usePermission(ERole.ProjectRead)
  const canViewGoal = usePermission(ERole.GoalRead)
  const canViewUsers = usePermission(ERole.UserRead)
  const dropDownItems = []

  if (canViewProjects) dropDownItems.push(DropDownOptions.PROJECTS)
  if (canViewContractors) dropDownItems.push(DropDownOptions.CONTRACTORS)
  if (canViewForms) dropDownItems.push(DropDownOptions.FORMS)
  if (canViewLevel) dropDownItems.push(DropDownOptions.DIVISIONS)
  if (canViewProject) dropDownItems.push(DropDownOptions.PROJECTS)
  if (canViewGoal) dropDownItems.push(DropDownOptions.GOAL_CONTROL)
  if(canViewUsers) dropDownItems.push(DropDownOptions.USERS)
  function getUrl(selectedOption: string) {
    switch (selectedOption) {
      case 'divisions':
        return 'levels/1'
      case 'forms':
        return 'forms/submissions'
      case 'users':
        return 'users'
      case 'goals':
        return 'goals'
      default:
        return selectedOption
    }
  }

  const handleSelect = (searchItems: ISearchResult[]) => {
    if (searchItems.length == 0) return
    const url = getUrl(selectedOption)
    navigate(`/${url}/${searchItems[0].id}`)
  }

  const [selectedOption, setSelectedOption] = useState('projects')

  const handleChange = (event: any) => {
    if(event.target.value == 'goals & control'){
      setSelectedOption('goals')
      return
    }

    setSelectedOption(event.target.value)
  }

  const getSearchFunction = () => {
    return (searchString:string) => searchGlobalMinimal(selectedOption, searchString )

  }
  const getNameKey = () => {
    switch (selectedOption) {
      case 'users':
        return 'fullName'
      case 'contractors':
        // do we want contacts also?
        return 'name'
      // case 'goal & control':
      //   return 'searchKey'
      default:
        return 'name'
    }
  }

  const swapLevelName = (filter: string) => {
    if (filter == DropDownOptions.PROJECTS) {
      return getHierarchyName(0, true)
    }
    if (filter == DropDownOptions.DIVISIONS) {
      return getHierarchyName(1, true)
    }
    return filter
  }

  const navigate = useNavigate()


  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <EdifySelect
        small
        onChange={handleChange}
        darkMode
        width='135px'
        defaultValue='projects'
        sx={{ marginRight: { md: '10px', lg: '24px' } }}
        dropDownItems={dropDownItems ??[]}
        renderMenuItem={(item: string) => (
          <EdifyMenuItem darkMode key={item} value={item.toLowerCase()}>
            {swapLevelName(item)}
          </EdifyMenuItem>
        )}
      />
      <EdifySearchDropdown
        small
        darkMode
        // width={'100%'}
        sx={{ width: { md: '155px', lg: '240px' } }}
        onSelect={handleSelect}
        searchFunction={getSearchFunction()}
        limit={
          selectedOption === 'users' || selectedOption === 'contractors'
            ? 8
            : undefined
        }
        initialSelectedItems={[]}
        nameKey={getNameKey()}
      />
    </Box>
  )
}
