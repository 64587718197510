import { Box, Divider, TextareaAutosize } from '@mui/material'
import React, { useState } from 'react'
import { EdifyFieldLabel } from '../../form'
import { EdifyFormField } from '../../form/shared/EdifyFormField'
import { EdifyButton } from '../../buttons'
import { useNavigate } from 'react-router-dom'
import EdifyImageUploader from '../../form/EdifyImage/EdifyImageUploader'
import {
  ErrorToast,
  SuccessToast,
} from '../../../../core/utils/toast-notifications/ToastNotifications'
import {
  addCommentOnCorrectiveAction,
  addPublicCommentOnCorrectiveAction,
  closeCorrectiveAction,
  personAddCommentOnCorrectiveAction,
  sendBackCorrectiveAction,
} from '../../../../domain/domain'
import { useForm } from 'react-hook-form'
import FormErrorText from '../../form/FormErrorText'
import { ICorrectiveAction } from '../../../../domain/interfaces/ICorrectiveAction'
import ErrorContainer from '../../error/ErrorContainer'
import {
  ECorrectiveActionViewMode,
  ICorrectiveActionPageViewModelProps,
  useCorrectiveActionPageViewModel,
} from '../../../pages/corrective-action/CorrectiveActionPageViewModel'
import { AppColors } from '../../../Theme'
import EdifyAttachmentViewer from '../../form/EdifyImage/EdifyImageViewer'
import { EnhancedFileUpload } from '../../../pages/user/EnhancedFileUpload'

export type Inputs = {
  notes: string
}
interface ICorrectiveActionFormV2 extends ICorrectiveAction {
  contractorView?: boolean
  description?: string
  setCorrectiveAction: (correctiveAction: ICorrectiveAction) => void
  viewMode: ECorrectiveActionViewMode
  apiCall: string //Make a enum for this
  closeForm?: () => void
  isContractor: boolean
}

const CorrectiveActionFormV2: React.FC<ICorrectiveActionFormV2> = ({
  setCorrectiveAction,
  apiCall,
  id,
  closeForm,
  isContractor,
}) => {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [submissionError, setSubmissionError] = useState<string | undefined>(
    undefined,
  )

  const caVM: ICorrectiveActionPageViewModelProps =
    useCorrectiveActionPageViewModel()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const handleFormSubmit = async (data: Inputs) => {
    setSubmitting(true)
    const formData = {
      notes: data.notes,
      images: imageUrls,
    }
    let res, errorMessage, successMessage
    if (apiCall === 'comment') {
      if (caVM.isPublicCA) {
        res = await addPublicCommentOnCorrectiveAction(caVM.hash, formData)
      } else if (caVM.isContractor) {
        res = await personAddCommentOnCorrectiveAction(id!, formData)
      } else {
        res = await addCommentOnCorrectiveAction(id!, formData)
      }

      errorMessage = 'Error!  Unable to send comment.'
      successMessage = 'Success!  Comment has been sent'
    } else if (apiCall == 'close') {
      res = await closeCorrectiveAction(id!, formData)
      errorMessage = 'Error! Unable to close corrective action.'
      successMessage = 'Success! Corrective action closed.'
    } else {
      res = await sendBackCorrectiveAction(id!, formData)
      errorMessage = 'Error!  Unable to send back corrective action.'
      successMessage = 'Success!  Corrective action has been sent back.'
    }
    if (res.isLeft()) {
      ErrorToast({ title: errorMessage })
      setSubmissionError((res.left?.message as string) ?? 'Error occurred')
      setSubmitting(false)
      return
    }
    SuccessToast({ title: successMessage })
    setCorrectiveAction(res.right as ICorrectiveAction)

    caVM.reloadCorrectiveAction()
    caVM.loadCorrectiveActionComments()
    // setSubmitting(false)
    // setCorrectiveActionComments(res.right as ICorrectiveAction)
    closeForm && closeForm()
  }

  const deleteAttachment = (url: string) => {
    // remove from array
    const newAttachments = imageUrls.filter((x) => x !== url)
    setImageUrls(newAttachments)
    setSubmitting(false)
  }

  //TODO: handle error, leaving this for now for testing
  // if it errors upload a default image
  const imageUploaded = (urls: string[]) => {
    setSubmitting(false)
    if (!urls) {
      ErrorToast({ title: 'Error uploading photo.' })
      // For testing just remove this
      urls = [
        'https://ih1.redbubble.net/image.2469972999.0130/poster,504x498,f8f8f8-pad,600x600,f8f8f8.jpg',
      ]
      return
    }
    if (Array.isArray(urls)) {
      setImageUrls([...imageUrls, ...urls])
    } else {
      setImageUrls([...imageUrls, urls])
    }
  }
  const imageError = (error: any) => {
    setSubmitting(false)
    setSubmissionError(error.message ?? 'Error uploading photo.')
  }
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {submissionError && <ErrorContainer error={submissionError} />}
      <hr
        style={{
          marginTop: '24px',
          marginBottom: '24px',
          borderTop: `1px solid ${AppColors.neutral600}`,
        }}
      />
      <EdifyFormField>
        <EdifyFieldLabel label='UPLOAD FILES' />
        {imageUrls.map((attachment, i: number) => {
          return (
            <>
              <EdifyAttachmentViewer
                key={attachment}
                name={'File ' + (i + 1)}
                attachment={attachment}
                onDelete={() => deleteAttachment(attachment)}

              />

              <Box sx={{ mb: '24px' }} />
              {/* <EnhancedFileUpload
                handleUpload={handleUpload}
                loading={loadingCSV}
                error={error}
                setError={setError}
                allowedFileTypes={['csv']}
              /> */}
            </>
          )
          // TODO: Check if other pdf, doc, etc?
        })}
        <EdifyImageUploader
          edifyUI
          singleFile={true}
          resetFilesOnSuccess
          handleLoad={imageUploaded}
          uploadUrl={caVM.isPublicCA ? '/fileUploads/public/CA' : isContractor ? '/fileUploads/forContractors/CA' : '/fileUploads/CA'}
          dataKey='anyFile'
          acceptedFileTypes={'All'}
          handleOnAddStart={() => setSubmitting(true)}
          params={`?correctiveActionId=${caVM.correctiveAction?.id}&hash=${caVM.hash}`}
        />
        {/* <EdifyImageUploader
          edifyUI
          singleFile={true}
          resetFilesOnSuccess
          handleLoad={imageUploaded}
          uploadUrl={'/fileUploads/TC'}
          acceptedFileTypes={['image/*', 'application/pdf']}
          dataKey='anyFile'
          // decodeResponse={(object: any) => object.data.downloadableUrl}
          handleOnAddStart={() => setSubmitting(true)}
        /> */}
        {/* Current On Main Allows Multiple image uploads */}
        {/* <EdifyImageUploader
          returnArray
          uploadUrl={'/fileUploads/CA'}
          handleOnError={imageError}
          handleLoad={imageUploaded}
          onFilesProcessed={(files: any) => {
            setSubmitting(false)
          }}
          handleOnAddStart={() => setSubmitting(true)}
        /> */}
      </EdifyFormField>
      <EdifyFormField>
        <EdifyFieldLabel label='Notes' />
        <TextareaAutosize
          {...register('notes', { required: true })}
          data-testid='Notes'
          placeholder='Tell us about what happened'
          className={'form-control'}
          minRows={3}
        />
      </EdifyFormField>
      {errors.notes && <FormErrorText>This field is required</FormErrorText>}
      <Box
        style={{
          marginTop: '24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <EdifyButton
          onClick={() => {
            if (apiCall === 'contractor') {
              navigate(-1)
            } else {
              {
                closeForm && closeForm()
              }
            }
          }}
          primary
          noBackground
          title={apiCall === 'contractor' ? 'Go Back' : 'Cancel'}
        />
        <EdifyButton
          loading={submitting}
          disabled={submitting}
          type='submit'
          primary
          title='Submit'
        />
      </Box>
    </form>
  )
}
export default CorrectiveActionFormV2
