import { FormControl, Input, InputAdornment, InputProps } from '@mui/material'
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { AppColors } from '../../../Theme'
import { Search } from '@mui/icons-material'
import { GridClearIcon } from '@mui/x-data-grid'
import { debounce } from 'lodash'

interface IEdifySearchProps extends InputProps {
  defaultValue?: string
  darkMode?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  width?: number | string
  small?: boolean
  clear?: () => void
  debounceTime?: number
}

export const EdifySearch: React.FC<IEdifySearchProps> = ({
  onChange,
  sx,
  defaultValue = '',
  darkMode = false,
  disabled = false,
  width = 'auto',
  small = false,
  placeholder = 'Search',
  debounceTime = 300, 
  clear
}) => {
  const [inputValue, setInputValue] = useState<string>(defaultValue)
  
  // Use a ref to track the latest value for the debounced function
  const latestValueRef = useRef<string>(defaultValue)
  
  // Create a debounced function 
  const debouncedSearch = useCallback(
    debounce(() => {
      // Use the ref value to ensure we always get the latest
      const currentValue = latestValueRef.current
      
      const syntheticEvent = {
        target: { value: currentValue },
      } as ChangeEvent<HTMLInputElement>
      
      onChange(syntheticEvent)
    }, debounceTime),
    [onChange, debounceTime]
  )
  
  // Handle blur event to ensure the last value is sent
  const handleBlur = () => {
    // When input loses focus, immediately flush any pending debounced search
    debouncedSearch.flush()
  }
  
  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      // Use flush instead of cancel to ensure any pending value is sent
      debouncedSearch.flush()
    }
  }, [debouncedSearch])
  
  // Handle input changes
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    
    // Update the UI immediately
    setInputValue(newValue)
    
    // Update the ref with the latest value
    latestValueRef.current = newValue
    
    // Trigger the debounced search (which will use the ref value when it executes)
    debouncedSearch()
  }
  
  // Handle clear button click
  const handleClear = () => {
    // Update UI immediately
    setInputValue('')
    
    // Update the ref
    latestValueRef.current = ''
    
    // Cancel any pending searches
    debouncedSearch.cancel()
    
    // Immediately trigger search with empty string
    const syntheticEvent = {
      target: { value: '' },
    } as ChangeEvent<HTMLInputElement>
    onChange(syntheticEvent)
    
    if (clear) clear()
  }
  
  return (
    <FormControl
      disabled={disabled}
      variant='standard'
      className='e-input'
      sx={{
        padding: small ? '5px 10px' : '8px 0px 8px 16px',
        border: `1px solid ${darkMode ? 'none' : AppColors.neutral600}`,
        borderRadius: '5px',
        backgroundColor: darkMode ? AppColors.gray900 : AppColors.baseWhite,
        width: width,
        gap: '12px',
        ...sx,
      }}
    >
      <Input
        className='e-input'
        disableUnderline
        placeholder={placeholder}
        value={inputValue}
        onChange={handleSearch}
        onBlur={handleBlur}
        startAdornment={
          <InputAdornment position='start'>
            <Search sx={{ color: AppColors.neutral950 }} />
          </InputAdornment>
        }
        endAdornment={
          clear && !inputValue.length ? null : (
            <InputAdornment
              position='start'
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <GridClearIcon  
                onClick={handleClear}
                sx={{
                  width: '20px',
                  height: '20px',
                  color: AppColors.neutral950,
                  marginLeft: small ? '10px' : '16px',
                }}
              />
            </InputAdornment>
          )
        }
      />
    </FormControl>
  )
}