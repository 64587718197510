import { Box, Button, Grid, Input } from '@mui/material'
import React, { useState } from 'react'
import { EdifyFieldLabel, EdifySelect } from '../../form'
import { EdifyFormField } from '../../form/shared/EdifyFormField'
import { EdifyButton } from '../../buttons'
import InnerPageContainer from '../../inner-page-container/InnerPageContainer'
import { EParagraph, ETypography } from '../../fonts/ETypography'
import EdifyAttachmentViewer from '../../form/EdifyImage/EdifyImageViewer'
import { AppColors } from '../../../Theme'
import {
  ECorrectiveActionStatus,
  ICorrectiveActionImage,
} from '../../../../domain/interfaces/ICorrectiveAction'
import {
  ECorrectiveActionViewMode,
  ICorrectiveActionPageViewModelProps,
  useCorrectiveActionPageViewModel,
} from '../../../pages/corrective-action/CorrectiveActionPageViewModel'
import { dateToMMMDYYYY } from '../../../../core/core'
import parse from 'html-react-parser'
import { LevelBadge } from '../../../pages/location/Node1'
import { RightArrowIcon } from '../../../../core/components/icons/RightArrowIcon'
import { set } from 'lodash'
import EdifyMenuItem from '../../form/EdifyMenuItem'
import { IMinimalCommon } from '../../../../domain/interfaces/IContractor'
import usePermission from '../../../../core/hooks/usePermission'
import { ERole } from '../../../../domain/interfaces/IRole'

interface ICorrectiveActionComment {
  viewMode?: ECorrectiveActionViewMode
  title?: string
  subTitle?: string
  subSubTitle?: string
  answer?: string
  comment: string
  images: ICorrectiveActionImage[]
  contractorName?: string
  dueDate?: string | null
  children?: React.ReactNode
  initialCA?: boolean
  status?: string
  contactPersonName?: string
  responsiblePartyName?: string
  additionalContactInfo?: string
  subLocations?: (string | undefined)[]
  location?: string | undefined
  locationHierarchies?: any
  locationId?: string | undefined
  isLocationEnabled?: boolean
  isSubLocationEnabled?: boolean
}

const CorrectiveActionComment: React.FC<ICorrectiveActionComment> = ({
  title,
  answer,
  comment = 'No Description Provided.',
  images,
  subTitle,
  subSubTitle,
  contractorName = 'No Contractor Provided',
  dueDate = null,
  children,
  initialCA,
  status,
  contactPersonName,
  responsiblePartyName,
  additionalContactInfo,
  subLocations,
  location,
  locationHierarchies,
  isLocationEnabled = false,
  isSubLocationEnabled = false,
}) => {
  const vm: ICorrectiveActionPageViewModelProps =
    useCorrectiveActionPageViewModel()
  const {
    user,
    correctiveAction,
    isShea,
    contractor,
    taskValue,
    locationValue,
    contactEmail,
    contactName,
    contactPhone,
    contactRole,
    lotName,
    address,
    responsibleParties,
    contactPersons,
    loadContactsForContractor,
    externalContact,
    setExternalContact,
    selectedContactPerson,
    setSelectedContactPerson,
    selectedResponsibleParty,
    setSelectedResponsibleParty,
    updateCorrectiveAction,

  } = vm
  const getLot = (
    location: string | undefined,
    address: string | undefined,
  ) => {
    if (!location && !address) return ''
    const locationValue = location ?? ''
    const addressValue = address ?? ''
    return `${locationValue} - ${addressValue}`
  }
  const [updatingVA, setUpdatingCA] = useState(false)
  const isClosed = status == ECorrectiveActionStatus.CLOSED
  const renderHeader = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'spaceAround',
          flexDirection: 'column',
        }}
      >

        <ETypography color='neutral950' font='SS' sx={{ fontSize: '16px' }}>
          {initialCA ? title : title ?? ''}
        </ETypography>
        <ETypography color='neutral950' font='SS' sx={{ fontSize: '16px' }}>
          {initialCA ? subTitle : subTitle ?? ''}
        </ETypography>
        <ETypography color='neutral950' font='SS' sx={{ fontSize: '16px' }}>
          {initialCA ? subSubTitle : subSubTitle ?? ''}
        </ETypography>
      </Box>
    )
  }


  const [editContactMode, setEditContactMode] = useState<boolean | undefined>(false)

  // console.log('debug initial data')
  // console.log(correctiveAction)
  // console.log(selectedResponsibleParty)
  // console.log(selectedContactPerson)
  // console.log(externalContact)

  const hasEditPermission = usePermission(ERole.CorrectiveActionChangeState)

  const updateCA = async () => {
    setUpdatingCA(true)
    await updateCorrectiveAction()
    setUpdatingCA(false)
    setEditContactMode(false)
  }
  return (
    <InnerPageContainer
      collapsible={!initialCA}
      header={renderHeader()} // Why is the padding being Changed here in Figma?
      sx={{ padding: '24px' }}
      renderRightItems={() =>
        initialCA ? (
          <Box>
            {/* TODO: Uncomment when BE DONE */}
            {/* {hasEditPermission ? (
              editContactMode ? (
                <EdifyButton
                  loading={updatingVA}
                  disabled={updatingVA}
                  noBackground
                  title='Done Editing'
                  onClick={updateCA}
                />
              ) : (
                <EdifyButton
                  hide={isClosed}
                  noBackground
                  title='Edit Contact Info'
                  onClick={() => {
                    setEditContactMode(true)
                  }}
                />
              )
            ) : null} */}
            <EdifyButton
              secondary
              disabled
              title={status ?? 'Open'}
              textStyle={{
                color: isClosed ? AppColors.baseWhite : AppColors.gray700,
              }}
              buttonStyle={{
                backgroundColor: isClosed
                  ? AppColors.success800
                  : AppColors.neutral700,
                height: '44px',
                whiteSpace: 'nowrap',
                minWidth: 'max-content',
              }}
            />
          </Box>
        ) : (
          <></>
        )
      }
    >
      <>
        {/* {answer && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              marginTop: '24px',
              gap: '24px',
            }}
          >
            <EdifyButton
              secondary
              disabled
              buttonStyle={{
                flex: 1,
                border: `1px solid ${AppColors.neutral800}`,
                background: AppColors.neutral600,
              }}
              textStyle={{ color: AppColors.gray700 }}
              noBackground
              title={answer || 'No'}
            />
            <EdifyButton
              secondary
              disabled
              buttonStyle={{
                flex: 1,
                border: `1px solid ${AppColors.neutral600}`,
                background: AppColors.baseWhite,
              }}
              textStyle={{ color: AppColors.gray700 }}
              noBackground
              title={'Yes'}
            />
          </Box>
        )} */}
        {!isShea ||
          (!initialCA && (
            <>
              {images.length > 0 && (
                <Grid item xs={12}>
                  <EdifyFormField>
                    <EdifyFieldLabel label='Attachments' />
                    <Box
                      style={{
                        display: 'flex',
                        gap: '24px',
                        flexWrap: 'wrap',
                        marginBottom:
                          (!dueDate || !contractorName) && children
                            ? '24px'
                            : '',
                      }}
                    >
                      {images.map((image, i) => {
                        let imageSrc = image.path ?? image
                        if (vm.isPublicCA) {
                          imageSrc = imageSrc + `&correctiveActionId=${vm.correctiveAction?.id}`
                          imageSrc = imageSrc.replace('/fileUploads', '/fileUploads/public/ca')
                        }
                        return (
                          <EdifyAttachmentViewer
                            key={image.id ?? image}
                            attachment={imageSrc}
                            name={image.title ?? `Image ${i + 1}`}
                            description={
                              image.description ?? 'No description provided.'
                            }
                          />
                        )
                      })}
                    </Box>
                  </EdifyFormField>
                </Grid>
              )}

              <EdifyFormField>
                <EdifyFieldLabel label={initialCA ? 'DESCRIPTION' : 'NOTES'} />
                <EParagraph>{parse(comment)}</EParagraph>
                {/* <EParagraph>{JSON.stringify(comment + '', null,2)}</EParagraph> */}
              </EdifyFormField>
            </>
          ))}
      </>
      {!isShea && images.length > 0 && (
        <EdifyFormField>
          <EdifyFieldLabel label='Attachments' />
          <Box
            style={{
              display: 'flex',
              gap: '24px',
              flexWrap: 'wrap',
              marginBottom:
                (!dueDate || !contractorName) && children ? '24px' : '',
            }}
          >
            {images.map((image, i) => {
              let imageSrc = image.path ?? image

              console.log('debug image path')
              console.log(imageSrc)
              if (vm.isPublicCA) {
                imageSrc = imageSrc + vm.correctiveAction?.id //`&correctiveActionId=${vm.correctiveAction?.id}`
                imageSrc = imageSrc + vm.correctiveAction?.id + `&hash=${vm.hash}`
                imageSrc = imageSrc.replace('/fileUploads', '/fileUploads/public/CA')
              }
              return (
                <EdifyAttachmentViewer
                  key={image.id ?? image}
                  attachment={imageSrc}
                  name={image.title ?? `Image ${i + 1}`}
                  description={image.description ?? 'No description provided.'}
                />
              )
            })}
          </Box>
        </EdifyFormField>
      )}
      {!isShea && (
        <Grid item xs={12}>
          <EdifyFormField>
            <EdifyFieldLabel label={initialCA ? 'DESCRIPTION' : 'NOTES'} />
            <EParagraph>{parse(comment)}</EParagraph>
          </EdifyFormField>
        </Grid>
      )}
      {/* TEMP FOR SHEA HOME ONLY */}
      {initialCA && isShea && (
        <Box>
          <Grid
            container
            spacing={2}
            style={{
              marginBottom: children ? '24px' : '0',
            }}
          >
            {correctiveAction && correctiveAction.question && (
              <Grid item xs={12}>
                <EdifyFormField>
                  <EdifyFieldLabel label={'Question'} />
                  <ETypography font='MM' color='gray700'>
                    {correctiveAction?.question ?? ''}
                  </ETypography>
                </EdifyFormField>
              </Grid>
            )}
            <CALabel
              title='Division'
              text={correctiveAction?.levelName ?? ''}
            />
            <CALabel
              title='Community'
              text={correctiveAction?.projectName ?? ''}
            />
            <CALabel title='Lot #' text={getLot(location, address)} />
            <CALabel title='Location' text={locationValue ?? ''} />
            <CALabel title='Task' text={taskValue ?? ''} />
            <CALabel
              title='Trade'
              text={correctiveAction?.contractorName ?? ''}
            />

            {contactRole !== undefined ? (
              <CALabel
                title='Contact Person Role'
                text={contactRole ?? 'Contractor'}
              />
            ) : (
              <Grid item xs={6} />
            )}
            <Grid item xs={6}>
              <EdifyFormField sx={{ flex: 1 }}>
                <EdifyFieldLabel label={'Contact Person Info'} />
                <ETypography font='MM' color='gray700'>
                  {contactName ?? ''}
                </ETypography>
                <ETypography font='MM' color='gray700'>
                  {contactEmail ?? ''}
                </ETypography>
                <ETypography font='MM' color='gray700'>
                  {contactPhone ?? ''}
                </ETypography>
              </EdifyFormField>
            </Grid>
            <Grid item xs={12}>
              <EdifyFormField>
                <EdifyFieldLabel label={initialCA ? 'DESCRIPTION' : 'NOTES'} />
                <EParagraph>{parse(comment)}</EParagraph>
              </EdifyFormField>
            </Grid>
            {images.length > 0 && (
              <Grid item xs={12}>
                <EdifyFormField>
                  <EdifyFieldLabel label='Attachments' />
                  <Box
                    style={{
                      display: 'flex',
                      gap: '24px',
                      flexWrap: 'wrap',
                      marginBottom:
                        (!dueDate || !contractorName) && children ? '24px' : '',
                    }}
                  >
                    {images.map((image, i) => {
                      return (
                        <EdifyAttachmentViewer
                          key={image.id ?? image}
                          attachment={image.path ?? image}
                          name={image.title ?? `Image ${i + 1}`}
                          description={
                            image.description ?? 'No description provided.'
                          }
                        />
                      )
                    })}
                  </Box>
                </EdifyFormField>
              </Grid>
            )}

            <CALabel
              title='Due Date'
              text={dueDate ? dateToMMMDYYYY(dueDate) : 'NA'}
            />
          </Grid>
        </Box>
      )}
      {initialCA && !isShea && (
        <Box>
          <Box
            style={{
              display: 'flex',
              gap: '24px',
              flexWrap: 'wrap',
              marginBottom: children ? '24px' : '0',
            }}
          >
            <EdifyFormField sx={{ flex: 1 }}>
              {/* What do we want to say here */}
              <EdifyFieldLabel
                label={'Responsible Party'}
              />
              {editContactMode ? (
                <EdifySelect
                  dropDownItems={responsibleParties ?? []}
                  onChange={(a: any) => {
                    const item = responsibleParties?.find((r) => r.name === a.target.value)
                    if (item) {
                      loadContactsForContractor(item.id, correctiveAction!)
                      setSelectedResponsibleParty(item!)
                      if (item.entity != 'Contractor') {
                        setSelectedContactPerson(undefined)
                      }

                    }

                  }}
                  value={selectedResponsibleParty?.name}
                  initialValue={contractorName}
                  placeholder={contractorName}
                  // width={464}
                  sx={{ marginRight: '24px', flex: 1 }}
                  // disabled
                  renderMenuItem={(item: IMinimalCommon) => (
                    <EdifyMenuItem key={item.id} value={item.name}>
                      {item.name} - {item.entity}
                    </EdifyMenuItem>
                  )}
                />

              ) : (
                <ETypography font='MM' color='gray700'>
                  {selectedResponsibleParty ? selectedResponsibleParty.name
                    :
                    contractorName
                      ? contractorName
                      : responsiblePartyName
                        ? responsiblePartyName
                        : 'NA'}
                </ETypography>
              )}

            </EdifyFormField>

            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='Contact' />
              {editContactMode && (selectedResponsibleParty?.entity == 'Contractor') ? (
                <EdifySelect
                  dropDownItems={contactPersons ?? []}
                  onChange={(a) => {

                    const item = contactPersons?.find((r) => r.fullName === a.target.value)
                    if (item) {
                      setSelectedContactPerson(item)
                    }

                  }}
                  initialValue={contactPersonName}
                  value={selectedContactPerson?.fullName}
                  placeholder={contactPersonName ?? 'none'}
                  // width={464}
                  sx={{ marginRight: '24px', flex: 1 }}
                  // disabled
                  renderMenuItem={(item: any) => (
                    <EdifyMenuItem key={item.id} value={item.fullName}>
                      {item.fullName}
                    </EdifyMenuItem>
                  )}
                />

              )
                : (
                  <ETypography font='MM' color='gray700'>
                    {
                      selectedContactPerson ?
                        selectedContactPerson?.fullName : 'NA'
                    }
                  </ETypography>
                )}

            </EdifyFormField>

            {/* {additionalContactInfo ?  ( */}

            {/* <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='External Contact' />
              {editContactMode ? (
                <Input
                  data-testid='externalContact'
                  disableUnderline
                  placeholder={additionalContactInfo}

                  className={'form-control'}
                  value={externalContact ?? additionalContactInfo}
                  onChange={(e) => {
                    setExternalContact(e.target.value)

                  }
                  }
                />) : (
                <ETypography font='MM' color='gray700'>
                  {externalContact ?? additionalContactInfo}
                </ETypography>)
              }

            </EdifyFormField> */}
            {/* ) : null} */}



            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='Due Date' />
              <ETypography font='MM' color='gray700'>
                {dueDate ? dateToMMMDYYYY(dueDate) : 'NA'}
              </ETypography>
            </EdifyFormField>

          </Box>

          {/* SubLocation SEction */}
          {isLocationEnabled && (
            <Box
              style={{
                display: 'flex',
                gap: '24px',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginBottom: children ? '24px' : '0',
              }}
            >
              <EdifyFormField sx={{ flex: 1 }}>
                <EdifyFieldLabel label='Location' />
                <ETypography font='MM' color='gray700'>
                  {location ?? 'NA'}
                </ETypography>
              </EdifyFormField>
              {isSubLocationEnabled && (
                <EdifyFormField sx={{ flex: 4 }}>
                  <EdifyFieldLabel label='Sub Locations' />
                  {subLocations && subLocations.length > 0 ? (
                    <ETypography font='MM' color='gray700'>
                      {subLocations?.map((s, i) => {
                        return (
                          <span key={i}>
                            {s}
                            <LevelBadge
                              level={i}
                              label={
                                locationHierarchies[i]
                                  ? locationHierarchies[i].name
                                  : ''
                              }
                            />
                            {i < subLocations.length - 1 && (
                              <RightArrowIcon
                                sx={{ marginLeft: '12px', marginRight: '12px' }}
                              />
                            )}
                          </span>
                        )
                      })}
                    </ETypography>
                  ) : (
                    <ETypography font='MM' color='gray700'>
                      NA
                    </ETypography>
                  )}
                </EdifyFormField>
              )}
            </Box>
          )}
        </Box>
      )}
      {children && children}
    </InnerPageContainer>
  )
}

interface ICALabel {
  title: string
  text: string
}
const CALabel: React.FC<ICALabel> = ({ title, text }) => {
  return (
    <Grid item xs={6}>
      <EdifyFormField>
        <EdifyFieldLabel label={title} />
        <ETypography font='MM' color='gray700'>
          {text}
        </ETypography>
      </EdifyFormField>
    </Grid>
  )
}

export default CorrectiveActionComment
