import { ButtonProps, SxProps } from '@mui/material'
import React from 'react'

import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material'
import { EdifyButton } from '../../buttons'
import { AppColors, AppFonts } from '../../../Theme'

interface CheckboxButtonProps extends ButtonProps {
  checked: boolean
  onClick: () => void
  title: string
  sx?: SxProps
  readOnly?: boolean
  disabled?:boolean
}

export const EdifyCheckboxButton: React.FC<CheckboxButtonProps> = ({
  checked,
  onClick,
  title,
  sx,
  readOnly,
  disabled
}) => {
  return (
    <EdifyButton
      disabled={disabled}
      textStyle={{
        ...AppFonts.textLMedium,
        color: AppColors.gray700,
        textAlign: 'left',
      }}
      secondary
      title={title}
      // variant={checked ? 'contained' : 'outlined'}
      onClick={onClick}
      icon={
        checked ? (
          <CheckCircle
            style={{
              color: readOnly || disabled ? AppColors.gray50 : AppColors.primary600,
            }}
          />
        ) : (
          <RadioButtonUnchecked style={{ color: AppColors.neutral700 }} />
        )
      }
      iconRight
      buttonStyle={{
        background: readOnly ? AppColors.neutral600 : AppColors.baseWhite,
        width: '185px',
        ...sx,
      }}
    />
  )
}
