import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridItem } from '../gridItems'
import { IOrg } from '../../../../domain/interfaces/IOrg'
import { dateToMMMDYYYY } from '../../../../core/core'
import { formatSnakeCase } from '../../../../core/utils/text-formaters/TextFormatters'

export const adminOrg_columns: () => GridColDef[] = () => {
  return [
    {
      field: 'name',
      renderHeader: () => <GridItem text='Name' />,
      flex: 1,
      renderCell: ({ row }) => <GridItem text={row.name} />,
    },
    {
      field: 'isMember',
      renderHeader: () => <GridItem text='Member' />,
      width: 120,
      renderCell: ({ row }) => (
        <GridItem text={row.personOrgs.includes(row.id) ? 'Yes' : 'No'} />
      ),
    },
    // {
    //   field: 'userOrgRole',
    //   renderHeader: () => <GridItem text='Org Role' />,
    //   width: 140,
    //   renderCell: ({ row }) => <GridItem text={formatSnakeCase(row.userOrgRole)} />,
    // },
    {
      field: 'type',
      renderHeader: () => <GridItem text='Type' />,
      width: 120,
      renderCell: ({ row }) => <GridItem text={row.type} />,
    },
    {
      field: 'id',
      renderHeader: () => <GridItem text='ID' />,
      flex: 1,
      renderCell: ({ row }) => <GridItem text={row.id} />,
    },

    {
      field: 'created',
      renderHeader: () => <GridItem text='Created' />,
      width: 165,
      renderCell: ({ row }) => (
        <GridItem text={dateToMMMDYYYY(row.createdAt)} />
      ),
    },
  ]
}
export const normalizeAdminOrgsForGrid: any = (
  adminOrgs: IOrg[],
  personOrgs: IOrg[],
) => {
  if (!adminOrgs) return []

  return adminOrgs?.map((adminOrg: IOrg) => {
    return {
      id: adminOrg.id,
      name: adminOrg.name,
      createdAt: adminOrg.createdAt,
      type: adminOrg.type,
      userOrgRole: adminOrg.userOrgRole,
      personOrgs: personOrgs ? personOrgs.map((org) => org.id) : [],
    }
  })
}
