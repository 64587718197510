import { Either, Failure } from '../../../core/core'
import {
  ICorrectiveAction,
  ICorrectiveActionCommentList,
  ICorrectiveActionContactsData,
  ICorrectiveActionList,
  ICorrectiveActionsDeleted,
  ICorrectiveActionSubmitData,
  ICorrectiveActionUpdateData,
} from '../../../domain/interfaces/ICorrectiveAction'
import { httPostBlob, httpDeleteWithPayload, httpGet, httpGetBlob, httpPost, httpPut } from '../../data'

const correctiveActionsUrl = '/correctiveActions'
const caCommentsUrl = '/caComments'

//TODO DOWNLOAD CORRECTIVE ACTION PDF
// export async function correctiveActionsGet(
//   ids: string,
// ): Promise<Either<Failure, Blob>> {
//   const res: Either<Failure, Blob> = await httpGetBlob(
//     `${submissionsBaseUrl}/generatePDF/?ids=${ids}`,
//   )
//   return res
// }

export async function getAllCorrectiveActions(
  queryString: string,
): Promise<Either<Failure, ICorrectiveActionList>> {
  const res: Either<Failure, ICorrectiveActionList> = await httpGet(
    `${correctiveActionsUrl}?${queryString}`,
    false,
  )
  return res
}

export async function getAllCorrectiveActionComments(
  id: string,
): Promise<Either<Failure, ICorrectiveActionCommentList>> {
  const res: Either<Failure, ICorrectiveActionCommentList> = await httpGet(
    `${caCommentsUrl}/?caId=${id}`,
    false,
  )
  return res
}

export async function getAllPublicCorrectiveActionComments(
  id: string,
  hash: string,
): Promise<Either<Failure, ICorrectiveActionCommentList>> {
  const res: Either<Failure, ICorrectiveActionCommentList> = await httpGet(
    `${correctiveActionsUrl}/public/comments?hash=${hash}`,
    false,
  )
  return res
}

export async function getOnePersonsCorrectiveActions(
  queryString: string,
): Promise<Either<Failure, ICorrectiveActionList>> {
  const res: Either<Failure, ICorrectiveActionList> = await httpGet(
    `${correctiveActionsUrl}/forPerson?${queryString}`,
    false,
  )
  return res
}

export async function getAllOwnedCorrectiveActions(
  queryString: string,
): Promise<Either<Failure, ICorrectiveActionList>> {
  const res: Either<Failure, ICorrectiveActionList> = await httpGet(
    `${correctiveActionsUrl}/owned/?${queryString}`,
    false,
  )
  return res
}

export async function correctiveActionResolve(
  id: string,
  data: { description: string },
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await httpPut(
    `${correctiveActionsUrl}/resolved/${id}`,
    data,
    false,
  )
  return res
}

// Version 2 CORRECTIVE ACTIONS FUNCTIONALITY
export async function correctiveActionById(
  id: string,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await httpGet(
    `${correctiveActionsUrl}/${id}`,
  )
  return res
}

export async function correctiveActionPublicById(
  id: string,
  hash: string,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await httpGet(
    `${correctiveActionsUrl}/public?hash=${hash}`,
  )
  return res
}

export async function correctiveActionAddComment(
  id: string,
  data: ICorrectiveActionSubmitData,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await httpPut(
    `${correctiveActionsUrl}/addComment/${id}`,
    data,
  )
  return res
}

export async function correctiveActionUpdateContacts(
  id: string,
  data: ICorrectiveActionContactsData,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await httpPut(
    `${correctiveActionsUrl}/${id}`,
    data,
  )
  return res
}

export async function correctiveActionAddPublicComment(
  hash: string,
  data: ICorrectiveActionSubmitData,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await httpPost(
    `${correctiveActionsUrl}/public/addComment?hash=${hash}`,
    data,
  )
  return res
}

export async function correctiveActionSetPublicToUnderReview(
  hash: string,
  data: ICorrectiveActionSubmitData,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await httpPost(
    `${correctiveActionsUrl}/public/underReview?hash=${hash}`,
    data,
  )
  return res
}

export async function correctiveActionUpdate(
  id: string,
  data: ICorrectiveActionUpdateData,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await httpPut(
    `${correctiveActionsUrl}/${id}`,
    data,
  )
  return res
}

export async function correctiveActionPersonAddComment(
  id: string,
  data: ICorrectiveActionSubmitData,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await httpPut(
    `${correctiveActionsUrl}/addComment/forPerson/${id}`,
    data,
  )
  return res
}

export async function correctiveActionSendBack(
  id: string,
  data: ICorrectiveActionSubmitData,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await httpPut(
    `${correctiveActionsUrl}/sendBack/${id}`,
    data,
  )
  return res
}

export async function correctiveActionClose(
  id: string,
  data: ICorrectiveActionSubmitData,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await httpPut(
    `${correctiveActionsUrl}/close/${id}`,
    data,
  )
  return res
}

//localhost:3000/correctiveActions/downloadPdf/f140559b-4a4b-45c2-993f-8d75a0455907

export async function correctiveActionPDF(
  id: string,
): Promise<Either<Failure, Blob>> {
  const res: Either<Failure, Blob> = await httpGetBlob(
    `${correctiveActionsUrl}/downloadPdf/${id}`,
  )
  return res
}

export async function correctiveActionsPDF(
  ids: string[],
): Promise<Either<Failure, Blob>> {
  const res: Either<Failure, Blob> = await httPostBlob(
    `${correctiveActionsUrl}/generatePDF`,
    { ids: ids },
  )
  return res
}

// TODO: CHECK URLS WHEN BE API IS READY
export async function deleteCorrectiveAction(
  correctiveActionId: string,
): Promise<Either<Failure, ICorrectiveAction>> {
  const res: Either<Failure, ICorrectiveAction> = await httpPut(
    // `/correctiveActions/${correctiveActionId}`,
    `/correctiveActions/${correctiveActionId}/archive`,
    {},
    // undefined, //no additional headers
  )

  return res
}

export async function deleteCorrectiveActions(
  correctiveActionIds: string[],
): Promise<Either<Failure, ICorrectiveActionsDeleted>> {
  const res: Either<Failure, ICorrectiveActionsDeleted> = await httpDeleteWithPayload(
    '/correctiveActions', { correctiveActionIds }
  )
  return res
}
