import React, { useEffect } from 'react'
import { AppBarTheme } from '../../../components/banners/app-bar/EdifyAppBar'
import { Box, IconButton, Typography } from '@mui/material'
import { AppColors, AppHovers } from '../../../Theme'
import { EdifyNavViewModel } from './EdifyNavViewModel'
import { SwitchAccountIcon } from '../../../../core/components/icons/switch-account/SwitchAccountIcon'
import { ROUTE_ORG_SELECTION } from '../../../pages/org_selection/OrgSelectionPage'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { EdifyAppBarSearch } from '../app-bar/EdifyAppBarSearch'
import useSocketConnection from '../../../../core/hooks/useSocketConnection'
import { styled } from '@mui/material/styles'
import Badge, { BadgeProps } from '@mui/material/Badge'
import { ROUTE_NOTIFICATIONS } from '../../../pages/notifications/NotificationsPage'
import { AlertIcon } from '../../../../core/components/icons/AlertIcon'
import { EdifyLinkSearch } from './EdifyLinkSearch'
import { useOrganizationProvider } from '../../../../providers/OrganizationProvider'
import { SettingsIcon } from '../../../../core/components/icons/SettingsIcon'
import { ROUTE_ORG_SETTINGS } from '../../../pages/org-settings/OrgSettingsPage'
import { useGlobalProvider } from '../../../../providers/GlobalProvider'
import { GlobeIcon } from '../../../../core/components/icons/GlobeIcon'
import { LanguageSearch } from './LanguageSearch'
import ErrorBoundaryWrapper from '../../../pages/wrappers/ErrorBoundaryWrapper'
import { I18nProvider } from '../../../../providers/InternationalizationProvider'
// ADDS option for toggle side drawer
import { MenuOpen, MenuOutlined } from '@mui/icons-material'

export const EdifyNav: React.FC = () => {
  return (
    <EdifyNavViewModel>
      <_Page />
    </EdifyNavViewModel>
  )
}

export const _Page: React.FC = () => {
  const {
    isContractor,
    orgProviderLoading,
    orgProviderError,
    userHasSingleOrg,
    org,
    setShowDrawer,
    showDrawer
  } = useOrganizationProvider()
  const { isGlobalUser, isGlobalUserView } = useGlobalProvider()
  const [badgeValue, setBadgeValue] = React.useState<number>(0)
  const [showBadge, setShowBadge] = React.useState<boolean>(false)

  const socket = useSocketConnection()
  const navigate = useNavigate()
  const location = useLocation()

  // Check if the current path is "/dashboard"
  const isDashboardPage = location.pathname === '/dashboard'
  const isHomePage = location.pathname === '/home'

  useEffect(() => {
    socket.on('unread_notifications_count', (count: number) => {
      if (count) {
        setBadgeValue(count)
        setShowBadge(true)
      }
    })
  }, [])

  const badgeClicked = () => {
    navigate(ROUTE_NOTIFICATIONS)
    setBadgeValue(0)
    setShowBadge(false)
  }

  const standardStyling = {
    borderRadius: '5px',

    marginRight: { sm: '12px', md: '12px', lg: '24px' },
    backgroundColor: AppColors.gray600,
    '&:hover': { ...AppHovers.dark },
  }

  const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
      right: -8,
      top: -8,
      display: 'inline-flex',
      width: '20px',
      height: '20px',
      padding: '0px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      background: 'red',
    },
  }))

  const barItems = (
    <Box
      // direction='row'
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: isContractor ? 'flex-end' : 'space-between',
        padding: '0px',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {/* <IconButton onClick={()=> setShowDrawer(!showDrawer)}>
          {showDrawer ? <MenuOpen sx={{color:AppColors.neutral700}}/> : <MenuOutlined sx={{color:AppColors.neutral700}} />}
        </IconButton> */}
        <Box
          sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}
        >
          <EdifyLinkSearch />
        </Box>
      </Box>

      <div>
        <LanguageSearch />
        {(!userHasSingleOrg || isGlobalUser) && (
          <>
            <IconButton
              sx={{
                color: 'white',
                ...standardStyling,
                padding: { md: '10px', lg: '10px' },
              }}
              onClick={() => navigate(ROUTE_ORG_SELECTION)}
            >
              <SwitchAccountIcon sx={{ marginRight: '6px' }} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '15px',
                  lineHeight: '22px',
                  display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' },
                }}
              >
                Account
              </Typography>
            </IconButton>
          </>
        )}
        <IconButton
          data-testid='EdifyAppBar_Notification'
          sx={{
            color: 'white',
            ...standardStyling,
            // marginRight: '6px',
            justifyContent: 'center',

            padding: { md: '10px', lg: '10px' },
          }}
          onClick={badgeClicked}
        >
          <StyledBadge
            badgeContent={showBadge ? badgeValue : 0}
            color='secondary'
          >
            <AlertIcon sx={{ width: 20, height: 20 }} />
          </StyledBadge>
        </IconButton>
        {isGlobalUser && isGlobalUserView && (
          <IconButton
            onClick={() => navigate(ROUTE_ORG_SETTINGS)}
            sx={{
              ...standardStyling,
              justifyContent: 'center',

              padding: { md: '10px', lg: '10px' },
              // marginLeft: { md: '12px', lg: '24px' },
            }}
          >
            <SettingsIcon />
          </IconButton>
        )}
      </div>
    </Box>
  )
  const renderContent = () => {
    if (isDashboardPage || isHomePage) {
      return <Outlet />
    }
    if (orgProviderLoading()) {
      return <></>
    }
    return <Outlet />
  }
  // For now we don't translate if Contact/Contractor
  const orgId = org?.id ?? ''

  return (
    <I18nProvider orgId={orgId}>
      <EdifyAppBarSearch theme={AppBarTheme.DARK} barItems={barItems} isFromOrg>
        <ErrorBoundaryWrapper>{renderContent()}</ErrorBoundaryWrapper>
      </EdifyAppBarSearch>
    </I18nProvider>
  )
}
