import { IPermission } from '../../../domain/interfaces/IRole'
interface FlattenedRoleData {
  [key: string]: number
}

export function flattenFormData(formData: IPermission[]): IPermission[] {
  return formData.map(form => {
    const flattenedForm: any = {
      id: form.id,

    }

    if (form.permissions) {
      Object.entries(form.permissions).forEach(([key, value]) => {
        flattenedForm[key] = value
      })
    }

    return flattenedForm as IPermission
  })
}

export function transformRoleDataForBackend(roleData: IPermission[]): FlattenedRoleData {
  const formattedRoleData = roleData.reduce((acc, item) => {
    if (typeof item.id !== 'string') return acc

    const featureName = item.id.replace('FeatureAccess', '')

    // Set the feature access
    acc[item.id] = item.featureAccess

    // Add permission values from the permissions object
    Object.entries(item.permissions).forEach(([permissionKey, value]) => {
      acc[`${featureName}${capitalizeFirstLetter(permissionKey)}`] = value as number
    })

    return acc
  }, {} as FlattenedRoleData)

  // if userRead is o manually set it to 1
  // so they can view there own profile
  if (formattedRoleData.userFeatureAccess === 0 && formattedRoleData.userRead === 0) {
    formattedRoleData.userRead = 1
  }
  return formattedRoleData
}

// Helper function to capitalize the first letter of a string
function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
interface ButtonValues {
  [key: string]: string[];
}

interface FeatureAccess {
  id?: string
  hide: boolean;
  readonly: boolean;
  name: string;
  buttonValues: ButtonValues;
}

export const orgFeatureFlagsIds = ['documentFeatureAccess', 'locationFeatureAccess']

const defaultAllValues = ['None', 'Self', 'All']
const userViewButton = ['None', 'All']
const defaultCreateValues = ['None', 'All']
const participantValues = ['None', 'Participant', 'All']
const projectValues = ['None', 'Assigned', 'All']


export const featureAccessAdditionalData: FeatureAccess[] = [
  {
    id: 'permissionFeatureAccess',
    hide: false,
    readonly: true,
    name: 'Permission Management',
    buttonValues: {
      read: defaultCreateValues,
      create: defaultCreateValues,
      edit: defaultCreateValues,
    }
  },
  {
    id: 'roleFeatureAccess',
    hide: true,
    readonly: true,
    name: 'Role Management',
    buttonValues: {
      read: defaultCreateValues,
      create: defaultCreateValues,
      edit: defaultCreateValues,
    }
  },
  {
    id: 'correctiveActionFeatureAccess',
    hide: false,
    readonly: false,
    name: 'Corrective Action Management',
    buttonValues: {
      read: defaultAllValues,
      comment: defaultAllValues,
      changeState: defaultAllValues,
      // TODO uncomment when archive role add to BE
      // archive: defaultAllValues,
    }
  },
  {
    id: 'projectFeatureAccess',
    hide: false,
    readonly: false,
    name: 'Project Management',
    buttonValues: {
      read: projectValues,
      create: defaultCreateValues,
      edit: projectValues,
    }
  },
  {
    id: 'levelFeatureAccess',
    hide: false,
    readonly: false,
    name: 'Level Management',
    buttonValues: {
      read: projectValues,
      create: defaultCreateValues,
      edit: projectValues,
    }
  },
  {
    id: 'userFeatureAccess',
    hide: false,
    readonly: false,
    name: 'User Management',
    buttonValues: {
      read: userViewButton,
      create: defaultCreateValues,
      edit: defaultAllValues,
    }
  },
  {
    id: 'trainingCertificateFeatureAccess',
    hide: false,
    readonly: false,
    name: 'Training & Certificate Management',
    buttonValues: {
      read: defaultAllValues,
      create: defaultAllValues,
      edit: defaultAllValues,
    }
  },
  {
    id: 'goalFeatureAccess',
    hide: false,
    readonly: false,
    name: 'Goal & Controls Management',
    buttonValues: {
      read: participantValues,
      create: defaultCreateValues,
      edit: participantValues,
    }
  },
  {
    id: 'locationFeatureAccess',
    hide: false,
    readonly: false,
    name: 'Location Management',
    buttonValues: {
      read: defaultCreateValues,
      create: defaultCreateValues,
      edit: defaultCreateValues,
    }
  },
  {
    id: 'contractorFeatureAccess',
    hide: false,
    readonly: false,
    name: 'Contractor Management',
    buttonValues: {
      read: defaultCreateValues,
      create: defaultCreateValues,
      edit: defaultCreateValues,
    }
  },
  {
    id: 'documentFeatureAccess',
    hide: false,
    readonly: false,
    name: 'Document Management',
    buttonValues: {
      read: defaultCreateValues,
      create: defaultCreateValues,
      edit: defaultCreateValues,
    }
  },
  {
    id: 'formFeatureAccess',
    hide: false,
    readonly: true,
    name: 'Form Management',
    buttonValues: {
      read: defaultAllValues,
      create: defaultCreateValues,
      edit: defaultAllValues,
    }
  },
  {
    id: 'notificationFeatureAccess',
    hide: false,
    readonly: true,
    name: 'Notification Management',
    buttonValues: {
      read: defaultAllValues,
      create: defaultCreateValues,
      edit: defaultAllValues,
    }
  },
  {
    id: 'profileFeatureAccess',
    hide: false,
    readonly: true,
    name: 'Profile Management',
    buttonValues: {
      read: defaultAllValues,
      create: defaultCreateValues,
      edit: defaultAllValues,
    }
  },
  {
    id: 'dashboardFeatureAccess',
    hide: false,
    readonly: false,
    name: 'Dashboard Management',
    buttonValues: {
      read: defaultAllValues,
      create: defaultCreateValues,
      edit: defaultAllValues,
    }
  },
  {
    id: 'correctiveActionFeatureAccess',
    name: 'Corrective Action Response',
    hide: false,
    readonly: false,
    buttonValues: {
      comment: defaultCreateValues,
      close: defaultCreateValues,
    },
  },
  {
    id: 'withOutCreate',
    name: 'withOutCreate Action Response',
    hide: false,
    readonly: false,
    buttonValues: {
      read: defaultCreateValues,
      edit: defaultCreateValues,
    },
  }
]

const defaultFormValues: FeatureAccess = {
  id: 'form',
  hide: false,
  readonly: true,
  name: 'Form',
  buttonValues: {
    read: defaultAllValues,
    create: defaultCreateValues,
    edit: defaultAllValues,
  }

}

export type EnhancedPermission = IPermission & {
  hide: boolean;
  readonly: boolean;
  buttonValues: ButtonValues;
};


export const addButtonValuesToRoleData = (roleData: IPermission[]): EnhancedPermission[] => {
  const enhancedRoleData = [] as EnhancedPermission[]
  roleData.forEach((item) => {
    const enhancedRole = item as EnhancedPermission
    const featureAccess = featureAccessAdditionalData.find((feature) => feature.id === item.id)
    if(enhancedRole.id === 'correctiveActionFeatureAccess'){

      // enhancedRole.permissions.archive = 2
    }
    if (featureAccess) {
      console.log({ featureAccess })
      enhancedRole.buttonValues = featureAccess.buttonValues
      enhancedRole.hide = featureAccess.hide
      enhancedRole.readonly = featureAccess.readonly
    } else {
      enhancedRole.buttonValues = defaultFormValues.buttonValues
      enhancedRole.hide = false
      enhancedRole.readonly = false
    }
    enhancedRoleData.push(enhancedRole)
  })
  return enhancedRoleData
}


const isEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (const key of keys1) {
    if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) return false
  }

  return true
}

export const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false
  for (let i = 0; i < arr1.length; i++) {
    if (!isEqual(arr1[i], arr2[i])) return false
  }
  return true
}