import React, { useContext, useEffect, useState } from 'react'

import {
  ECorrectiveActionStatus,
  ICorrectiveAction,
  ICorrectiveActionComment,
  ICorrectiveActionContactsData,
} from '../../../domain/interfaces/ICorrectiveAction'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  getCorrectiveActionById,
  getCorrectiveActionsComment,
  getCorrectivePublicActionById,
  getCurrentPerson,
  getCurrentUser,
  getPublicCorrectiveActionsComment,
  updateCorrectiveActionContacts,
} from '../../../domain/domain'
import { IUser } from '../../../domain/interfaces/IUser'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { anonGetOneForm, deleteCorrectiveAction, getContractorById, getResponsibleParties } from '../../../data/data'
import { IContractor, IMinimalCommon } from '../../../domain/interfaces/IContractor'
import { IFormComponent } from '../../../domain/interfaces/IFormComponent'
import { findComponent } from '../../../core/utils/formio-formatters/FormIOFormatter'
import { getContactById } from '../../../data/repositories/contacts/ContactsRepository'
import { getSheaLocation } from '../corrective-actions/SheaLocations'
import { ErrorToast, SuccessToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { ROUTE_CORRECTIVE_ACTIONS } from '../corrective-actions/CorrectiveActionsPage'
import { showConfirmationDeleteMessage } from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'

export const SHEA_HOMES_ID = 'f8782756-9dd8-45cf-9495-70131108fd7f'

export enum ECorrectiveActionViewMode {
  CONTRACTOR = 'CONTRACTOR',
  ADMIN = 'ADMIN', // author, admins in org
  READ_ONLY = 'READ_ONLY', // people in org
}
export interface ICorrectiveActionPageViewModelProps {
  authorName: string
  contractorName: string
  correctiveAction: ICorrectiveAction | undefined
  correctiveActionLoading: boolean
  correctiveActionLoadError: string | undefined
  reloadCorrectiveAction: () => void
  loadCorrectiveActionComments: () => void
  setCorrectiveAction: (correctiveAction: ICorrectiveAction) => void
  user: IUser | undefined
  viewMode: ECorrectiveActionViewMode
  correctiveActionClosing: boolean
  isContractor: boolean
  isAdmin: boolean
  comments: ICorrectiveActionComment[]
  isOpen: boolean
  isClosed: boolean
  isUnderReview: boolean
  commentsLoading: boolean
  commentsError: string | undefined
  // Shea values
  isShea: boolean
  contractor: IContractor | undefined
  taskValue: string | undefined
  locationValue: string | undefined
  contactName: string | undefined
  contactEmail: string | undefined
  contactPhone: string | undefined
  contactRole: string | undefined
  lotName: string | undefined
  address: string | undefined
  showDeleteConfirmation: () => void
  isPublicCA: boolean
  hash: string
  responsibleParties: IMinimalCommon[] | undefined
  contactPersons: any[] | undefined
  loadContactsForContractor: (contractorId: string, ca: ICorrectiveAction) => void
  setSelectedResponsibleParty: (responsibleParty: IMinimalCommon | undefined) => void
  setSelectedContactPerson: (contactPerson: IMinimalCommon | undefined) => void
  selectedResponsibleParty: IMinimalCommon | undefined
  selectedContactPerson: IMinimalCommon | undefined
  externalContact: string | undefined
  setExternalContact: (externalContact: string) => void
  updateCorrectiveAction: () => void


}

const CorrectiveActionPageContext =
  React.createContext<ICorrectiveActionPageViewModelProps | null>(null)

export function useCorrectiveActionPageViewModel(): ICorrectiveActionPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(CorrectiveActionPageContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const CorrectiveActionPageViewModel: React.FC<Props> = ({
  children,
}) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const hash = new URLSearchParams(useLocation().search).get('hash') ?? 'TEST_HASH_REMOVE'
  // '$2b$10$EEA1o2CbFc65ryi6vDEiJeKOVkpf86EeYFeJ0vMLm1IkswbYBmVHK' 

  const [correctiveAction, setCorrectiveAction] = useState<ICorrectiveAction>(
    {},
  )
  const [correctiveActionLoadError, setCorrectiveActionLoadError] = useState<
    string | undefined
  >(undefined)
  const [correctiveActionLoading, setCorrectiveActionLoading] = useState(false)
  const [correctiveActionClosing, setCorrectiveActionClosing] = useState(false)

  const [contractor, setContractor] = useState<IContractor | undefined>(
    undefined,
  )
  const [comments, setComments] = useState<ICorrectiveActionComment[]>([])
  const [commentsLoading, setCommentsLoading] = useState<boolean>(false)
  const [commentsError, setCommentsError] = useState<string | undefined>(
    undefined,
  )
  const [viewMode, setViewMode] = useState(ECorrectiveActionViewMode.ADMIN)
  const vm = useOrganizationProvider()
  // Public CA logic
  const location = useLocation()
  const isPublicCA = location.pathname.includes('public/correctiveActions')

  const isContractor = vm ? vm.isContractor : isPublicCA ?? false
  // console.log('isContractor', isContractor)
  // console.log('isPublicCA', isPublicCA)



  const user = getCurrentUser()
  const person = getCurrentPerson()
  const [isShea, setIsShea] = useState<boolean>(false)

  const [taskValue, setTaskValue] = useState<string | undefined>(undefined)
  const [locationValue, setLocationValue] = useState<string | undefined>(
    undefined,
  )
  const [contactName, setContactName] = useState<string | undefined>(undefined)
  const [contactEmail, setContactEmail] = useState<string | undefined>(
    undefined,
  )
  const [contactPhone, setContactPhone] = useState<string | undefined>(
    undefined,
  )
  const [contactRole, setContactRole] = useState<string | undefined>(undefined)
  const [lotName, setLotName] = useState<string | undefined>(undefined)
  const [address, setAddress] = useState<string | undefined>(undefined)

  const [responsibleParties, setResponsibleParties] = useState<IMinimalCommon[]>()
  const [contactPersons, setContactPersons] = useState<any[] | undefined>([])

  const [selectedResponsibleParty, setSelectedResponsibleParty] = useState<IMinimalCommon | undefined>()
  const [selectedContactPerson, setSelectedContactPerson] = useState<IMinimalCommon | undefined>()
  const [externalContact, setExternalContact] = useState<string | undefined>()


  const loadResponsibleParties = async (ca: ICorrectiveAction) => {
    const res = await getResponsibleParties()

    if (res.isRight()) {
      const data = res.right!.data

      const initialResponsibleParty = data?.find((r) => r.name === ca.contractorName)

      setSelectedResponsibleParty(initialResponsibleParty)
      if (initialResponsibleParty) {
        await loadContactsForContractor(initialResponsibleParty.id, ca)


      }

      setResponsibleParties(data ?? [])
    }

  }

  const loadContactsForContractor = async (contractorId: string, ca: ICorrectiveAction) => {
    const res = await getContractorById(contractorId)
    if (res.isRight()) {
      const contractor = res.right
      // setContractor(contractor)

      const contacts = [
        {
          fullName: contractor?.pointOfContactName,
          id: contractor!.pointOfContactId!,
          email: contractor?.pointOfContactEmail,
          phone: contractor?.pointOfContactPhone,

        }
        , ...contractor?.contacts?.map((contact) => {
          return {
            fullName: contact.fullName,
            id: contact?.id,
            email: contact?.email,
            phone: contact?.phone,
          }
        }) ?? []]
      setContactPersons(contacts)
      const item: IMinimalCommon | undefined = contacts?.find((r) => r.fullName === ca.contactPersonName)
      if (item) {
        setSelectedContactPerson(item)
      }
      // console.log('contacts', contacts)
    }
  }

  const updateCorrectiveAction = async () => {

    const correctiveActionContactData: ICorrectiveActionContactsData = {
      responsiblePartyId: selectedResponsibleParty?.id,
      contactId: selectedContactPerson?.id,
      additionalContactInfo: externalContact,
    }

    // console.log('correctiveActionContactData', correctiveActionContactData)

    //send updated corrective action to backend
    const res = await updateCorrectiveActionContacts(correctiveAction.id!, correctiveActionContactData)

    if (res.right) {
      SuccessToast({ title: 'Successfully updated the Corrective Action.' })
      return
    }

    ErrorToast({ title: res.left!.message ?? 'A error occurred updating the Corrective Action.' })
    // reload corrective action to get previous values if update fails
    await init()

  }


  // TODO: When we get to roles/permissions. Revisit this logic
  const getViewMode = () => {
    if (isContractor) {
      setViewMode(ECorrectiveActionViewMode.CONTRACTOR)
      return
    }
    setViewMode(ECorrectiveActionViewMode.ADMIN)
    // No Read Only mode for now but this will be added
  }

  const handleDelete = async () => {
    const res = await deleteCorrectiveAction(correctiveAction!.id ?? '')
    if (res.isLeft()) {
      ErrorToast({ title: 'Failed to delete corrective action from the DB' })
      return
    }
    SuccessToast({ title: 'Corrective action  successfully deleted.' })
    navigate(ROUTE_CORRECTIVE_ACTIONS)
  }

  const showDeleteConfirmation = async () => {
    showConfirmationDeleteMessage('this corrective action', handleDelete)
  }

  const loadCorrectiveAction = async () => {

    let res
    if (isPublicCA) {
      res = await getCorrectivePublicActionById(id!, hash)
    } else {
      res = await getCorrectiveActionById(id!)
    }

    if (res.isLeft()) {
      setCorrectiveActionLoadError(
        res.left?.message ?? 'Error loading corrective action',
      )
      return
    }

    getViewMode()
    const ca = res.right as ICorrectiveAction
    setCorrectiveAction(ca)

    const isTheShea = ca.orgId == SHEA_HOMES_ID
    setIsShea(isTheShea)
    if (isTheShea) {
      // get select contractor from CA
      // only do this for shea

      // get form from the CA
      const form = await anonGetOneForm(ca.formId!, ca.orgId!)
      // the submission where CA came from.

      // const submission = await getSubmissionById(ca.submissionId ?? '')
      const components: IFormComponent[] = form.right?.components
        ? form.right.components
        : []

      try {
        // see if selected contact
        if (ca.contactPersonId) {
          const contactRes = await getContactById(ca.contactPersonId)
          if (contactRes.right) {
            const { fullName, email, phone, title } = contactRes.right
            setContactName(fullName)
            setContactEmail(email)
            setContactPhone(phone)
            setContactRole(title ?? '')
          }
        }

        // then use contractor point of contact if no contact
        if (ca.contractorId) {
          const contractorRes = await getContractorById(ca.contractorId)
          if (contractorRes.right) {
            const {
              pointOfContactName,
              pointOfContactEmail,
              pointOfContactPhone,
            } = contractorRes.right
            setContactName(pointOfContactName)
            setContactEmail(pointOfContactEmail)
            setContactPhone(pointOfContactPhone)
            setContactRole('Contractor')
          } else {
            setContactRole(undefined)
            setContactName(person?.fullName ?? '')
            setContactEmail(person?.email ?? '')
            setContactPhone(person?.phone ?? '')
          }
        }
        setAddress(ca.locationAddress ?? '')
        setLocationValue(ca.location1 ? getSheaLocation(ca.location1) : '')

        const tasks = findComponent(components, 'task', false, false, 0)

        if (tasks && tasks.data) {
          const task = tasks.data.values.find((value: any) => {
            // return subData.correctiveAction.task == value.value
            return ca.task == value.value
          })
          setTaskValue(task?.label ?? '')
        } else {
          setTaskValue('')
        }

        const locations = findComponent(
          components,
          'location1',
          false,
          false,
          0,
        )
        if (locations && locations.data) {
          const location = locations.data.values.find((l: any) => {
            return l.value == ca.location1
          })
          setLocationValue(location?.label ?? '')
        } else {
          setLocationValue('')
        }

      } catch (err) {
        setTaskValue('')
        setLocationValue('')
      }
    }
    return ca
  }
  const loadCorrectiveActionComments = async () => {
    setCommentsError(undefined)
    setCommentsLoading(true)
    let res
    if (isPublicCA) {
      res = await getPublicCorrectiveActionsComment(id!, hash)
    } else {
      res = await getCorrectiveActionsComment(id!)
    }
    setCommentsLoading(false)

    if (res.isLeft()) {
      setCommentsError(res.left?.message ?? 'Error loading comments.')
      return
    }
    // TODO: check with backend, they should return in correct order
    const newComments = res.right?.data ?? []
    setComments(newComments.sort((a, b) => b.createdAt.localeCompare(a.createdAt)))
  }

  const init = async () => {
    setCorrectiveActionLoading(true)
    const ca = await loadCorrectiveAction()

    if (ca && !isPublicCA) {
      loadResponsibleParties(ca!)
      setExternalContact(ca!.additionalContactInfo)
    }
    setCorrectiveActionLoading(false)


    loadCorrectiveActionComments()
  }

  useEffect(() => {
    init()
  }, [])



  return (
    <CorrectiveActionPageContext.Provider
      value={{
        correctiveAction,
        correctiveActionLoadError,
        correctiveActionLoading,
        commentsLoading,
        commentsError,
        reloadCorrectiveAction: loadCorrectiveAction,
        setCorrectiveAction,
        user,
        viewMode,
        authorName: correctiveAction?.authorName ?? '',
        contractorName: correctiveAction?.contractorName ?? '',
        correctiveActionClosing,
        loadCorrectiveActionComments: loadCorrectiveActionComments,
        comments: comments,
        isContractor,
        isAdmin: viewMode === ECorrectiveActionViewMode.ADMIN,
        isOpen: correctiveAction?.status === ECorrectiveActionStatus.OPEN,
        isClosed: correctiveAction?.status === ECorrectiveActionStatus.CLOSED,
        isUnderReview:
          correctiveAction?.status === ECorrectiveActionStatus.UNDER_REVIEW,
        isShea,
        contractor,
        taskValue,
        locationValue,
        contactName,
        contactEmail,
        contactPhone,
        contactRole,
        lotName,
        address,
        showDeleteConfirmation,
        isPublicCA,
        hash,

        responsibleParties,
        contactPersons,
        loadContactsForContractor,
        setSelectedResponsibleParty,
        selectedResponsibleParty,
        selectedContactPerson,
        setSelectedContactPerson,
        externalContact,
        setExternalContact,
        updateCorrectiveAction


      }}
    >
      {children}
    </CorrectiveActionPageContext.Provider>
  )
}
