// ED-1386 https://edifyai.atlassian.net/browse/ED-1386
import React, { useContext, useEffect, useState } from 'react'
import useQuery from '../../../core/hooks/useQuery'

import { IForm } from '../../../domain/interfaces/IForm'
import { Either, Failure } from '../../../core/core'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { getForm, getFormsFromQuery } from '../../../domain/domain'
import { useParams } from 'react-router-dom'
import { ISearchResult } from '../../components/form/EdifySearch/EdifySearchDropdown'
import { DEFAULT_LIMIT } from '../../components/datagrid/EdifyDataGrid'
import { IFormList } from '../../../domain/interfaces/IFormList'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'

export interface FormsPageViewModelProps {
  queryHelper: Record<string, any>
  forms:IForm[]
  hiddenForms: IForm[]
  filteredForms: IForm[]
  hiddenFilteredForms: IForm[]
  isLoading: boolean
  totalCount: number
  formsError: string | undefined
  error: string | undefined
  getForms: () => void
  showHiddenFormsToggle: () => void
  // showDeleteConfirmation: (ids: string[]) => void
  loadMore: (paginationModel: GridPaginationModel) => void
  fetchForms: (fetchSkip: number, query: Record<string, any>) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  onFormSearch: (result: ISearchResult) => void
  // setPageForms: (forms: IForm[]) => void
  getFavoriteCount: () => void
  errorObj: any | undefined
  showHiddenForms: boolean
  setShowHiddenForms: (show: boolean) => void
  onFormFESearch: (searchKey: string) => void
  setFilteredForms: (forms:IForm[]) => void 
  resetFilteredForms: ()=> void
}

const FormPageContext = React.createContext<FormsPageViewModelProps | null>(
  null,
)

export function useFormsPageViewModel(): FormsPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(FormPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const FormsPageViewModel: React.FC<Props> = ({ children }) => {
  const { id } = useParams()
  const orgVm = useOrganizationProvider()
  const forms = orgVm.forms ?? []
  const hiddenForms = orgVm.hiddenForms ?? []

  const queryHelper = useQuery({
    ...(id ? { formId: id } : {}),
    skip: 0,
    limit: DEFAULT_LIMIT,
  })
  const { query, getQueryStringFromQuery, setNew } = queryHelper
  // const [forms, setForms] = useState<IForm[]>([])
  const [formsError, setFormsError] = useState<string | undefined>(undefined)
  const [errorObj, setErrorObj] = useState<any | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [showHiddenForms, setShowHiddenForms] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const [hiddenFilteredForms, setHiddenFilteredForms] = useState<IForm[]>([])
  const [filteredForms, setFilteredForms] = useState<IForm[]>([])

  const loadForms = async (initialLoad = false) => {
    setFormsError(undefined)
    setIsLoading(true)
    // setForms([])
    setFilteredForms(forms)
    setHiddenFilteredForms(hiddenForms)
    setIsLoading(false)
  }

  const showHiddenFormsToggle = async () => {
    setShowHiddenForms(!showHiddenForms)

    // fetchForms(0, {...query, includeHidden: !showHiddenForms})
  }

  const fetchForms = async (fetchSkip: number, query: Record<string, any>) => {
    setFormsError(undefined)
    setIsLoading(true)

    const newQuery = { ...query, skip: fetchSkip, includeHidden: true }

    const res: Either<Failure, IFormList> = await getFormsFromQuery(
      getQueryStringFromQuery(newQuery),
    )
    setIsLoading(false)
    if (res.isLeft()) {
      setFormsError(res.left?.message)
      setErrorObj(res.left)
      return
    }
    setTotalCount(res.right?.total_count || 0)
    setHiddenFilteredForms(res.right?.data as IForm[])
    setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchForms(fetchSkip, query)
  }

  const getFavoriteCount = () => {
    return forms.filter((f) => f.isFavorited).length
  }

  // const deleteSelectedForms = async (ids: string[]) => {

  //   const res = await adminDeleteForms(ids)
  //   const formString = ids.length == 1 ? 'Form' : 'Forms'
  //   const formStringLower = ids.length == 1 ? 'form' : 'forms'
  //   if (res.isRight()) {
  //     SuccessToast({ title: `${formString} removed successfully.` })
  //     fetchForms(query.skip, query)
  //   } else {
  //     ErrorToast({ title: `Error removing ${formStringLower}.` })
  //   }
  // }

  // const showDeleteConfirmation = async (ids: string[]) => {
  //   const confirmMessage = ids.length > 1 ? `${ids.length} forms` : '1 form'

  //   showConfirmationDeleteMessage(confirmMessage, () =>
  //     deleteSelectedForms(ids),
  //   )
  // }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    const newQuery = {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    }
    fetchForms(0, newQuery)
  }

  // This might need to be changed (UX)..
  const onFormSearch = async (formSearchResult: ISearchResult) => {
    // check to see if it is already in the page
    const matchedForm = forms.find((p) => p.id == formSearchResult.id)
    if (matchedForm) {
      setFilteredForms([matchedForm])
      setTotalCount(1)
      return
    }
    // if not grab from API
    if (!matchedForm) {
      setIsLoading(true)
      setFormsError(undefined)
      setIsLoading(false)
      const res = await getForm(formSearchResult.id)
      if (res.right) {
        setFilteredForms([res.right as IForm])
        setTotalCount(1)
      } else {
        ErrorToast({ title: 'Could not find form' })
      }
    }
  }

  const onFormFESearch = async (searchKey: string) => {
    // If search key is empty, return all forms
    setIsLoading(true)

    if (!searchKey.trim()) {
      setFilteredForms(forms)
      setTotalCount(forms.length)
      setIsLoading(false)
      setSearchValue('')
      return
    }

    // Convert search key to lowercase for case-insensitive matching
    const searchLower = searchKey.toLowerCase()
    setSearchValue(searchLower)

    // Filter forms that contain the search key in their title
    const formGroup = showHiddenForms ? hiddenForms : forms
    const matchedForms = formGroup.filter((form) =>
      form.title.toLowerCase().includes(searchLower),
    )

    setTotalCount(matchedForms.length)
    if(showHiddenForms){
      setHiddenFilteredForms(matchedForms)
    } else {
      setFilteredForms(matchedForms)
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 100)

    return
  }

  const resetFilteredForms = ()=>{
    setIsLoading(true)
    setTimeout(() => {
      setFilteredForms(forms)
      setHiddenFilteredForms(hiddenForms)
      setSearchValue('')
      setIsLoading(false)
    }, 100)
  }

  useEffect(() => {
    loadForms(true)
  }, [orgVm.forms])

  useEffect(() => {
    onFormFESearch(searchValue)
  }, [showHiddenForms])

  return (
    <FormPageContext.Provider
      value={{
        queryHelper,
        forms,
        hiddenForms: hiddenForms,
        filteredForms,
        hiddenFilteredForms,
        isLoading,
        formsError,
        fetchForms,
        getForms: loadForms,
        // showDeleteConfirmation,
        loadMore,
        totalCount,
        sortClickedHandler,
        onFormSearch,
        error: formsError,
        // setPageForms: setForms,
        getFavoriteCount,
        errorObj,
        showHiddenForms,
        setShowHiddenForms,
        showHiddenFormsToggle,
        onFormFESearch,
        setFilteredForms,
        resetFilteredForms
      }}
    >
      {children}
    </FormPageContext.Provider>
  )
}
export default FormPageContext
