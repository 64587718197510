import { Box, Tab, Tabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IMojoFormioRenderer1Props } from '../../../pages/submission/MojoFormRenderer1'
import SigneeList from '../../../pages/submission/SingeeList'
import { EdifyButton } from '../../buttons'
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  ErrorOutline,
} from '@mui/icons-material'
import { AppColors } from '../../../Theme'
import { getCurrentUser } from '../../../../domain/domain'
import { MojoFormRender } from '../../../pages/submission/MojoFormRender'
import {
  ISubmissionLoader,
} from '../../../../core/hooks/useSubmission'
import { IFormSubmitter } from '../../../../core/hooks/useFormSubmitter'
import AdditionalForms from '../../../pages/submission/AdditionalForms'
import { IForm } from '../../../../domain/interfaces/IForm'
import { IFormLoader } from '../../../../core/hooks/useFormLoader'

interface ITabHeader {
  title: string
  formId: string
  form?: IForm
  isPostSubmission: boolean
  submissionId?: string | undefined
  submission?: any
  formReadOnly?: IForm
  isAnon?: boolean
  canEdit?: boolean
  canCreate?: boolean
  psdIndex?: number
  readOnly?: boolean
  formSubmitter?: IFormSubmitter
  errors?: string[] | undefined
}

// TODO use this interface
interface IMojoTabFormProps {
  orgId: string
  formId: string
  submissionId?: string
  isAnon?: boolean
  readOnly?: boolean
  mode: 'CREATE' | 'EDIT' | 'VIEW'
  lastActiveTab?: number
  formSubmitter?: IFormSubmitter
  submission?: any
  setLastActiveTab?: (tabIndex: number) => void
  submissionLoader?: ISubmissionLoader
  formLoader?: IFormLoader
  options?: any
  noContainer?: boolean
  setActiveFormId?: any
}

const MojoTabForm: React.FC<IMojoFormioRenderer1Props> = (props) => {
  const user = getCurrentUser()
  const userId = user?.id ?? undefined
  const [value, setValue] = React.useState(props.lastActiveTab ?? 0)
  const [tabHeaders, setTabHeaders] = useState<ITabHeader[]>([])
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    const { setLastActiveTab,setActiveFormId } = props
    setLastActiveTab && setLastActiveTab(newValue)
    setActiveFormId && setActiveFormId(tabHeaders[newValue].formId)
  }

  const showAsLink = props.form?.showAsLink ?? false 
  const { submission, formSubmitter } = props
  // show post data even if draft
  const postSubmissionData = submission?.postSubmissionData ?? []

  // TAB FORM NEEDS FORM SUBMITTER TODO FINALIZE TYPE
  if (!formSubmitter) return <div>Loading...</div>

  const getParentFormTab = () => {
    // const form = formSubmitter.forms[0]
    const form = formSubmitter.additionalFormSubmissions[0]
    if (!form) return
    return {
      title: form.title,
      // formId: form.id,
      isPostSubmission: false,
      // submissionId: undefined,
      ...(props.mode == 'VIEW' && { submissionId: submission!.id }),
      isAnon: false,
      canEdit: true,
      canCreate: true,
    }
  }
  const getCreateTabs = () => {
    const connectedFormData: ITabHeader[] =
      formSubmitter.additionalFormSubmissions.map((af: any) => {
        return {
          title: af.title,
          formId: af.id || af.formId,
          form: af.form,
          formReadOnly: af.formReadOnly,
          submission: af.submission,
          errors: af.errors,
          isPostSubmission: false,
          isAnon: props.isAnon,
          canEdit: true,
          canCreate: true,
          submissionId: undefined,
          readOnly: props.readOnly,
        }
      })
    const linkFormData = formSubmitter.linkFormsState.reduce(
      (acc: ITabHeader[], lf: any) => {
        // if multiple Sub per form
        if (lf.submissions.length > 1) {
          const submissions = lf.submissions.map((submission: any) => {
            return {
              title: lf.title,
              formId: lf.id,
              form: lf.form,
              formReadOnly: lf.formReadOnly,
              isPostSubmission: false,
              submissionId: submission.id,
              submission: submission,
              readOnly: true,
            }
          })
          return [...acc, ...submissions]
        }
        const lfSubmission: ITabHeader = {
          title: lf.title,
          formId: lf.id,
          form: lf.form,
          formReadOnly: lf.formReadOnly,
          isPostSubmission: false,
          submissionId: lf.submissions[0].id,
          submission: lf.submissions[0],
          readOnly: true,
        }
        acc.push(lfSubmission)
        return acc
      },
      [],
    )
    let postSubmissionHeaders: any = []
    if (props.isAnon) {
      postSubmissionHeaders = postSubmissionData.map((psd: any, i: number) => {
        return {
          title: psd.childFormName,
          formId: psd.childFormId,
          psdIndex: i,
          isPostSubmission: true,
        }
      })
    }
    const linkTabs = showAsLink ?  [] : linkFormData
    setTabHeaders([
      ...connectedFormData,
      ...linkTabs,
      ...postSubmissionHeaders,
    ])
  }

  const getViewTabs = () => {
    const connectedFormData: ITabHeader[] =
      formSubmitter.additionalFormSubmissions.map((af: any) => {
        return {
          title: af.formTitle || af.title,
          formId: af.id || af.formId,
          form: af.form,
          formReadOnly: af.formReadOnly,
          // setDataCallback: af.setDataCallback,
          submissionId: af.submission ? af.submission.id : undefined,
          submission: af.submission,
          isPostSubmission: false,
          readOnly: props.readOnly,
        }
      })

    const linkFormData = formSubmitter.linkFormsState.reduce(
      (acc: ITabHeader[], lf: any) => {
        // if multiple Sub per form
        if (lf.submissionIds.length > 1) {
          const submissions = lf.submissionIds.map(
            (submissionId: string, i: number) => {
              return {
                title: lf.title,
                formId: lf.id,
                form: lf.form,
                formReadOnly: lf.formReadOnly,
                isPostSubmission: false,
                submissionId: submissionId,
                submission: lf.submissions ? lf.submissions[i] : undefined,
                readOnly: true,
              }
            },
          )
          return [...acc, ...submissions]
        }
        const lfSubmission: ITabHeader = {
          title: lf.title,
          formId: lf.id,
          form: lf.form,
          formReadOnly: lf.formReadOnly,
          isPostSubmission: false,
          submissionId: lf.submissionIds[0],
          submission: lf.submissions ? lf.submissions[0] : undefined,
          readOnly: true,
        }
        acc.push(lfSubmission)
        return acc
      },
      [],
    )

    const postSubmissionHeaders = postSubmissionData.map(
      (psd: any, i: number) => {
        return {
          title: psd.childFormName,
          formId: psd.childFormId,
          psdIndex: i,
          isPostSubmission: true,
        }
      },
    )
    const linkTabs = showAsLink ? [] : linkFormData
    setTabHeaders([
      // getParentFormTab(),
      ...connectedFormData,
      ...linkTabs,
      ...postSubmissionHeaders,
    ])
  }

  useEffect(() => {
    if (!formSubmitter || formSubmitter.loading) return
    if (props.mode === 'CREATE' || props.mode === 'EDIT') {
      getCreateTabs()
    }

    if (props.mode === 'VIEW') {
      getViewTabs()
    }
  }, [
    formSubmitter.loading,
    props.submission,
    formSubmitter.additionalFormSubmissions,
    formSubmitter.linkFormsState,
  ])

  const nextTab = () => {
    const { setLastActiveTab,setActiveFormId } = props
    const newValue = value + 1
    setValue(newValue)
    setLastActiveTab && setLastActiveTab(newValue)
    setActiveFormId && setActiveFormId(tabHeaders[newValue].formId)
  }
  const prevTab = () => {
    const { setLastActiveTab, setActiveFormId } = props
    const newValue = value - 1
    setValue(newValue)
    setLastActiveTab && setLastActiveTab(newValue)
    setActiveFormId && setActiveFormId(tabHeaders[newValue].formId)
  }
  const renderFormTabButtonSection = () => {
    const g700 = AppColors.gray700
    if (tabHeaders.length <= 1) return null
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '24px',
          gap: '24px',
        }}
      >
        <EdifyButton
          className='tabButton tabButtonGroupLeft'
          secondary
          buttonStyle={{
            borderRadius: '30px',
            marginRight: '0px',
            padding: '4px !important',
          }}
          hide={value == 0}
          disabled={value == 0}
          onClick={prevTab}
          icon={<ChevronLeftRounded sx={{ color: g700 }} />}
          title='Previous Form'
        />

        <EdifyButton
          className='tabButton tabButtonGroupRight'
          secondary
          buttonStyle={{
            borderRadius: '30px',
          }}
          data-testid='NextButton'
          hide={value == tabHeaders.length - 1}
          onClick={nextTab}
          iconRight={true}
          icon={<ChevronRightRounded sx={{ color: g700 }} />}
          title='Next Form'
        />
      </Box>
    )
  }

  const renderAdditionalFormsPanels = () => {
    return tabHeaders.map((th, i) => {
      if (th.isPostSubmission) {
        return (
          <CustomTabPanel key={`CustomTabPanel-${i}`} value={value} index={i}>
            <SigneeList
              userId={userId}
              orgId={props.orgId}
              parentSubmission={props.submission!}
              isAnon={props.isAnon}
              postSubmissionData={postSubmissionData[th.psdIndex ?? 0]}
              parentSubmissionReloader={props.reloadSubmission!}
            />
          </CustomTabPanel>
        )
      }

      return (
        <CustomTabPanel key={`CustomTabPanel-${i}`} value={value} index={i}>
          <MojoFormRender
            orgId={props.orgId}
            {...th}
            submission={th.submission}
            readOnly={th.readOnly}
            formReadOnly={th.formReadOnly}
            setDataCallback={formSubmitter.setDataCallback}
          />
        </CustomTabPanel>
      )
    })
  }

  if (!props.form) return <div>Loading.....</div>

  if (!formSubmitter.additionalFormSubmissions.length)
    return <div>Loading..</div>

  return (
    <Box>
      {tabHeaders.length > 1 && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            {tabHeaders.map((th, i) => {
              return (
                <Tab
                  key={`${th.formId}-${i}`}
                  label={th.title}
                  icon={
                    th.errors && <ErrorOutline sx={{ color: AppColors.d700 }} />
                  }
                  iconPosition='end'
                />
              )
            })}
          </Tabs>
        </Box>
      )}
      {renderAdditionalFormsPanels()}
      {value == 0 && (
        <AdditionalForms
          form={formSubmitter.additionalFormSubmissions[0].form}
          formSubmitter={formSubmitter}
          viewOnly={props.mode == 'VIEW'}
        />
      )}
      {renderFormTabButtonSection()}
    </Box>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

export default MojoTabForm
