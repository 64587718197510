import React, { useEffect, useRef, useState, useMemo } from 'react'
import DataGridHeader from './DataGridHeader'
import { Box } from '@mui/material'
import { AppColors } from '../../Theme'
import { StyledDataGrid } from './StyledDataGrid'
import {
  DataGridProps,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid'
import { NoRowsOverlay } from './NoRowsOverLay'
import DataGridFooter from './DataGridFooter'
import InnerPageContainer from '../inner-page-container/InnerPageContainer'
import pluralize from 'pluralize'

export interface EdifyDataGridProps extends DataGridProps {
  title: string
  error?: string
  rows: Array<any>
  columns: Array<any>
  setSelectionModel: (value: GridRowSelectionModel) => void
  selectionModel?: GridRowSelectionModel
  handleRowClicked?: (row: any) => void
  renderHeaderButtons?: JSX.Element
  loadMoreRows?: (gridPaginationModel: GridPaginationModel) => void
  reload?: () => void
  rowCount: number
  sortClickedHandler?: (sorter: GridSortModel) => void
  count?: number
  large?: boolean
  noRowsText?: string
  disableSort?: boolean
  checkboxSelection?: boolean
  includeCount?: boolean
  formatTitle?: boolean
  initialSortField?: string
  initSort?: GridSortModel
  extraSlots?: any
  clientSideProcessing?: boolean
}

export const DEFAULT_LIMIT = 20
export const TABLE_ROW_HEIGHT = 64

export const EdifyDataGridV2: React.FC<EdifyDataGridProps> = ({
  title,
  rows,
  setSelectionModel,
  renderHeaderButtons,
  loading,
  columns,
  handleRowClicked,
  rowCount,
  paginationMode,
  rowSelectionModel,
  loadMoreRows,
  error,
  sortClickedHandler,
  reload,
  disableSort = false,
  noRowsText = 'No rows found',
  checkboxSelection = true,
  includeCount = true,
  formatTitle = true,
  // NEED TO SORT BE FAVORITED TRUE THAN BY TITLE [a-z]
  initSort = [
    { field: 'favorite', sort: 'desc' },  // favorite true first
  ],
  
  extraSlots,
  clientSideProcessing = false,
}) => {
  // Initialize pagination model
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_LIMIT,
  })
  const prevPaginationModelRef = useRef(paginationModel)
  const [displayCount, setDisplayCount] = useState<number>(rowCount ?? 0)
  const [sorter, setSorter] = useState<GridSortModel>(initSort)
  
  // Store the complete dataset for client-side mode
  const [localData, setLocalData] = useState<Array<any>>(rows)
  
  // Force re-render when pagination changes - key technique
  const [paginationKey, setPaginationKey] = useState(0)
  
  // Determine if we're using server or client mode
  const isServer = !clientSideProcessing && paginationMode === 'server'

  // Update local data whenever rows prop changes
  useEffect(() => {
    if (clientSideProcessing) {
      setLocalData(rows)
      // Reset pagination when data source changes
      if (paginationModel.page !== 0) {
        setPaginationModel({...paginationModel, page: 0})
      }
    }
  }, [rows, clientSideProcessing])

  // Process the data using useMemo instead of a separate state
  // This ensures the visible rows are always calculated from the latest data
  const visibleRows = useMemo(() => {
    if (!clientSideProcessing) {
      return rows
    }
    
    let processed = [...localData]
    
    // Apply multi-field sorting
    if (sorter && sorter.length > 0) {
      processed = processed.sort((a, b) => {
        // First sort by favorite
        if (sorter.some(s => s.field === 'favorite')) {
          const favoriteSort = sorter.find(s => s.field === 'favorite')
          const aFavorite = Boolean(a.favorite)
          const bFavorite = Boolean(b.favorite)
          
          if (aFavorite !== bFavorite) {
            // Respect the sort direction
            return favoriteSort?.sort === 'asc' 
              ? (aFavorite ? 1 : -1)  // for asc: false values first
              : (aFavorite ? -1 : 1)  // for desc: true values first
          }
        }
        
        // Then sort by title
        if (sorter.some(s => s.field === 'title')) {
          const titleSort = sorter.find(s => s.field === 'title')
          const aTitle = a.title || ''
          const bTitle = b.title || ''
          // Respect the sort direction
          return titleSort?.sort === 'asc'
            ? aTitle.localeCompare(bTitle)
            : bTitle.localeCompare(aTitle)
        }
        
        // For any other field that might be sorted
        const sortItem = sorter[0]
        if (sortItem && a[sortItem.field] !== undefined && b[sortItem.field] !== undefined) {
          const aValue = a[sortItem.field]
          const bValue = b[sortItem.field]
          
          // Handle different types appropriately
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortItem.sort === 'asc' 
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue)
          } else {
            // For numbers, booleans, etc.
            return sortItem.sort === 'asc'
              ? (aValue > bValue ? 1 : -1)
              : (aValue > bValue ? -1 : 1)
          }
        }
        
        return 0
      })
    }
    
    // Apply pagination
    const startIndex = paginationModel.page * paginationModel.pageSize
    const endIndex = Math.min(startIndex + paginationModel.pageSize, processed.length)
    
    return processed.slice(startIndex, endIndex)
    
  }, [clientSideProcessing, localData, sorter, paginationModel])
  

  // Handle pagination changes
  const handlePaginationModelChange = (newModel: GridPaginationModel) => {

    
    if (clientSideProcessing) {
      setPaginationModel(newModel)
      // Force a re-render by incrementing the key
      setPaginationKey(prev => prev + 1)
      return
    }
    
    // Server-side processing logic
    if (!loadMoreRows) return
    loadMoreRows(newModel)
    setPaginationModel(newModel)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    const oldSorter: GridSortItem = model.length === 0 ? sorter[0] : model[0]
    const newSorter: GridSortItem = updateSorter(sorter[0], oldSorter)
    
    // Reset to page 0 when sort changes
    setPaginationModel({ page: 0, pageSize: DEFAULT_LIMIT })
    setSorter([newSorter])
    
    if (!clientSideProcessing && sortClickedHandler) {
      sortClickedHandler([newSorter])
    }
  }

  function updateSorter(
    oldSorter: GridSortItem,
    newSorter: GridSortItem,
  ): GridSortItem {
    console.log({oldSorter})
    console.log({newSorter})
    if (oldSorter.field === newSorter.field) {
      // The fields are the same, revert the sort value
      const newSort = oldSorter.sort === 'asc' ? 'desc' : 'asc'
      return { ...oldSorter, sort: newSort }
    } else {
      // The fields are different, return a new object with the field of the newState and sort as 'desc'
      return { field: newSorter.field, sort: 'desc' }
    }
  }

  const getTitle = (title: string) => {
    if (error || !formatTitle || rowCount == -1) {
      return title
    }
    if (includeCount) {
      return pluralize(title, displayCount ?? 0, true)
    }
    return title
  }

  useEffect(() => {
    setDisplayCount(clientSideProcessing ? localData.length : rowCount)
  }, [rowCount, localData, clientSideProcessing])

  // Track and manage pagination state when filters/sorts are applied
  useEffect(() => {
    const prevPaginationModel = prevPaginationModelRef.current
    if (loading) {
      // Only reset pagination when:
      // 1. The current page hasn't changed from previous state (indicating this isn't a pagination action)
      // 2. We're not already on the first page
      if (
        prevPaginationModel.page === paginationModel.page &&
        paginationModel.page !== 0
      ) {
        setPaginationModel({ page: 0, pageSize: DEFAULT_LIMIT })
      }
      prevPaginationModelRef.current = paginationModel
    }
  }, [loading])

  const showFooter = clientSideProcessing 
    ? localData.length > DEFAULT_LIMIT 
    : rowCount > DEFAULT_LIMIT

  if (error) {
    return (
      <Box data-testid='EdifyDataGridError'>
        <DataGridHeader title={title}></DataGridHeader>
        <InnerPageContainer
          innerPageError={error}
          innerPageReload={reload || undefined}
          sx={{
            padding: '24px',
            borderTop: 'none',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            borderLeft: `1px solid ${AppColors.neutral600}`,
            borderRight: `1px solid ${AppColors.neutral600}`,
            borderBottom: `1px solid ${AppColors.neutral600}`,
          }}
        />
      </Box>
    )
  }

  return (
    <>
      <DataGridHeader title={getTitle(title)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: 'auto',
            ml: 24,
          }}
        >
          {renderHeaderButtons && renderHeaderButtons}
        </Box>
      </DataGridHeader>
      <Box
        sx={{
          height: error ? '250px' : rows.length == 0 ? '109px' : 'auto',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: AppColors.baseWhite,
        }}
      >
        <StyledDataGrid
          // Use a key to force remounting when pagination changes in client-side mode
          key={clientSideProcessing ? `grid-${paginationKey}` : undefined}
          sortingMode={clientSideProcessing ? 'server' : 'server'}
          rowHeight={TABLE_ROW_HEIGHT}
          checkboxSelection={checkboxSelection}
          className={!checkboxSelection ? 'no-check-box' : ''}
          disableRowSelectionOnClick
          disableColumnMenu
          onRowSelectionModelChange={(
            rowSelectionModel: GridRowSelectionModel,
          ) => {
            setSelectionModel(rowSelectionModel)
          }}
          rows={clientSideProcessing ? visibleRows : rows}
          columns={columns}
          keepNonExistentRowsSelected={true}
          rowCount={clientSideProcessing ? localData.length : rowCount}
          loading={loading}
          pageSizeOptions={[DEFAULT_LIMIT]}
          paginationModel={paginationModel}
          paginationMode={clientSideProcessing ? 'server' : (isServer ? 'server' : 'client')}
          onRowClick={handleRowClicked}
          onPaginationModelChange={handlePaginationModelChange}
          rowSelectionModel={rowSelectionModel}
          sortModel={disableSort ? [] : sorter}
          onSortModelChange={handleSortModelChange}
          slots={{
            noRowsOverlay: () => (
              <NoRowsOverlay
                text={!error && (clientSideProcessing ? localData.length === 0 : rowCount === 0) ? noRowsText : undefined}
                error={error}
                reload={reload}
              />
            ),
            footer: () =>
              showFooter ? (
                <DataGridFooter
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  totalCount={clientSideProcessing ? localData.length : rowCount}
                />
              ) : null,
            ...extraSlots,
          }}
        />
      </Box>
    </>
  )
}