import React from 'react'
import { Switch, Box, Grid } from '@mui/material'
import { AppColors } from '../../Theme'
import PermissionButtonGroup from './PermissionButtonGroup'
import { ETypography } from '../../components/fonts/ETypography'
import { IPermission } from '../../../domain/interfaces/IRole'
import { camelCaseToNormal } from '../../../core/utils/text-formaters/TextFormatters'
import { addButtonValuesToRoleData, EnhancedPermission } from './helpers'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import _ from 'lodash'

type PermissionObject = {
  key: string
  value: string
}

interface RolesRendererProps {
  roleData: IPermission[]
  setRoleData: React.Dispatch<React.SetStateAction<IPermission[]>>
  disabled?: boolean
  hideButtons?: boolean
  setHideForms?: React.Dispatch<React.SetStateAction<boolean>>
}

const RolesRenderer: React.FC<RolesRendererProps> = ({
  roleData,
  setRoleData,
  disabled = false,
}) => {
  const { flags } = useOrganizationProvider()
  const updateFeatureAccess = (
    item: EnhancedPermission,
  ): EnhancedPermission => {
    let values = []
    if (item.readonly) {
      values = item.permissions ? [item.permissions.read] : []
    } else {
      values = Object.values(item.permissions)
    }

    // case when there are no permissions
    if (values.length == 0) {
      return {
        ...item,
        featureAccess: !item.featureAccess ? 1 : 0,
      }
    }

    const allZero =
      item.permissions &&
      values.every((value) => {
        return value === 0
      })
    return {
      ...item,
      featureAccess: allZero ? 0 : 1,
    }
  }

  const handleToggle = (index: number) => {
    setRoleData((prevData) => {
      const newData = _.cloneDeep(prevData)
      let item = { ...newData[index] } as EnhancedPermission
      let values = []
      if (item.readonly) {
        values = item.permissions ? [item.permissions.read] : []
      } else {
        values = Object.values(item.permissions)
      }
      const allZero =
        item.permissions &&
        values.every((value) => {
          return value === 0
        })

      if (item.permissions) {
        Object.keys(item.permissions).forEach((key) => {
          if (item.readonly) {
            if (key == 'read') {
              item.permissions.read = allZero ? 2 : 0
            }
          } else {
            item.permissions[key] = allZero ? 2 : 0
          }
        })
      }

      item = updateFeatureAccess(item)
      newData[index] = item
      return newData
    })
  }

  const handlePermissionChange = (
    index: number,
    permissionType: string,
    value: number,
  ) => {
    setRoleData((prevData) => {
      const newData = _.cloneDeep(prevData)
      let item = { ...newData[index] } as EnhancedPermission
      if (item.permissions) {
        item.permissions = {
          ...item.permissions,
          [permissionType]: value,
        }
      }
      item = updateFeatureAccess(item)
      newData[index] = item
      return newData
    })
  }

  //
  function processPermissionKeys(permissionKeys: string[]): PermissionObject[] {
    const expectedKeys: readonly string[] = ['read', 'create', 'edit'] as const
    const keyValueMap: Record<string, string> = {
      read: 'View',
      create: 'Create',
      edit: 'Edit',
    }

    if (
      permissionKeys.length === expectedKeys.length &&
      expectedKeys.every((key) => permissionKeys.includes(key))
    ) {
      // Return the specific order for the expected keys
      return expectedKeys.map((key) => ({
        key,
        value: keyValueMap[key],
      }))
    } else {
      // Sort keys alphabetically and return key-value pairs
      return permissionKeys
        .map((key) => {
          const uiKey =
            key === 'read' ? 'View' : key === 'changeState' ? 'Status' : key
          return {
            key: key as string,
            value: camelCaseToNormal(uiKey),
          }
        })
        .sort((a, b) =>
          a.value == 'View' ? -1 : a.value.localeCompare(b.value),
        )
    }
  }

  const enhancedRoleData = addButtonValuesToRoleData(roleData)

  const isOrgFeatureDisabled = (permission: EnhancedPermission) => {
    if (
      permission.id == 'documentFeatureAccess' &&
      !flags.isDocumentLibraryEnabled
    ) {
      return true
    }
    if (permission.id == 'locationFeatureAccess' && !flags.isLocationEnabled) {
      return true
    }
    return false
  }

  const disablePermission = (permission: EnhancedPermission) => {
    if (isOrgFeatureDisabled(permission)) return true
    return disabled
  }

  const renderPermissionButtons = (item: EnhancedPermission, index: number) => {
    if (!item.permissions || item.name == 'Submission Management') return null

    const permissionKeys = Object.keys(item.permissions)
    const permissionObjects = processPermissionKeys(permissionKeys)
    const permissionObjectsLength = permissionObjects.length
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            lg: 'row',
            md: 'column',
          },
        }}
      >
        <Grid
          container
          spacing={2}
          // justifyContent='space-around'
          alignItems='flex-start'
          justifyContent="flex-start"
          sx={{ flex: 1 }}
        >
          {permissionObjects.map(({ key, value }) => (
            <Grid
              key={key}
              item
              sm={12}
              md={12}
              lg={4} // Always use 4 columns (1/3 of the space)
              sx={{
                marginBottom: {
                  lg: '4px',
                },
                backgroundColor:
                  index % 2 === 0 ? AppColors.baseWhite : AppColors.neutral100,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  // alignItems: 'center',
                  padding: '12px 0px',
                  height: '100%',
                }}
              >
                <ETypography
                  font='MS'
                  color='gray900'
                  sx={{
                    marginRight: '12px',
                    // minWidth:'90px'
                    minWidth: {
                      lg: 'auto',
                      md: '120px',
                      sm: '100px',
                    },
                  }}
                >
                  {value}
                </ETypography>
                <PermissionButtonGroup
                  permission={item}
                  disabled={disablePermission(item)}
                  permissionKey={key}
                  value={item.permissions[key]}
                  onChange={(newValue) =>
                    handlePermissionChange(index, key, newValue)
                  }
                  // showSelfOption={key !== 'create'}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  }
  // console.log({enhancedRoleData})
  return (
    <Box>
      {enhancedRoleData.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            padding: '12px 24px',
            backgroundColor:
              index % 2 == 0 ? AppColors.baseWhite : AppColors.neutral100,
          }}
        >
          <Grid
            container
            alignItems='center'
            justifyContent='space-between'
            sx={{ marginBottom: '12px' }}
          >
            <Grid item sx={{ marginBottom: '24px' }}>
              <ETypography
                font='SM'
                color='gray700'
                sx={{ marginRight: '12px' }}
              >
                {item.name ?? item.title}{' '}
                {isOrgFeatureDisabled(item) && '(Turned off for organization)'}
              </ETypography>
              {item.description && (
                <ETypography font='SR' color='gray50'>
                  {item.description}
                </ETypography>
              )}
            </Grid>
            <Grid item>
              <Switch
                disabled={disablePermission(item)}
                checked={item.featureAccess === 1}
                onChange={() => handleToggle(index)}
              />
            </Grid>
          </Grid>
          {item.featureAccess === 1 && renderPermissionButtons(item, index)}
        </Box>
      ))}
    </Box>
  )
}

export default RolesRenderer
