import { Either, Failure } from '../../../core/core'
import { IContractor, IContractorDeleted, IContractorList, IMinimalCommonList } from '../../../domain/interfaces/IContractor'
import { contractorParams } from '../../../presentation/pages/contractor/ContractorPageViewModel'
import { httpDelete, httpDeleteWithPayload, httpGet, httpPost, httpPut } from '../../data'

export async function getContractors(
  queryString: string,
): Promise<Either<Failure, IContractorList>> {
  const res: Either<Failure, IContractorList> = await httpGet(
    `/contractors?${queryString}`,
    false,
  )

  return res
}

export async function getResponsibleParties(

): Promise<Either<Failure, IMinimalCommonList>> {
  const res: Either<Failure, IMinimalCommonList> = await httpGet(
    '/minimal/responsibleParty/',
    false,
  )

  return res
}

export async function getContractorById(
  id: string,
): Promise<Either<Failure, IContractor>> {
  const res: Either<Failure, IContractor> = await httpGet(
    `/contractors/${id}`,
    true,
  )

  return res
}


export async function createContractor(contractor: contractorParams): Promise<Either<Failure, IContractor>> {
  const res: Either<Failure, IContractor> = await httpPost(
    '/contractors',
    contractor,
    undefined, //no additional headers
    false, // direct data parsing is false
  )

  return res
}

export async function editContractor(contractor: contractorParams, id: string): Promise<Either<Failure, IContractor>> {
  const res: Either<Failure, IContractor> = await httpPut(
    `/contractors/${id}`,
    contractor,
    false, // direct data parsing is false
  )

  return res
}

export async function deleteContractor(
  contractorId: string,
): Promise<Either<Failure, IContractor>> {
  const res: Either<Failure, IContractor> = await httpDelete(
    `/contractors/${contractorId}`,
    undefined, //no additional headers
  )

  return res
}

export async function searchContractors(
  skip: number,
  limit?: number,
  key?: string,
): Promise<Either<Failure, IContractorList>> {
  const urlStr = `/contractors?searchKey=${key}&skip=${skip}&limit=${limit ?? 8}`
  const res: Either<Failure, IContractorList> = await httpGet(urlStr
    ,
    false,
  )
  return res
}

// TODO: Add when BE updated
export async function deleteContractors(
  contractorIds: string[],
): Promise<Either<Failure, IContractorDeleted[]>> {
  const res: Either<Failure, IContractorDeleted[]> = await httpDeleteWithPayload(
    '/contractors/bulk', { contractorIds }
  )
  return res
}
