import {
  Avatar,
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AppColors, AppFonts } from '../../Theme'
import { EdifyAppBar } from '../../components/banners/app-bar/EdifyAppBar'
import { getCurrentOrg, getCurrentPerson, setCurrentOrg } from '../../../domain/domain'
import { Link, useNavigate } from 'react-router-dom'
import BusinessIcon from '@mui/icons-material/Business'
import CloseIcon from '@mui/icons-material/Close'
import { deepOrange, deepPurple, green } from '@mui/material/colors'
import { IVerifyOTPPayload } from '../../../domain/interfaces/IUserDetails'
import { EdifyButton } from '../../components/buttons'
import { LaunchIcon } from '../../../core/components/icons/LaunchIcon'
import PageContainer from '../../components/page/PageContainer'
import { ROUTE_CORRECTIVE_ACTIONS } from '../submissions/SubmissionsPage'
import { ROUTE_DASHBOARD } from '../dashboard/DashboardPage'
import { dateToMMMDYYYY } from '../../../core/core'
import { ROUTE_ADMIN_ORGS } from '../admin-org-selection/AdminOrgsPage'
import { useGlobalProvider } from '../../../providers/GlobalProvider'
import { useTranslation } from 'react-i18next'
import { ETypography } from '../../components/fonts/ETypography'
import { formatSnakeCase } from '../../../core/utils/text-formaters/TextFormatters'
import { getTempLanguage } from '../../../i18n'
import { removeFromLocalStorage } from '../../../data/data'

export const ROUTE_ORG_SELECTION = '/org-selection'

export const OrgSelectionPage: React.FC = () => {
  const [person, setPerson] = useState<IVerifyOTPPayload>()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    setIsGlobalUserView,
    isGlobalUser,
    singleAccountContractor,
    singleAccountMember,
  } = useGlobalProvider()

  useEffect(() => {
    removeFromLocalStorage('org')
    const person = getCurrentPerson()
    setPerson(person)
    setIsGlobalUserView(false)
    // Contractor/Contact view case no orgs
    if (singleAccountContractor && !isGlobalUser) {
      navigate(ROUTE_CORRECTIVE_ACTIONS)
      return
    }
    if (singleAccountMember && !isGlobalUser) {
      navigate(ROUTE_DASHBOARD)
      return
    }
  }, [])

  const renderAdminLink = () => {
    if (isGlobalUser) {
      return (
        <>
          {/* <pre>{JSON.stringify(person, null, 2)}</pre> */}
          <Link to={ROUTE_ADMIN_ORGS}>
            Access all other Organizations as Global Admin
          </Link>
        </>
      )
    }
    return <></>
  }
  const contacts = person?.contractors ? person?.contractors : undefined
  const users = person?.users ? person?.users : undefined
  const orgs = person?.orgs ? person?.orgs : undefined

  const findOrgRole = (orgId: string) => {
    if (!users || !orgs) return 'Not Found'
    const user = users.find((role: any) => role.orgId === orgId)
    const name = user?.roles[0] ? user.roles[0].name : 'Not Found'
    return formatSnakeCase(name)
  }
  return (
    <EdifyAppBar barItems={[]} showDrawer={false}>
      <Stack
        sx={{
          backgroundColor: AppColors.g100,
          height: '100%',
          minHeight: 'calc("100vh - 56px")',
        }}
      >
        <PageContainer
          title={t('My Organizations')}
          background={AppColors.g100}
        >
          {renderAdminLink()}
          <Divider
            sx={{
              marginTop: '12px',
              color: AppColors.neutral600,
            }}
          />
          {orgs && orgs.length > 0 && (
            <>
              <div style={{ marginTop: '24px' }}>
                <ETypography font='HMS' color='gray700'>
                  Member
                </ETypography>
              </div>
              {orgs.map((org: any) => (
                <Stack key={org.id} sx={{ minWidth: '768' }}>
                  <OrganizationCard
                    orgId={org.id}
                    orgName={org.name}
                    type={org.type ?? ''}
                    typeHeader={'Type'}
                    // terminationDate={org.terminationDate ?? 'NA'}
                    // terminationDate={findOrgRole(org.id)}
                    role={findOrgRole(org.id)}
                  />
                  {/* <ProjectsTableCard /> */}
                </Stack>
              ))}
            </>
          )}

          {contacts && (
            <div style={{ marginTop: '24px' }}>
              <ETypography font='HMS' color='gray700'>
                Contractor
              </ETypography>
            </div>
          )}
          {contacts &&
            contacts.map((contractor: any, i: number) => {
              return (
                <Stack
                  key={contractor.id}
                  // key={`${contractor.id}-${contractor.orgId}`}
                  sx={{ minWidth: '768' }}
                >
                  <OrganizationCard
                    orgId={contractor.orgId}
                    // need name
                    orgName={contractor.orgName}
                    typeHeader={'Contractor'}
                    type={contractor.name}
                    isContractor={true}
                    role={
                      contractor.pointOfContactEmail == person!.email
                        ? 'Primary Contact'
                        : 'Contact'
                    }
                  // terminationDate={contractor.terminationDate ?? 'NA'}
                  />
                </Stack>
              )
            })}
        </PageContainer>
      </Stack>
    </EdifyAppBar>
  )
}

interface IOrganizationCardProps {
  orgName: string
  orgId: string
  type: string
  terminationDate?: string
  isContractor?: boolean
  role?: string
  typeHeader?: string
}

const OrganizationCard: React.FC<IOrganizationCardProps> = ({
  orgName,
  orgId,
  type,
  terminationDate,
  isContractor = false,
  role,
  typeHeader = 'Account Type',
}: IOrganizationCardProps) => {
  const navigate = useNavigate()
  const [isNavigating, setIsNavigating] = useState<boolean>(false)

  const iconSize = 16

  const _buildOrgRowItem = (
    topText: string,
    // icon: ReactElement,
    bottomText: string,
  ) => (
    <Stack
      sx={{
        flex: 3,
        justifyContent: 'center',
        display: 'flex',
        paddingRight: '12px',
      }}
    >
      <Typography sx={AppFonts.textSMedium}>{topText}</Typography>
      <Stack direction={'row'} spacing={8}>
        {/* {icon} */}
        <Typography sx={AppFonts.textMSemibold}>{bottomText}</Typography>
      </Stack>
    </Stack>
  )

  const hardNavigate = async (isContractor:boolean) => {
    const page = isContractor ? ROUTE_CORRECTIVE_ACTIONS : ROUTE_DASHBOARD
    const lang = getTempLanguage()
    if (lang) {
      localStorage.setItem('tempLang', JSON.stringify(lang))
    }
    window.location.href = page
  }

  return (
    <Box
      data-testid={'OrgCard'}
      sx={{
        boxSizing: 'border-box',
        width: 'auto',
        height: 'auto',
        left: 0,
        marginTop: '24px',
        marginX: '16px',
        backgroundColor: AppColors.g000,
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        // padding: '8px',
        flexDirection: 'row',
      }}
    >
      <Box
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          height: 'auto',
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            borderRadius: 100,
            backgroundColor: AppColors.g200,
            width: 84,
            height: 84,
            marginX: 32,
            marginY: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <BusinessIcon
            sx={{
              width: 48,
              height: 48,
            }}
          />
        </Box>
        {_buildOrgRowItem(
          'Company',
          // <CompanyIcon height={iconSize} width={iconSize} />,
          orgName,
        )}

        <Stack
          sx={{
            flex: 2,
            marginTop: '5px',
          }}
        >
          {_buildOrgRowItem(
            typeHeader,
            // <DivisionIcon height={iconSize} width={iconSize} />,
            type,
          )}
        </Stack>
        {/* <Stack
          sx={{
            flex: 1,
            marginTop: '5px',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {_buildOrgRowItem(
            'Projects',
            <ProjectsIcon height={iconSize} width={iconSize} />,
            '5',
          )}
        </Stack> */}
        <Stack
          sx={{
            flex: 2,
            marginTop: '5px',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {terminationDate &&
            _buildOrgRowItem(
              'Account Expires',
              // <CalendarIcon height={iconSize} width={iconSize} />,
              terminationDate == 'NA'
                ? terminationDate
                : dateToMMMDYYYY(terminationDate),
            )}
        </Stack>
        <Stack
          sx={{
            flex: 2,
            marginTop: '5px',
          }}
        >
          {role && _buildOrgRowItem('Role', role)}
        </Stack>
        <Box sx={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
          <EdifyButton
            title='Visit'
            buttonStyle={{ margin: 32, width: 112, padding: '10px, 12px' }}
            loading={isNavigating}
            onClick={async () => {
              setIsNavigating(true)
              await setCurrentOrg(orgId)
              
              setIsNavigating(false)
              hardNavigate(isContractor)
         
              // navigate(
              //   isContractor ? ROUTE_CORRECTIVE_ACTIONS : ROUTE_DASHBOARD,
              // )
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

const ProjectsTableCard: React.FC = () => {
  function _createMockData(
    name: string,
    description: string,
    location: string,
    status: string,
    pointOfContact: string,
    assignedTeam: string[],
  ) {
    return { name, description, location, status, pointOfContact, assignedTeam }
  }

  // TODO: Replace with actual data
  const mockProjects = [
    _createMockData(
      'Large Facility',
      'Data Center & Manufacturing',
      '4967 State St, Murray, UT 84107',
      'Current',
      'Andrew Admin',
      ['X', 'J', 'K'],
    ),
    _createMockData(
      'A Big Hotel',
      'Habitational',
      '762 E 12300 S, Draper, UT 84020',
      'Current',
      'Soliver Admin',
      ['M', 'Q', 'W', 'D', 'E', 'F', 'G', 'H', 'I'],
    ),
    _createMockData(
      'A Car Manufacturing Plant',
      'Tesla',
      '230 W 10600 S Suite 1700, Sandy, UT 84070',
      'Planning',
      'Emerson Baus',
      ['A', 'B', 'C', 'D', 'E', 'F'],
    ),
    _createMockData(
      'Textiles Warehouse',
      '',
      '5444 S 900 E, Murray, UT 84107',
      'Current',
      'Emerson Baus',
      ['C', 'D', 'E'],
    ),
    _createMockData(
      'Gas Station and Car Wash',
      '',
      '230 W 10600 S Suite 1700, Sandy, UT 84070',
      'On Hold',
      'Emerson Baus',
      ['A', 'B', 'C', 'D', 'E'],
    ),
    _createMockData(
      'Grocery Store & Local Farmers Market',
      'Food & Drug',
      '',
      'Complete',
      '',
      ['E', 'F'],
    ),
    _createMockData('Small Wooden Vehicle', '', '', 'Complete', '', ['A']),
  ]

  const _buildAvatarRow = (team: string[]) => {
    const subTeam = team.length < 4 ? team : team.slice(0, 3)
    if (team.length > 3) subTeam.push(`+${team.length - 3}`)
    // TODO: replace with actual colors and/or images
    const bgColors = [
      deepOrange[500],
      deepPurple[500],
      green[500],
      AppColors.purpleSecondary600,
    ]

    return (
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'left',
          display: 'flex',
        }}
      >
        {subTeam.map((member) => (
          <Avatar
            sx={{
              position: 'relative',
              left: -8 * subTeam.indexOf(member),
              bgcolor: bgColors[subTeam.indexOf(member) % 4],
              border: '2px solid',
              color: AppColors.baseWhite,
            }}
            key={member}
          >
            {member}
          </Avatar>
        ))}
      </Stack>
    )
  }
  const _buildStatusChip = (status: string) => {
    const statusStrings = ['Current', 'On Hold', 'Complete', 'Planning']
    const bgColors = [
      AppColors.primary25,
      AppColors.neutral300,
      AppColors.success50,
      AppColors.warning50,
    ]
    const textColors = [
      AppColors.primary600,
      AppColors.gray700,
      AppColors.success700,
      AppColors.warning600,
    ]

    return (
      <Typography
        sx={{
          ...AppFonts.textXSMedium,
          backgroundColor:
            bgColors[statusStrings.indexOf(status) % bgColors.length],
          color: textColors[statusStrings.indexOf(status) % textColors.length],
          borderRadius: '5px',
        }}
      >
        {status}
      </Typography>
    )
  }
  function _buildProjectRow(row: {
    name: string
    description: string
    location: string
    status: string
    pointOfContact: string
    assignedTeam: string[]
  }): JSX.Element {
    return (
      <TableRow key={row.name}>
        <TableCell component='th' scope='row'>
          <Stack>
            <Typography
              sx={{
                ...AppFonts.textMSemibold,
                color:
                  row.status === 'Complete'
                    ? AppColors.gray25
                    : AppColors.gray700,
              }}
            >
              {row.name}
            </Typography>
            <Typography
              sx={{
                ...AppFonts.textMSemibold,
                color:
                  row.status === 'Complete'
                    ? AppColors.gray25
                    : AppColors.gray50,
              }}
            >
              {row.description}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell
          sx={{ ...AppFonts.textMSemibold, color: AppColors.gray700 }}
          align='left'
        >
          {row.location}
        </TableCell>
        <TableCell align='center'>{_buildStatusChip(row.status)}</TableCell>
        <TableCell sx={AppFonts.textMSemibold} align='left'>
          {row.pointOfContact}
        </TableCell>
        <TableCell align='center'>
          {_buildAvatarRow(row.assignedTeam)}
        </TableCell>
        <TableCell align='center'>
          {row.status === 'Complete' ? (
            <CloseIcon sx={{ color: AppColors.gray700 }} />
          ) : (
            <LaunchIcon />
          )}
        </TableCell>
      </TableRow>
    )
  }

  function _buildProjectTable(
    rows: {
      name: string
      description: string
      location: string
      status: string
      pointOfContact: string
      assignedTeam: string[]
    }[],
  ) {
    return (
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align='left'>Location</TableCell>
              <TableCell align='center'>Status</TableCell>
              <TableCell align='left'>Point of Contact</TableCell>
              <TableCell align='left'>Assigned Team</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows.map((row) => _buildProjectRow(row))}</TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Stack
      data-testid={'ProjectCard'}
      sx={{
        width: 'auto',
        height: 'auto',
        marginTop: '8px',
        marginX: '16px',
        backgroundColor: AppColors.g000,
        borderRadius: '5px',
        justifyContent: 'space-between',
        padding: '24px',
      }}
    >
      <Typography sx={AppFonts.textLMedium}>Your Projects</Typography>
      <Divider sx={{ marginY: '16px' }} />
      {_buildProjectTable(mockProjects)}
    </Stack>
  )
}
