import React, { useState } from 'react'
import { Box, Button, Input, MenuItem } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import {
  EdifyFieldLabel,
  EdifyFormField,
  EdifySelect,
} from '../../components/form'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import FormErrorText from '../../components/form/FormErrorText'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import FormSection from '../../components/form/Containers/FormSection'

import {
  AdminCreateFormHeader,
  AdminFormUpdate,
  getForm,
} from '../../../domain/domain'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { EdifyToggle1 } from '../../components/permissions/PermissionList'

import {
  SubmissionsPageViewModelProps,
  useSubmissionsPageViewModel,
} from '../submissions/SubmissionsViewModel'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'

// react-hook-form state
type FormSettingsInputs = {
  title: string
  aiModelEndpoint: string
  aiVisionModelEndpoint: string
  category: string | null
  // categoryIcon: string | null
}
interface FormSettingsFormProps {
  id?: string | undefined
  onCancel?: () => void
}
export const FormSettingsForm: React.FC<FormSettingsFormProps> = () => {
  // const vm: FormSettingsPageViewModelProps = useFormSettingsPageViewModel()
  const vm: SubmissionsPageViewModelProps = useSubmissionsPageViewModel()
  const { formUpdated } = useOrganizationProvider()
  const [loading, setLoading] = useState<boolean>(false)

  const [categoryIcon, setCategoryIcon] = useState<string | undefined>(
    vm.form?.category?.id ?? '-1',
  )
  const [allowAnonymous, setAllowAnonymous] = useState<boolean>(
    vm.form.allowAnonymous ?? false,
  )

  const [isSharingEnabled, setIsSharingEnabled] = useState<boolean>(
    vm.form.isSharingEnabled ? true : false,
  )

  const [isHidden, setIsHidden] = useState<boolean>(
    vm.form.isHidden ? true : false,
  )

  const addHardcodedFormHeader = async () => {
    const res = await AdminCreateFormHeader({
      columnNumber: 1,
      name: 'Language',
      key: 'language',
      formId: 'a4e3db83-129f-4885-b39a-f05a26850597',
      type: 'DESKTOP',
    })
  }

  /**
   * Validates the input values for the person's profile form.
   */
  const resolver: Resolver<FormSettingsInputs> = async (values) => {
    const errors: FieldErrors<FormSettingsInputs> = {}
    if (!values.title) {
      errors.title = {
        type: 'required',
        message: 'Form title is required',
      }
    }

    return {
      values,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<FormSettingsInputs>({
    resolver,
    defaultValues: {
      title: vm.form.title,
      aiModelEndpoint: vm.form.aiModelEndpoint,
      aiVisionModelEndpoint: vm.form.aiVisionModelEndpoint,
      category: vm.form.category?.name ?? '',
    },
    mode: 'onChange',
  })

  /**
   * Handles the form submission when the form is valid.
   * will run resolver validations before submitting
   * @param {Object} data - The form data submitted by the signUp.
   * @returns {void}
   */
  const onSubmit = handleSubmit(async (data) => {
    const formData = {
      title: data.title,
      aiModelEndpoint: data.aiModelEndpoint,
      aiVisionModelEndpoint: data.aiVisionModelEndpoint,
      // null to remove, need -1 to show 'remove icon in dropdown'
      category: categoryIcon == '-1' ? null : categoryIcon,
      allowAnonymous: allowAnonymous,
      isSharingEnabled: isSharingEnabled,
      isHidden: isHidden,
    }

    setLoading(true)
    const res = await AdminFormUpdate(vm.form.id, formData)
    setLoading(false)
    const updatedForm = res.right
    if (updatedForm) {
      // BE not return full form after update, need to get it again
      setLoading(true)
      const correctForm = await getForm(vm.form.id)
      setLoading(false)
      if (correctForm.right) {
        // update the form in the settings page
        vm.setForm(updatedForm)
        const addRemovedCategory =
          (vm.form.category && updatedForm.category) ||
          (!vm.form.category && !updatedForm.category)
            ? false
            : true
        // update the forms in the organization provider, need to check if category was added or removed
        formUpdated(correctForm.right, addRemovedCategory)
      }

      SuccessToast({
        title: 'Form Updated',
      })
    } else {
      ErrorToast({ title: 'Error Toast' })
    }
  })
  const handleIconChange = (e: any) => {
    setCategoryIcon(e.target.value)
  }

  return (
    <InnerPageContainer headerTitle='General' collapsible collapsed={true}>
      <Box sx={{ padding: '12px' }}>
        <form onSubmit={onSubmit}>
          <Box
            sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}
          >
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='Form title' />
              <Input
                disableUnderline
                className={`form-control ${errors?.title ? 'error' : ''}`}
                {...register('title')}
              />
              {errors?.title && (
                <FormErrorText>{errors.title.message}</FormErrorText>
              )}
            </EdifyFormField>

            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='Form Category' />

              <EdifySelect
                width={'100%'}
                value={categoryIcon}
                dropDownItems={[{ id: '-1', name: 'None' }, ...vm.categories]}
                onChange={handleIconChange}
                renderMenuItem={(cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                )}
              ></EdifySelect>
              {errors?.category && (
                <FormErrorText>{errors.category.message}</FormErrorText>
              )}
            </EdifyFormField>
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}
          >
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='Voice Model Endpoint' />
              <Input
                disableUnderline
                className={`form-control ${
                  errors?.aiModelEndpoint ? 'error' : ''
                }`}
                {...register('aiModelEndpoint')}
              />
              {errors?.aiModelEndpoint && (
                <FormErrorText>{errors.aiModelEndpoint.message}</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <></>
              {/* <IconPicker
            value=''
            onChange={handleIconChange}
            formControlProps={{
              sx: {},
            }}
          /> */}
              <EdifyFieldLabel label='Anonymous Submissions' />
              <EdifyToggle1
                checked={allowAnonymous}
                onChange={() => setAllowAnonymous(!allowAnonymous)}
                text='Enable'
                sx={{ marginTop: '0px' }}
              />
            </EdifyFormField>
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}
          >
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='Vision Model Endpoint' />
              <Input
                disableUnderline
                className={`form-control ${
                  errors?.aiVisionModelEndpoint ? 'error' : ''
                }`}
                {...register('aiVisionModelEndpoint')}
              />
              {errors?.aiVisionModelEndpoint && (
                <FormErrorText>
                  {errors.aiVisionModelEndpoint.message}
                </FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <></>
              {/* <IconPicker
            value=''
            onChange={handleIconChange}
            formControlProps={{
              sx: {},
            }}
          /> */}
              <EdifyFieldLabel label='Enable Sharing' />
              <EdifyToggle1
                checked={isSharingEnabled}
                onChange={() => setIsSharingEnabled(!isSharingEnabled)}
                text='Enable'
                sx={{ marginTop: '0px' }}
              />
            </EdifyFormField>
          </Box>
          <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
            <EdifyFieldLabel label='Hide form from list view' />
            <EdifyToggle1
              checked={isHidden}
              onChange={() => setIsHidden(!isHidden)}
              text='Enable'
              sx={{ marginTop: '0px' }}
            />
          </EdifyFormField>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              marginTop: '48px',
            }}
          >
            <Box>
              <EdifyButton
                disabled={Object.keys(errors).length > 0}
                loading={loading}
                type='submit'
                data-testid={'SubmissionButton'}
                primary
                title={'Save'}
              />
            </Box>
          </Box>

          {/* ADMIN SETTING UI SECTION */}
        </form>
      </Box>
    </InnerPageContainer>
  )
}

export default FormSettingsForm
